import Immutable from 'immutable';
import { LIST_FILTER_UPDATE } from 'constants/actionTypes.js';

const initialState = Immutable.fromJS({
  value: '',
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_FILTER_UPDATE: {
      if (payload.listName !== 'workType') {
        return state;
      }

      return state.set('value', payload.searchValue || '');
    }
    default:
      return state;
  }
};
