import { all } from 'redux-saga/effects';
import router from 'sagas/router';
import auth from 'sagas/auth';
import filters from 'sagas/filters';
import vendors from 'sagas/vendors/amplitude';

export default function* root() {
  yield all([
    router(),
    auth(),
    vendors(),
    filters(),
    // other sagas
  ]);
}
