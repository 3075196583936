import {
  CSV_EXPORT_BOM,
  CSV_EXPORT_MIME,
  CSV_EXPORT_SEPARATOR,
  REPORTS_CSV_EXPORT_FILENAME,
} from 'constants/index';
import { saveAs } from 'file-saver';


export const formatCsvValue = (value) => {
  if (typeof value === 'number') {
    if (!value) {
      value = '0.0';
    }
    value = '"=""' + value.toString().replace('.', ',') + '"""';
  }
  return value;
};

const downloadCsv = (rows, filename) => {
  for (let i = 0; i < rows.length; i++) {
    for (let j = 0; j < rows[i].length; j++) {
      rows[i][j] = formatCsvValue(rows[i][j]);
    }
  }

  let csvContent = rows.map(e => e.join(CSV_EXPORT_SEPARATOR)).join('\n');
  csvContent = CSV_EXPORT_BOM + csvContent;
  const blob = new Blob([csvContent], {type: CSV_EXPORT_MIME});
  saveAs(blob, filename);
};

const prepareExportData = (prepareRow, data) => {
  let rows = [];
  data.forEach(item => {
    rows.push(prepareRow(item));
  });
  return rows;
};

export const exportCsv = (prepareRow, exportHeaders, data) => {
  let rows = prepareExportData(prepareRow, data);

  if (exportHeaders && exportHeaders.length) {
    rows.unshift(exportHeaders);
  }

  downloadCsv(rows, REPORTS_CSV_EXPORT_FILENAME);
};
