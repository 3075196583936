import { combineReducers } from 'redux-immutable';
import factWorksReport from 'reducers/reports/factWorksReport';
import agroEventsReport from 'reducers/reports/agroEventsReport';
import location from 'reducers/reports/location';
import track from 'reducers/reports/track';

export default combineReducers({
  factWorksReport,
  agroEventsReport,
  location,
  track,
});
