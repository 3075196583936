import Immutable from 'immutable';
import _ from "lodash";
import * as actionsTypes from 'constants/actionTypes.js';

const initialState = Immutable.fromJS({
  workTypes: [],
  drivers: [],
  geozoneTypes: [],
  isSeasonsLoading: true,
  seasons: [],
  cultures: [],
  cadasterSpotTypes: [],
  vehicleUnattached: [],
  trailerUnattached: [],
  vehicleCurrentTrailers: {},
});

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {

    case actionsTypes.TRAILER_WORK_TYPE_GET_LIST_SUCCESS:
      return state.set('workTypes', Immutable.fromJS(payload.data));

    case actionsTypes.DRIVER_DRIVER_GET_LIST_SUCCESS:
      return state.set('drivers', Immutable.fromJS(payload.data));

    case actionsTypes.TERRITORY_GEOZONE_TYPE_GET_LIST_SUCCESS:
      return state.set('geozoneTypes', Immutable.fromJS(payload.data));

    case actionsTypes.TERRITORY_CULTURE_GET_LIST_SUCCESS:
      return state.set('cultures', Immutable.fromJS(payload.data));

    case actionsTypes.SEASON_CREATE_SUCCESS:
      return state.update('seasons', seasons => seasons.push(Immutable.fromJS(payload.data)));

    case actionsTypes.SEASON_UPDATE_SUCCESS:
      const index = state.get('seasons').findIndex(item => {
        console.log(item);
        return item.get('id') === payload.data.id
      });
      if (index < 0) {
        return state;
      }
      return state.updateIn(['seasons', index], season =>  Immutable.fromJS(({...season.toJS(), ...payload.data})));

    case actionsTypes.SEASON_DELETE_SUCCESS:
      const id = _.get(meta, "previousAction.payload.id");
      if (!id) {
        return state;
      }
      const deleteIndex = state.get('seasons').findIndex(item => item.get('id') === id);
      if (deleteIndex < 0) {
        return state;
      }
      return state.deleteIn(['seasons', deleteIndex]);

    case actionsTypes.TERRITORY_SEASON_GET_LIST:
      return state.set('isSeasonsLoading', true);

    case actionsTypes.TERRITORY_SEASON_GET_LIST_SUCCESS:
      return state.set('seasons', Immutable.fromJS(payload.data)).set('isSeasonsLoading', false);

    case actionsTypes.TERRITORY_CADASTER_SPOT_TYPE_GET_LIST_SUCCESS:
      return state.set('cadasterSpotTypes', Immutable.fromJS(payload.data));

    case actionsTypes.VEHICLE_VEHICLE_GET_LIST_SUCCESS:
      return state.set('vehicleUnattached', Immutable.fromJS(payload.data));

    case actionsTypes.TRAILER_TRAILER_GET_LIST_SUCCESS:
      return state.set('trailerUnattached', Immutable.fromJS(payload.data));

    case actionsTypes.VEHICLE_CURRENT_TRAILER_FETCH_SUCCESS: {
      const { data = []} = payload;
      const { previousAction } = meta;
      const vehicle = data[0] || {trailer: {}};
      // const id = vehicle.vehicle_uid;
      const id = previousAction.payload.request.data.vehicle_uids[0];
      const name = vehicle.trailer.name;
      const readedAt = new Date().getTime();
      return state.setIn(
        ['vehicleCurrentTrailers', id],
        Immutable.fromJS({ name, readedAt })
      );    }

    default:
      return state;
  }
};
