import React from 'react';
import PT from 'prop-types';
import Input from 'components/ui/Input';
import IconButton from 'components/ui/IconButton';
import HR from 'components/ui/HR';
import styles from './style.module.scss';
import List from 'containers/filters/List';
import WorkTypeListItem from 'containers/filters/WorkTypeListItem';
import ReactResizeDetector from 'react-resize-detector';
import {resizeLeftPanelList} from 'appEvents';

export class Panel extends React.Component {

  render = () => {
    const props = this.props;
    return (
      <div className={styles.container}>
        <Input
          placeholder="Найти вид работ..."
          icon="search"
          value={this.props.searchValue}
          onChange={this.props.onSearchChange}
          onSearch={this.props.onSearchStart}
        />
        <div className={styles.buttonsContainer}>
          <IconButton icon="item_add" title="Добавить вид работы" onClick={props.onNewItem} />
          <IconButton icon="item_edit" disabled={!props.isItemSelected} title="Редактировать вид работы" onClick={props.onEditItem} />
        </div>
        <HR />
        <div className={"leftPanelList"}>
          <ReactResizeDetector handleHeight onResize={ () => {resizeLeftPanelList()} } />
          <List
            listName="workType"
            renderItem={ (item) => <WorkTypeListItem id={item} listName="workType" /> }
          />
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  isItemSelected: PT.bool,
  onNewItem: PT.func,
  onEditItem: PT.func,
  searchValue: PT.string,
  onSearchChange: PT.func,
  onSearchStart: PT.func,
};

export default Panel;
