import * as actionsTypes from 'constants/actionTypes.js';

export const showCalendarModal = (isDisplayed, from, to) => ({
  type: actionsTypes.MODAL_CALENDAR_SHOW,
  payload: { isDisplayed, from, to },
});

export const closeCalendarModal = () => ({
  type: actionsTypes.MODAL_CALENDAR_CLOSE,
  payload: {},
});
