import React from 'react';
import CN from 'classnames';
import PT from 'prop-types';
import moment from 'moment';
import { container, button, selected } from './style.module.scss';
// import styles from "../../filters/vehicle/Panel/style.module.scss";

const periods = [
  {
    from: () => moment().startOf('day'),
    to: () => moment().endOf('day'),
    caption: 'сегодня',
  },
  {
    from: () => moment().subtract(1, 'day').startOf('day'),
    to: () => moment().subtract(1, 'day').endOf('day'),
    caption: 'вчера',
  },
  {
    from: () => moment().subtract(7 ,'day').startOf('day'),
    to: () => moment().endOf('day'),
    caption: '7 дней',
  },
  {
    from: () => moment().subtract(30, 'day').startOf('day'),
    to: () => moment().endOf('day'),
    caption: 'месяц',
  },
];

const isSelected = (index, from, to) => 
  periods[index].from().valueOf() === from &&
  periods[index].to().valueOf() === to;


class Periods extends React.PureComponent {
  onClick = (index) => e => {
    const from = periods[index].from().valueOf();
    const to = periods[index].to().valueOf();
    this.props.onSelect && this.props.onSelect(from, to, index);
  };

  render = () => this.renderWithProps(this.props);

  renderWithProps = ({className, ...props}) => (
    <div
        className={CN([
          container,
          className,
        ])}
        {...props}
    >
      {periods.map((period, index) => (
        <button
          key={index}
          type="button"
          className={CN([
            button,
            { [selected]: isSelected(index, props.from, props.to) },
          ])}
          onClick={this.onClick(index)}
        >
          {period.caption}
        </button>
      ))}
    </div>
  );
}

Periods.propTypes = {
  from: PT.number,
  to: PT.number,
  onSelect: PT.func,
};

export default Periods;
