import React from 'react';
import PT from 'prop-types';
import Button from 'components/ui/Button';
import styles from './style.module.scss';

class ApiExample extends React.PureComponent {
  onClick = () => e => {
    this.props.onClick && this.props.onClick();
  };

  render = () => (
    <div className={styles.container}>
      <div>
        <Button disabled={this.props.isLoading} onClick={this.onClick()}>
          Update
        </Button>
      </div>
      <div className={styles.value}>
        {this.props.isLoading ? 'Loading...' : this.props.code}
      </div>
    </div>
  );
}

ApiExample.propTypes = {
  isLoading: PT.bool,
  code: PT.string,
  onClick: PT.func,
};

export default ApiExample;
