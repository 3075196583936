import { connect } from 'react-redux';
import Calendar from 'components/filters/Calendar';
import { getPeriodValue } from 'selectors/filters';
import { showCalendarModal } from 'actions/calendarModal/index';

const mapStateToProps = (state, props) => ({
  from: getPeriodValue(state, 'from'),
  to: getPeriodValue(state, 'to'),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: () => {
      dispatch(showCalendarModal(true, ownProps.from, ownProps.to));
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar);
