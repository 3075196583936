import React, {Component} from 'react';
import PT from 'prop-types';
import TimelinePlanner, {
  getLocalTime,
  getUtcTime,
  getMaxOpenTime,
  isFolderGroup,
  getId,
} from 'components/planner/TimelinePlanner';
import moment from 'moment';


class Page extends Component {

  getAllVehicleGroups = (items, parent, level = 0) => items.flatMap(({id, title, children, isFolder}) => (
    [{
      id: (isFolder ? "group_" : "") + id,
      title,
      level,
      parent,
      stackItems: true,
      root: isFolder
    }]
      .concat(isFolder ? this.getAllVehicleGroups(children, "group_" + id, level + 1) : [])
  ));

  isEqualTreeData = (prevTree, tree) => {
    const prevVehicleIds = this.getAllVehicleIds(prevTree);
    const vehicleIds = this.getAllVehicleIds(tree);
    return prevVehicleIds.length === vehicleIds.length && prevVehicleIds.every((value, index) => value === vehicleIds[index]);
  };

  loadTimelineData = () => {
    const {loadData} = this.props;
    const {vehicleIds, from, to} = this.getFetchParams();
    loadData(vehicleIds, from, to);
  };
  getAllVehicleIds = (vehicleTree) => this.getAllVehicleGroups(vehicleTree.children).filter(item => !item.root).map(item => item.id);

  getFetchParams() {
    const {vehicleTree} = this.props;
    const vehicleIds = this.getAllVehicleIds(vehicleTree);
    return {
      type: "driver",
      vehicleIds,
      from: moment().subtract(3, 'month').valueOf(),
      to: moment().add(3, 'month').valueOf(),
    }
  }
  getGroups = () => {
    const {vehicleTree} = this.props;
    return this.getAllVehicleGroups(vehicleTree.children);
  };

  getGroupById = (id) => {
    return this.getGroups().find(item => item.id === id)
  };

  getItems = (groups) => {
    const {data} = this.props;
    return data.map(({id, driver: {id: driverId, name: title}, vehicle_uid, start_at: start_time, finish_at: end_time}) => {
      if (end_time <= start_time) {
        return [];
      }
      const itemGroups = groups.filter(group => group.id === vehicle_uid);
      return itemGroups.map(itemGroup => ({
        id: id,
        id2: id+"|"+itemGroup.id2,
        title,
        group: itemGroup.id2,
        driverId,
        start_time: getLocalTime(start_time),
        end_time: getMaxOpenTime(end_time),
      }));
    }).flatMap(item => item);
  };

  handleItemMove = (item, group, dragTime) => {
    const {putTrailerVehicle} = this.props;
    if (isFolderGroup(group.id)) {
      return;
    }
    putTrailerVehicle(item.id, {
      driver_id: item.driver_id,
      vehicle_uid: group.id,
      start_at: getUtcTime(dragTime),
      finish_at: item.finish_at ? (getUtcTime(dragTime) + (item.finish_at - item.start_at)) : null,
    }, this.getFetchParams())
  };

  handleItemResize = (itemId, time, edge) => {
    const {putTrailerVehicle, data} = this.props;
    if (isFolderGroup(itemId)) {
      return;
    }
    const item = data.find(item => "" + item.id === getId(itemId));
    if (!item) {
      return;
    }
    putTrailerVehicle(getId(itemId), {
      driver_id: item.driver_id,
      vehicle_uid: item.vehicle_uid,
      start_at: edge === 'right' ? item.start_at : getUtcTime(time),
      finish_at: item.finish_at ? (edge === 'right' ? getUtcTime(time) : item.finish_at) : null,
    }, this.getFetchParams())
  };

  handleItemDoubleClick = (itemId) => {
    const {data} = this.props;
    if (isFolderGroup(itemId)) {
      return;
    }
    const item = data.find(item => "" + item.id === getId(itemId));
    if (!item) {
      return;
    }
    const vehicle = this.getGroupById("" + item.vehicle_uid);
    this.props.attachVehicle(item.vehicle_uid, {
      attachTitle: vehicle && vehicle.title,
      item: {
        ...item,
        attached_at: getLocalTime(item.start_at),
        unattached_at: item.finish_at ? getLocalTime(item.finish_at) : null
      }, fetchParams: this.getFetchParams()
    });
  };

  handleIntervalSelected = (selectedGroup, start, end) => {
    const vehicle = this.getGroupById(getId(selectedGroup));
    this.props.attachVehicle(getId(selectedGroup), {start, end, attachTitle: vehicle && vehicle.title, fetchParams: this.getFetchParams()});
  };

  handleCanvasDoubleClick = (group) => {
    if (isFolderGroup(group)) {
      return;
    }
    const vehicle = this.getGroupById(getId(group));
    this.props.attachVehicle(getId(group), {attachTitle: vehicle && vehicle.title, fetchParams: this.getFetchParams()});
  };

  handleDeleteItem = (item) => {
    if (!item || isFolderGroup(item.group)) {
      return;
    }
    this.props.deleteVehicleTrailer(item.id, this.getFetchParams());
  };

  render() {
    return (
      <TimelinePlanner
        tree={this.props.vehicleTree}
        data={this.props.data}
        isLoading={this.props.isLoading}
        pageTitle="Водители в разрезе по ТС"
        plannerType={4}
        loadTimelineData={this.loadTimelineData}
        isEqualTreeData={this.isEqualTreeData}
        getGroups={this.getGroups}
        getItems={this.getItems}
        onIntervalSelected={this.handleIntervalSelected}
        onDeleteItem={this.handleDeleteItem}
        onItemMove={this.handleItemMove}
        onItemResize={this.handleItemResize}
        onItemDoubleClick={this.handleItemDoubleClick}
        onCanvasDoubleClick={this.handleCanvasDoubleClick}
        onInit={this.props.onInit}
      />
    );
  }
}

Page.propTypes = {
  onInit: PT.func,
  loadData: PT.func,
  putTrailerVehicle: PT.func,
  attachVehicle: PT.func,
  deleteVehicleTrailer: PT.func,
  trailerTree: PT.object,
  data: PT.array,
};

export default Page;
