import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import { When } from 'react-if';
import Checkbox from 'components/ui/Checkbox';
import styles from './style.module.scss';

const LEAF = {
  PLOT: 'plot',
  FIELD: 'field',
};

const TreeNode = ({
  id,
  parentId,
  keyPath,
  checked,
  indeterminate,
  leaf,
  leafType,
  checkboxColor,
  textColor,
  opened,
  selected,
  title,
  onClick,
  onSelect,
  onDrag,
  renderTitle
}) => (
  <div
    className={CN(styles.container, {
      [styles.selected]: selected,
    })}
    onClick={() => onSelect && onSelect(id, keyPath, parentId)}
    draggable={Boolean(onDrag)}
    onDragStart={(event)=> {
      if (!onDrag) {
        return;
      }
      event.dataTransfer.setData("id", id);
      event.dataTransfer.setData("isFolder", !leaf);
    }}
    onDragOver={true || !leaf ? (event)=> {
      if (!onDrag) {
        return;
      }
      event.stopPropagation();
      event.preventDefault();
    } : null}
    onDrop={(event)=> {
      if (!onDrag) {
        return;
      }
      const fromId = event.dataTransfer.getData("id");
      const fromIsFolder = event.dataTransfer.getData("isFolder") === "true";
      const toId = id;
      const toIsFolder = !leaf;
      onDrag(fromId, toId, fromIsFolder, toIsFolder);
    }}
  >
    <Checkbox
      color={checkboxColor}
      checked={checked}
      indeterminate={indeterminate}
      onClick={(event) => {
        onClick && onClick(id, keyPath, parentId);
        event.stopPropagation();
      }}
    />
    <When condition={!leaf}>
      <div
        className={CN([
          styles.leftMargin,
          styles.folder,
          { [styles.folderOpen]: opened },
          { [styles.folderClosed]: !opened },
        ])}
      />
    </When>
    <When condition={leaf && !!leafType}>
      <div
        className={CN([
          styles.leftMargin,
          styles.folder,
          { [styles.leafPlot]: leafType === LEAF.PLOT },
          { [styles.leafField]: leafType === LEAF.FIELD },
        ])}
      />
    </When>
    <div
      style={{ color: textColor }}
      className={CN(styles.leftMargin, styles.caption)}
    >
      {renderTitle ? renderTitle(title) : title}
    </div>
  </div>
);

TreeNode.propTypes = {
  id: PT.oneOfType([PT.number, PT.string]),
  parentId: PT.oneOfType([PT.number, PT.string]),
  keyPath: PT.array,
  checked: PT.bool,
  indeterminate: PT.bool,
  leaf: PT.bool,
  leafType: PT.oneOf(Object.values(LEAF)),
  checkboxColor: PT.string,
  textColor: PT.string,
  opened: PT.bool,
  selected: PT.bool,
  title: PT.string,
  onClick: PT.func,
  onSelect: PT.func,
  onDrag: PT.func,
  renderTitle: PT.func,
};

TreeNode.defaultProps = {
  leaf: true,
  keyPath: [],
};

export default TreeNode;
