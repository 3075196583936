import React from 'react';
import PT from 'prop-types';
import Modal from '../../ui/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './style.module.scss';
import moment from 'moment';
import Periods from '../../ui/Periods';
import Button from '../../ui/Button';
import Input from '../../ui/Input';
import Datetime from '../../ui/Datetime';
import "react-datetime/css/react-datetime.css";

const formatDateTime = timestamp => moment(timestamp).format('DD.MM.YYYY HH:mm');


class CalendarModal extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      from: moment(props.from),
      to: moment(props.to),
      periodInterval: 'minutes',
      periodValue: 0,
    };
  }

  handleChangeFrom = (date) => {
    this.setState({'from': date});
  };

  handleChangeTo = (date) => {
    this.setState({'to': date});
  };

  handleOnSelectPeriod = (from, to) => {
    this.setState({
      from: moment(from),
      to: moment(to),
    });
  };

  handleOnChangeInputPeriod = (e) => {
    this.setState({'periodValue': e.target.value}, this.calculateDates);
  };

  handleOnChangeSelectPeriod = (e) => {
    this.setState({'periodInterval': e.target.value}, this.calculateDates);
  };

  calculateDates() {
    this.setState({
      from: moment().subtract(this.state.periodValue, this.state.periodInterval),
      to: moment(),
    });
  };

  onOk = () => {
    this.props.onOk && this.props.onOk(false, this.state.from.valueOf(), this.state.to.valueOf());
  };

  onOpened = () => {
    this.setState({
      from: moment(this.props.from),
      to: moment(this.props.to),
    });
  };

  render = () => {
    const props = this.props;
    return (
      <Modal
        isDisplayed={props.isDisplayed}
        onToggle={props.onClose}
        size={'lg'}
        onOpened={this.onOpened}
        header={
          <div className={styles.period_selector_title}>
            <label className={styles.period_label}>Период:</label>
            <div className={styles.period_input}>
              <span>{formatDateTime(this.state.from)}</span>
            </div>
            <span className={styles.ic_defice}>–</span>
            <div className={styles.period_input}>
              <span>{formatDateTime(this.state.to)}</span>
            </div>
          </div>
        }
        content={
          <div>
            <div className={styles.content}>
              <div>
                <Periods onSelect={this.handleOnSelectPeriod}/>
                <p>Начало периода</p>
                <Datetime
                    input={false}
                    open={true}
                    value={this.state.from}
                    onChange={this.handleChangeFrom}
                />
              </div>
              <div>
                <div>
                  За последние <Input inline centerText size={'2'} maxLength={2} className={styles.minutes}
                                      onChange={this.handleOnChangeInputPeriod} />
                  <div className={styles.select_light_wrap}>
                    <select className={styles.select_light} onChange={this.handleOnChangeSelectPeriod}>
                      <option value="minutes">минут.</option>
                      <option value="hours">час.</option>
                      <option value="days">дн.</option>
                      <option value="weeks">недел.</option>
                      <option value="months">месяц.</option>
                    </select>
                  </div>
                </div>
                <p>Окончание периода</p>
                <Datetime
                    input={false}
                    open={true}
                    value={this.state.to}
                    onChange={this.handleChangeTo}
                />
              </div>
            </div>
          </div>
        }
        buttons={<Button onClick={this.onOk}>Выбрать</Button>}
      />
    );
  }
}

CalendarModal.propTypes = {
  isDisplayed: PT.bool,
  from: PT.number,
  to: PT.number,
  onOk: PT.func,
  onClose: PT.func,
};

export default CalendarModal;
