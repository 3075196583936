import {resizeElement} from "helpers/utils";

export const resizeLeftPanelList = function() {
  const element = document.querySelector('.leftPanelList ul');
  if (!element) {
    return;
  }
  resizeElement(element);
};

window.addEventListener('resize', () => { resizeLeftPanelList(); });
