import React from 'react';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { TRACK_COLOR } from 'constants/index';
import { getTreeInnerNodePropById } from 'selectors/filters';
import { getCheckedTreeNodeIds } from 'selectors/filters';
import * as turf from '@turf/turf';
import { TRACK_FREQ_DIVIDER, TRACK_FREQ_DIRECTION_TRASHHOLD, REPORT_FACT_WORKS_SELECTED_COLOR } from 'constants/index';
import { getDistance, getSpeed } from 'helpers/geo';

const getReport = (state, name) => state.getIn(['reports', name]);

export const getReportProp = createCachedSelector(
  [getReport, (state, name, prop) => name, (state, name, prop) => prop],
  (report, name, prop) => report.get(prop)
)((state, name, prop) => `${name}:${prop}`);

export const getReportData = (state, name) => state.getIn(['reports', name,  'data']);

export const getLocationReportTerritories = state => {
  const ids = getCheckedTreeNodeIds(state, 'territory', true);
  const territories = getReportProp(state, 'location', 'territories');
  return getSelectedTerritories(state, territories, ids);
};

const getSelectedTerritories = createSelector(
  [
    (state, territories, ids) => territories,
    (state, territories, ids) => ids,
  ],
  (territories, ids) =>
  territories
    .filter(item => ids.some(id => id === item.get('id')))
    .map(item => item.set('positions', item.get('positions').map(getPosition)).set('description',
      <span key={item.get("id")}>
        <b key={item.get("id")+"title"}>{item.get('description')}</b>
        {item.get("area") > 0 && <React.Fragment key={item.get("id")+"area"}>
          <br key={item.get("id")+"br"}/>
          <span key={item.get("id")+"area_num"}>{`Площадь: ${item.get("area")} Га`}</span>
        </React.Fragment>}
      </span>
    ))
);

export const getLocationReportVehicleMarkers = createSelector(
  [
    state => state,
    state => getReportProp(state, 'location', 'vehicles'),
  ],
  (state, vehicles) => vehicles.filter(
    vehicle => vehicle.get('latitude') && vehicle.get('longitude')
  ).map(vehicle => Immutable.fromJS({
    id: vehicle.get('id'),
    title: getTreeInnerNodePropById(state, 'vehicle', vehicle.get('id'), true, 'name'),
    icon: 'car',
    position: getPosition(vehicle),
  }))
);

/** Get vehicle ids from just selected checkboxes */
export const getNewVehiclesIds = createCachedSelector(
  [
    state => getCheckedTreeNodeIds(state, 'vehicle', true),
    (state, reportName, prop) => getReportProp(state, reportName, prop),
  ],
  (selectedIds, items) =>
    selectedIds.filter(id =>
      items.every(item => item.get('id') !== id)
    )
)((state, reportName, prop) => `${reportName}:${prop}`);

/** Get vehicle ids from just unselected checkboxes  */
export const getOldVehiclesIds = createCachedSelector(
  [
    state => getCheckedTreeNodeIds(state, 'vehicle', true),
    (state, reportName, prop) => getReportProp(state, reportName, prop),
  ],
  (selectedIds, items) =>
    items.map(item => item.get('id')).filter(id =>
      selectedIds.every(selectedId => selectedId !== id)
    )
)((state, reportName, prop) => `${reportName}:${prop}`);

const getVehicleStates = (state) => state.getIn(['reports', 'location', 'vehicles']);

const getTerritories = (state) => state.getIn(['reports', 'location', 'territories']);

export const getVehicleStateProp = createCachedSelector(
  [
    getVehicleStates,
    (state, id, prop) => id,
    (state, id, prop) => prop
  ],
  (vehicles, id, prop) =>
    vehicles.getIn([vehicles.findIndex(item => item.get('id') === id), prop])
)((state, id, prop) => `${id}:${prop}`);

export const getVehicleTerritoryName = (state, vehicleId) => {
  const id = getVehicleTerritoryId(state, vehicleId);
  // return getTreeInnerNodePropById(state, 'territory', id, true, 'name');
  return getTerritoryName(state, id);
}

export const getTerritoryName = createCachedSelector(
  [
    getTerritories,
    (state, id) => id,
  ],
  (territories, id) => {
    const territory = territories.find(item => item.get('id') === id);
    if (!territory) {
      return null;
    }
    return territory.get('description');
  }
)((state, id) => `${id}`);

export const getVehicleTerritoryId = createCachedSelector([
  (state, vehicleId) => getReportProp(state, 'location', 'territories'),
  (state, vehicleId) => getVehicleStateProp(state, vehicleId, 'latitude'),
  (state, vehicleId) => getVehicleStateProp(state, vehicleId, 'longitude'),
],
(territories, latitude, longitude) => {
  const index = territories.findIndex(item => isInsideTerritory(latitude, longitude, item.get('positions')));
  if (index < 0) {
    return null;
  }
  return territories.getIn([index, 'id']);
})((state, vehicleId) => `${vehicleId}`);

const isInsideTerritory = (latitude, longitude, positions) => {
  if (!latitude || !longitude) {
    return false;
  }
  const coordinates = [];
  positions.forEach(item => coordinates.push([item.get('latitude'), item.get('longitude')]));
  coordinates.push(coordinates[0]); // turf require closed path
  if (coordinates.length < 4) { // Wrong polygons
      return false;
  }
  const point = turf.point([latitude, longitude]);
  const polygon = turf.polygon([coordinates]);
  return turf.inside(point, polygon);
}

export const getVehicleTrailerName = (state, id) => {
  const trailerId = getVehicleStateProp(state, id, 'trailerId');
  if (!trailerId) {
    return null;
  }

  return getTreeInnerNodePropById(state, 'trailer', trailerId, true, 'name');
}

export const getTrackReportVehicleMarkers = createSelector(
  [
    state => state,
    state => getReportProp(state, 'track', 'tracks'),
  ],
  (state, tracks) => tracks.map(track => Immutable.fromJS({
    id: track.get('id'),
    title: getTreeInnerNodePropById(state, 'vehicle', track.get('id'), true, 'name'),
    icon: 'car',
    position: getPosition(track.get('track').get(-1)),
  }))
);

export const getTrackReportArrows = createSelector(
  [state => getReportProp(state, 'track', 'tracks')],
  tracks =>
    tracks.map(track => {
      const points = track
        .get('track')
        .map((item, index) => Immutable.fromJS({
          id: `${track.get('id')}:${index}`,
          date: item.get('date'),
          color: TRACK_COLOR,
          position: getPosition(item),
          direction: item.get('direction'),
          isPopupDisabled: false,
        }));

      return Immutable.Map({id: track.get('id'), points});
    })
);

const dropFrequentPoints = (item, index) =>
  index === 0 || index % TRACK_FREQ_DIVIDER === 0;

const dropSimilarDirection = (item, index, self) => {
  if (!index || index === self.size - 1) {
    return;
  }

  const next = self.get(index + 1);
  const diff = Math.abs(item.get('direction') - next.get('direction'));
  return diff < TRACK_FREQ_DIRECTION_TRASHHOLD;
};
/*
export const getTrackReportMarkers = createSelector(
  [
    getTrackReportArrows,
    getTrackReportVehicleMarkers,
  ],
  (tracks, vehicles) => tracks.concat(vehicles)
);
*/
export const getTrackReportTracks = createSelector(
  [state => getReportProp(state, 'track', 'tracks')],
  tracks =>
    tracks.map(item =>
      Immutable.fromJS({
        id: item.get('id'),
        color: TRACK_COLOR,
        positions: item.get('track').map(getPosition),
      })
    )
);

const getPosition = trackItem =>
  Immutable.Map({
    lat: trackItem.get('latitude'),
    lng: trackItem.get('longitude'),
  });

export const getTrackReportSpeed = createSelector(
  [
    state => getReportProp(state, 'track', 'tracks'),
    (state, id, date) => id,
    (state, id, date) => date,
  ],
  (tracks, id, date) => {
    const vehicle = tracks.find(item => item.get('id') === id);
    const track = vehicle.get('track');
    const pointIndex = track.findIndex(item => item.get('date') === date);
    const point = track.get(pointIndex);
    const pointPrev = pointIndex > 0 ? track.get(pointIndex - 1) : null;
    if (!pointPrev) {
      return null;
    }
    const lon1 = pointPrev.get('longitude');
    const lat1 = pointPrev.get('latitude');
    const date1 = pointPrev.get('date');
    const lon2 = point.get('longitude');
    const lat2 = point.get('latitude');
    const date2 = point.get('date');
    return Math.floor(getSpeed(lon1, lat1, date1, lon2, lat2, date2));
  }
);

export const getTrackReportMileage = createSelector(
  [
    state => getReportProp(state, 'track', 'tracks'),
    (state, id, date) => id,
    (state, id, date) => date,
  ],
  (tracks, id, date) => {
    const vehicle = tracks.find(item => item.get('id') === id);
    const track = vehicle.get('track');
    const pointIndex = track.findIndex(item => item.get('date') === date);

    let result = 0;
    let lonPrev = 0;
    let latPrev = 0;
    track.map((item, index) => {
      if (index > pointIndex) {
        return item;
      }
      const lon = item.get('longitude');
      const lat = item.get('latitude');
      if (index > 0) {
        result += getDistance(lonPrev, latPrev, lon, lat);
      }
      lonPrev = lon;
      latPrev = lat;

      return item;
    });

    return result.toFixed(2);
  }
);

export const getFactReportTerritories = createSelector(
  [
    state => getReportProp(state, 'location', 'territories'),
    state => getReportProp(state, 'factWorksReport', 'map'),
  ],
  (territories, mapData) => {
    let result = territories.filter(item => mapData.get('geozones').includes(item.get('id')));
    result = result.map(item => item.set('positions', item.get('positions').map(getPosition)))
    return result;
  }
);

export const getFactReportTracks = createSelector(
  [
    state => getReportProp(state, 'factWorksReport', 'map'),
  ],
  (mapData) => mapData.get('tracks')
);

const getFactReportSpeedTracks = createSelector(
  [
    state => getReportProp(state, 'factWorksReport', 'map'),
  ],
  (mapData) => mapData.get('speedTracks')
);

export const getFactReportPolygons = createSelector(
  [
    getFactReportTerritories,
    getFactReportSpeedTracks,
    state => getInReportProp(state, 'factWorksReport', ['table', 'selectedGeozoneId']),
  ],
  (territories, speedTracks, selectedGeozoneId) =>
    speedTracks.concat(
      territories
        .map(item =>
          item.get('id') === selectedGeozoneId
            ? item
              .set('color', REPORT_FACT_WORKS_SELECTED_COLOR)
              .set('fillColor', item.get('color'))
            : item
        ))
);

export const getInReportProp = createCachedSelector(
  [
    getReport,
    (state, name, path) => path,
  ],
  (report, path) => report.getIn(path)
)((state, name, path) => `${name}:${path.join('.')}`);
