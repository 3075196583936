import React, {Component} from 'react';
import PT from 'prop-types';
import TimelinePlanner, {
  getLocalTime,
  getUtcTime,
  getMaxOpenTime,
  isFolderGroup,
  getId,
} from 'components/planner/TimelinePlanner';
import moment from 'moment';


class Page extends Component {

  getAllTrailerGroups = (items, parent, level = 0) => items.flatMap(({id, title, isFolder, children}) => (
    [{
      id: (isFolder ? "group_" : "") + id,
      title,
      parent,
      level,
      stackItems: true,
      root: isFolder
    }]
      .concat(isFolder ? this.getAllTrailerGroups(children, "group_" + id, level + 1) : [])
  ));

  isEqualTreeData = (prevTree, tree) => {
    const prevTrailerIds = this.getAllTrailerIds(prevTree);
    const trailerIds = this.getAllTrailerIds(tree);
    return prevTrailerIds.length === trailerIds.length && prevTrailerIds.every((value, index) => value === trailerIds[index]);
  };

  loadTimelineData = () => {
    const {loadData} = this.props;
    const {trailerIds, from, to} = this.getFetchParams();
    loadData(trailerIds, from, to);
  };

  getAllTrailerIds = (trailerTree) => this.getAllTrailerGroups(trailerTree.children).filter(item => !item.root).map(item => item.id);

  getFetchParams() {
    const {trailerTree} = this.props;
    const trailerIds = this.getAllTrailerIds(trailerTree);
    return {
      type: "trailer",
      trailerIds,
      from: moment().subtract(3, 'month').valueOf(),
      to: moment().add(3, 'month').valueOf(),
    }
  }

  getGroups = () => {
    const {trailerTree} = this.props;
    return this.getAllTrailerGroups(trailerTree.children);
  };

  getGroupById = (id) => {
    return this.getGroups().find(item => item.id === id)
  };

  getItems = (groups) => {
    const {data} = this.props;
    return data.map(({id, vehicle: {name: title}, trailer_id, attached_at: start_time, unattached_at: end_time}) => {
      if (end_time <= start_time) {
        return [];
      }
      const itemGroups = groups.filter(group => group.id === "" + trailer_id);
      return itemGroups.map(itemGroup => ({
        id,
        id2: id+"|"+itemGroup.id2,
        title,
        group: itemGroup.id2,
        start_time: getLocalTime(start_time),
        end_time: getMaxOpenTime(end_time),
      }));
    }).flatMap(item => item);
  };

  handleItemMove = (item, group, dragTime) => {
    const {putTrailerVehicle} = this.props;
    if (isFolderGroup(group.id)) {
      return;
    }
    putTrailerVehicle(item.id, {
      vehicle_uid: item.vehicle_uid,
      trailer_id: group.id,
      attached_at: getUtcTime(dragTime),
      unattached_at: item.unattached_at ? getUtcTime(dragTime) + (item.unattached_at - item.attached_at) : null,
    }, this.getFetchParams())
  };

  handleItemResize = (itemId, time, edge) => {
    const {putTrailerVehicle, data} = this.props;
    if (isFolderGroup(itemId)) {
      return;
    }
    const item = data.find(item => "" + item.id === getId(itemId));
    if (!item) {
      return;
    }
    putTrailerVehicle(getId(itemId), {
      vehicle_uid: item.vehicle_uid,
      trailer_id: item.trailer_id,
      attached_at: edge === 'right' ? item.attached_at : getUtcTime(time),
      unattached_at: item.unattached_at ? (edge === 'right' ? getUtcTime(time) : item.unattached_at) : null,
    }, this.getFetchParams())
  };

  handleItemDoubleClick = (itemId) => {
    const {data} = this.props;
    if (isFolderGroup(itemId)) {
      return;
    }
    const item = data.find(item => "" + item.id === getId(itemId));
    if (!item) {
      return;
    }
    const trailer = this.getGroupById("" + item.trailer_id);
    this.props.attachVehicle(item.trailer_id, {
      attachTitle: trailer && trailer.title,
      item: {
        ...item,
        attached_at: getLocalTime(item.attached_at),
        unattached_at: item.unattached_at ? getLocalTime(item.unattached_at) : null
      }, fetchParams: this.getFetchParams()
    });
  };

  handleIntervalSelected = (selectedGroup, start, end) => {
    const trailer = this.getGroupById(getId(selectedGroup));
    this.props.attachVehicle(getId(selectedGroup), {start, end, attachTitle: trailer && trailer.title, fetchParams: this.getFetchParams()});
  };

  handleCanvasDoubleClick = (group) => {
    if (isFolderGroup(group)) {
      return;
    }
    const trailer = this.getGroupById(getId(group));
    this.props.attachVehicle(getId(group), {attachTitle: trailer && trailer.title, fetchParams: this.getFetchParams()});
  };

  handleDeleteItem = (item) => {
    if (!item || isFolderGroup(item.group)) {
      return;
    }
    this.props.deleteVehicleTrailer(item.id, this.getFetchParams());
  };

  render() {
    return (
      <TimelinePlanner
        tree={this.props.trailerTree}
        data={this.props.data}
        isLoading={this.props.isLoading}
        pageTitle="Планировщик в разрезе по навесному оборудованию"
        plannerType={2}
        loadTimelineData={this.loadTimelineData}
        isEqualTreeData={this.isEqualTreeData}
        getGroups={this.getGroups}
        getItems={this.getItems}
        onIntervalSelected={this.handleIntervalSelected}
        onDeleteItem={this.handleDeleteItem}
        onItemMove={this.handleItemMove}
        onItemResize={this.handleItemResize}
        onItemDoubleClick={this.handleItemDoubleClick}
        onCanvasDoubleClick={this.handleCanvasDoubleClick}
        onInit={this.props.onInit}
      />
    );
  }
}

Page.propTypes = {
  onInit: PT.func,
  loadData: PT.func,
  putTrailerVehicle: PT.func,
  attachVehicle: PT.func,
  detachTrailer: PT.func,
  deleteVehicleTrailer: PT.func,
  trailerTree: PT.object,
  data: PT.array,
};

export default Page;
