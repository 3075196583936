import Immutable from 'immutable';
import treeReducer, { loadState, saveState } from 'helpers/tree.js';

const treeName = 'territory';
const initialState = Immutable.fromJS(loadState(treeName));

export default (state = initialState, action) => {
  const stateNew = treeReducer(state, action, treeName);
  if (state !== stateNew) {
    saveState(treeName, stateNew);
  }
  return stateNew;
};
