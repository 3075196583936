import {VEHICLE_POPUP_CACHE_PERIOD} from 'constants/index';

export const getTrailerPopupProp = (state, prop) => state.getIn(['trailerPopup', prop]);
export const getTreeFolderPopupProp = (state, prop) => state.getIn(['trailerTreeFolderPopup', prop]);
export const getVehicleCurrentTrailer = (state, id) => state.getIn(['dictionary', 'vehicleCurrentTrailers', id, 'name']);

export const isVehicleTrailerOutDated = (state, id) => {
    const readedAt = state.getIn([
      'dictionary',
      'vehicleCurrentTrailers',
      id,
      'readedAt',
    ]);
    const timestamp = new Date().getTime();
    return !readedAt || timestamp - readedAt > VEHICLE_POPUP_CACHE_PERIOD;
};