import amplitude from 'amplitude-js/amplitude';

export const initAmplitude = (appKey) => {
  amplitude.getInstance().init(appKey);
};

export const setAmplitudeUserDevice = installationToken => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = userId => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties);
};

export const logEvent = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};