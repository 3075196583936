import React from 'react';
import CN from 'classnames';
import HR from 'components/ui/HR';
import styles from './style.module.scss';

const FormFooter = ({ className, left, right, center, children, ...props }) => (
  <div
      className={CN([
        styles.container,
        { [styles.left]: left },
        { [styles.right]: right },
        { [styles.center]: center },
        className,
      ])}
      {...props}
  >
    <HR className={ styles.hr }/>
    {children}
  </div>
);

export default FormFooter;
