import { connect } from 'react-redux';
import { SidebarTabs } from 'components/ui/SidebarTabs';
import toJS from 'helpers/tojs';
import { changeTabs } from 'actions/filters';

const mapStateToProps = (state, props) => ({
});

const mapDispatchToProps = (dispatch, props) => ({
  onSelect: (firstTab, lastTab) => {
    if (firstTab !== lastTab) {
      dispatch(changeTabs(props.tabsname, firstTab, lastTab));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(SidebarTabs));
