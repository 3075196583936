import Immutable from 'immutable';
import * as actionsTypes from 'constants/actionTypes.js';

const initialState = Immutable.fromJS({
  isDisplayed: false,
  data: {},
});

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case actionsTypes.TERRITORY_NEW:
      return Immutable.fromJS({
          isDisplayed: true,
          isCreate: true,
          data: {},
        });

    case actionsTypes.TERRITORY_EDIT:
      return Immutable.fromJS({
          isDisplayed: true,
          isCreate: false,
          data: {},
        });

    case actionsTypes.TERRITORY_POPUP_CLOSE:
      return state.isDisplayed = false;

    case actionsTypes.TERRITORY_TERRITORY_GET_ITEM_SUCCESS:
      return state.set('data', payload.data);

    default:
      return state;
  }
};
