import {connect} from 'react-redux';
import PlannerByVehiclePage from 'components/planner/PlannerByVehiclePage';
import {getSelectedTree} from 'selectors/filters';
import toJS from 'helpers/tojs';
import {putTrailerVehicle} from 'actions/planner';
import {fetchVehicleTrailers} from "actions/reports";
import {getVehicleData} from 'selectors/planner';
import {
  plannerAttachVehicleTrailer,
  plannerDetachTrailer,
  plannerDeleteVehicleTrailer,
  plannerStart
} from 'actions/planner';

const mapStateToProps = (state) => ({
  vehicleTree: getSelectedTree(state, 'vehicle'),
  data: getVehicleData(state),
});

const mapDispatchToProps = dispatch => ({
  onInit: () => dispatch(plannerStart('vehicle')),
  loadData: (vehicleIds, from, to) => dispatch(fetchVehicleTrailers(vehicleIds, from, to)),
  putTrailerVehicle: (id, data, fetchParams) => dispatch(putTrailerVehicle(id, data, fetchParams)),
  attachTrailer: (id, params) => dispatch(plannerAttachVehicleTrailer(id, params)),
  detachTrailer: (id, fetchParams) => dispatch(plannerDetachTrailer(id, fetchParams)),
  deleteVehicleTrailer: (id, fetchParams) => dispatch(plannerDeleteVehicleTrailer(id, fetchParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(PlannerByVehiclePage));
