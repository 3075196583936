import { createSelector } from 'reselect';

const getServerData = state => state.get('planner');

export const isLoading = createSelector(
  [getServerData],
  data => data.get('isLoading')
);
export const getTrailersData = createSelector(
  [getServerData],
  data => data.get('trailersData')
);

export const getVehicleData = createSelector(
  [getServerData],
  data => data.get('vehicleData')
);

export const getWorkTypesData = createSelector(
  [getServerData],
  data => data.get('workTypesData')
);

export const getDriversData = createSelector(
  [getServerData],
  data => data.get('driversData')
);
