import React from 'react';
import { connect } from 'react-redux';
import TreeNode from 'components/filters/TreeNode';
import {getListItemByIdSelector} from "selectors/filters";
import {selectListItem} from "actions/filters";

const mapStateToProps = (state, {listName, id}) => {
  const itemData = getListItemByIdSelector(state, listName, id);
  if (itemData) {
    return {
      id: itemData.id.toString(),
      title: itemData.name,
      checked: itemData.selected,
      selected: itemData.selected,
    }
  }
};

const mapDispatchToProps = (dispatch, {listName}) => ({
  onClick: itemId => {
    dispatch(selectListItem(listName, itemId))
  },
  onSelect: itemId => {
    dispatch(selectListItem(listName, itemId))
  },
});

class ListItemContainer extends React.Component {
  render() {
    return (
      <TreeNode {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListItemContainer);
