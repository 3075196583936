import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  isVehicleTrailerOutDated,
  getVehicleCurrentTrailer,
} from 'selectors/trailer';
import { fetchVehicleCurrentTrailer } from 'actions/reports';

// TODO: move to components
const PopupContent = props => {
  const { id, text, onShow } = props;
  useEffect(() => {
    if (!onShow) {
      return;
    }
    onShow(id);
  });

  if (!text) {
    return null;
  }

  return <div>{text}</div>;
};

const mapStateToProps = (state, { id }) => ({
  text: getVehicleCurrentTrailer(state, id),
});

const mapDispatchToProps = (dispatch) => ({
  onShow: id => {
    dispatch((dispatch, getState) => {
      const isOutdated = isVehicleTrailerOutDated(getState(), id);
      if (isOutdated) {
        dispatch(fetchVehicleCurrentTrailer(id));
      }
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopupContent);
