import { connect } from 'react-redux';
import ArrowPopup from 'components/report-vehicle-track/ArrowPopup';
import { getTreeInnerNodePropById } from 'selectors/filters';
import { getTrackReportSpeed, getTrackReportMileage } from 'selectors/reports';

const mapStateToProps = (state, { id, date }) => ({
  vehicleName: getTreeInnerNodePropById(state, 'vehicle', id, true, 'name'),
  speed: getTrackReportSpeed(state, id, date),
  mileage: getTrackReportMileage(state, id, date),
});

export default connect(mapStateToProps)(ArrowPopup);
