import Immutable from 'immutable';
import { TERRITORY_LIST_FETCH_SUCCESS } from 'constants/actionTypes.js';
import { VEHICLES_DETAILS_RECEIVE } from 'constants/actionTypes.js';
import { VEHICLES_DETAILS_REMOVE } from 'constants/actionTypes.js';
import { REPORT_LOADING_TOGGLE } from 'constants/actionTypes.js';
import { MAP_TILES_CHANGED } from 'constants/actionTypes.js';
import { loadTiles, saveTiles} from 'reducers/reports/helpers';
import {REPORT_IMPORT_RUNNING_TOGGLE, TERRITORY_UPDATE_DATA_SUCCESS} from "constants/actionTypes";

const initialState = Immutable.fromJS({
  territories: [],
  vehicles: [],
  loading: false,
  runningImport: false,
  progress: null,
  tiles: loadTiles('location'),
});

export default (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case TERRITORY_LIST_FETCH_SUCCESS:
      let territories = Immutable.List();
      payload.data.forEach(({ id, color, name, description, area, coordinates = [] }) => {
        if (!coordinates.length) {
          return; // skip
        }
        territories = territories.push(
          Immutable.Map({
            id,
            color,
            description: name || description,
            positions: Immutable.fromJS(coordinates),
            area,
          })
        );
      });

      return state.set('territories', territories);


    case TERRITORY_UPDATE_DATA_SUCCESS:
      const item = payload.data;
      const { id, color, plot:{cadastre_number}={}, field:{name}, coordinates } = item;
      const territoryName = cadastre_number || name;
      const index = state
        .get('territories')
        .findIndex(item => item.get('id') === id);
      if (index < 0) {
        return state.update('territories', l => l.push(
          Immutable.Map({
            id,
            color,
            description: territoryName,
            positions: Immutable.fromJS(coordinates),
          })
        ));
      }
      return state.updateIn(['territories', index], item =>
        item.set('color', color).set('description', territoryName).set('positions', Immutable.fromJS(coordinates))
      );

    case VEHICLES_DETAILS_RECEIVE:
      const vehicles = Immutable.fromJS(payload.vehicles);
      if (!vehicles.size) {
        return state;
      }
      return state.updateIn(['vehicles'], item =>
        item.concat(
          vehicles.map(vehicle =>
            vehicle
              .set('latitude', vehicle.getIn(['lastGPS', 'latitude']))
              .set('longitude', vehicle.getIn(['lastGPS', 'longitude']))
              .delete('lastGPS')
          )
        )
      );

    case VEHICLES_DETAILS_REMOVE:
      const ids = Immutable.fromJS(payload.ids);
      return state.updateIn(['vehicles'], item =>
        item.filter(item => ids.every(id => item.get('id') !== id))
      );

    case REPORT_LOADING_TOGGLE:
      if (payload.report !== 'location') {
        return state;
      }
      return state.set('loading', Immutable.fromJS(payload.loading));
    case REPORT_IMPORT_RUNNING_TOGGLE:
      if (payload.report !== 'location') {
        return state;
      }
      return state.set('runningImport', payload.running).set('progress', payload.progress);;

    case MAP_TILES_CHANGED: {
        const {report, tiles} = payload;
        if (report !== 'location') {
          return state;
        }
        saveTiles('location', tiles);
        return state.set('tiles', tiles);
      }

    default:
      return state;
  }
};

