import Immutable from 'immutable';
import * as actionsTypes from 'constants/actionTypes.js';

const initialState = Immutable.fromJS({
  formType: null,
  isDisplayed: false,
  fromDate: new Date(),
  toDate: null,
  trailerId: null,
  vehicleId: null,
  workTypeId: null,
  driverId: null,
});

export default (state = initialState, {type, payload, ...action}) => {
  switch (type) {
    case actionsTypes.PLANNER_ATTACH_WORK_TYPE_SHOW:
      return Immutable.fromJS({
        formType: payload.formType,
        attachTitle: payload.params.attachTitle,
        itemId: payload.params.item && payload.params.item.id,
        isDisplayed: true,
        trailerId: payload.id,
        fromDate: payload.params.start || payload.params.item.attached_at,
        toDate: payload.params.end || payload.params.item.unattached_at,
        workTypeId: payload.params.item && payload.params.item.work_type_id,
        fetchParams: payload.params.fetchParams,
      });
    case actionsTypes.PLANNER_ATTACH_DRIVER_SHOW:
      return Immutable.fromJS({
        formType: payload.formType,
        attachTitle: payload.params.attachTitle,
        itemId: payload.params.item && payload.params.item.id,
        isDisplayed: true,
        vehicleId: payload.id,
        fromDate: payload.params.start || payload.params.item.attached_at,
        toDate: payload.params.end || payload.params.item.unattached_at,
        driverId: payload.params.item && payload.params.item.driver_id,
        fetchParams: payload.params.fetchParams,
      });
    case actionsTypes.PLANNER_ATTACH_VEHICLE_SHOW:
    case actionsTypes.PLANNER_ATTACH_TRAILER_SHOW:
      const {id, formType, params: {item, start, end, attachTitle, fetchParams} = {}} = payload;
      return Immutable.fromJS({
        formType,
        attachTitle,
        itemId: item && item.id,
        isDisplayed: true,
        trailerId: id,
        fromDate: start || item.attached_at,
        toDate: end || item.unattached_at,
        vehicleId: item && item.vehicle_uid,
        fetchParams,
      });
    case actionsTypes.TRAILER_ATTACH_TIMELINE_POPUP_CLOSE:
      return state.set('isDisplayed', false);

    default:
      return state;
  }
};
