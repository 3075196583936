import {connect} from 'react-redux';
import Panel from 'components/filters/WorkType/Panel';
import {newWorkType, editWorkType} from 'actions/WorkType/index';
import {loadEntity} from 'actions/common';
import {updateListFilter, filterList} from 'actions/filters';
import toJS from 'helpers/tojs';
import {getListItemSelected, isListItemsSelected} from 'selectors/filters';
import { getTreeSearchValue } from 'selectors/filters';

const mapStateToProps = (state, props) => ({
  isItemSelected: isListItemsSelected(state, 'workType'),
  searchValue: getTreeSearchValue(state, 'workType'),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onNewItem: () => {
      dispatch(newWorkType());
    },
    onEditItem: () =>
      dispatch((dispatch, getState) => {
        const item = getListItemSelected(getState(), 'workType').toJS();
        dispatch(loadEntity('trailer', 'work_type', item.id));
        dispatch(editWorkType(item));
      }),
    onSearchChange: (event) => {
      dispatch(updateListFilter('workType', event.target.value));
    },
    onSearchStart: (searchValue) => {
      dispatch(filterList('workType', searchValue));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Panel));
