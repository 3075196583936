import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import {saveJSON, loadJSON} from "helpers/utils";
import styles from './style.module.scss';
import Pagination from 'containers/features/reports/ReactTable/Pagination';

const reportName = 'agroEventsReport';

const columns = [
  {
    Header: 'Наименование ТС',
    accessor: 'vehicle_name',
  },
  {
    Header: 'Дата и время',
    accessor: 'event_at',
  },
  {
    Header: 'Участок/поле',
    accessor: 'territory_name',
  },      {
    Header: 'Событие',
    accessor: 'even_name',
  },
  {
    Header: 'Параметры',
    accessor: 'parameters',
  },
];

const prepareExportRow = (item) => {
  return [
    item['vehicle_name'],
    item['event_at'],
    item['even_name'],
    item['parameters'],
    item['territory_name'],
  ]
};
const exportTableHeaders = [
  'Транспортное средство',
  'Дата и время',
  'Событие',
  'Параметры',
  'Поле/кадастровый участок',
];

const LOCALSTORGE_KEY = `${reportName}:hidden:columns`;
const saveHiddenColumns = saveJSON(LOCALSTORGE_KEY);
const loadHiddenColumns = loadJSON(LOCALSTORGE_KEY, []);

class Page extends React.PureComponent {

  state = {
    hiddenColumns: loadHiddenColumns(),
    defaultPopupOpen: false,
  };

  handleToggleColumn = (accessor, visible) => {
    const hiddenColumns = visible
      ? this.state.hiddenColumns.filter(a => a !== accessor)
      : [...this.state.hiddenColumns, accessor];
    this.setState({ hiddenColumns });
    saveHiddenColumns(hiddenColumns);
  };

  handleToggleOpened = (opened) => {
    this.setState({ defaultPopupOpen: opened });
  };

  render = () => {
    let data = this.props.data;

    const visibleColumns = columns
      .filter(({accessor}) => this.state.hiddenColumns.findIndex(a => a === accessor) < 0)
      .map(props => ({headerStyle: {whiteSpace: "normal"}, ...props}));

    return (
      <div className={styles.wrapper}>
        <ReactTable
          className={CN([styles.table, "-striped", "-highlight"])}
          data={data}
          loading={this.props.loading || this.props.runningImport}
          columns={visibleColumns}
          defaultSorted={[
            {
              id: "event_at",
              desc: false,
            }
          ]}
          pageSizeOptions={[10, 20, 50, 100]}
          minRows={0}
          previousText="Предыдущая"
          nextText="Следующая"
          loadingText={this.props.runningImport && this.props.progress !== null ? `Подождите, синхронизируются данные. ${this.props.progress}%` : "Загрузка..."}
          noDataText="Данных не найдено"
          pageText="Страница"
          ofText="из"
          rowsText="строк"
          PaginationComponent={props => (
            <Pagination
              {...props}
              reportName={reportName}
              prepareRow={prepareExportRow}
              columns={columns.map(({Header, accessor }) => ({Header, accessor, visible: this.state.hiddenColumns.findIndex(a => a === accessor) < 0}))}
              defaultPopupOpen={this.state.defaultPopupOpen}
              onToggleColumn={this.handleToggleColumn}
              exportHeaders={exportTableHeaders}
              onTogglePopup={this.handleToggleOpened}
            />
          )}
        />
      </div>
    )
  };
}

Page.propTypes = {
  data: PT.array,
  loading: PT.bool,
};

export default Page;
