import React from 'react';
import {Then, Else} from 'react-if';
import IfReportLoading from 'containers/features/reports/IfReportLoading';
import TrackMap from 'containers/features/reports/TrackMap';
import TrackMarker from 'components/report-vehicle-track/TrackMarker';
import VehiclePopup from 'containers/features/reports/VehiclePopup';
import ArrowPopup from 'containers/features/reports/ArrowPopup';
import styles from './style.module.scss';
import IfReportImportRunningProgress from "containers/features/reports/IfReportImportRunningProgress";

const Page = () => (
  <div className={styles.wrapper}>
    <IfReportImportRunningProgress report={'track'}>
        <IfReportLoading report={'track'}>
          <Then>{() => <div>Загрузка</div>}</Then>
          <Else>
            {() => (
              <TrackMap
                fitToBounds
                markerClusterRadius={0}
                useDefaultMarkerIcon={marker => marker.icon}
                markerNode={marker => (
                  <TrackMarker color={marker.color} direction={marker.direction} />
                )}
                markerClusterIconFunction={cluster => {
                  const markers = cluster.getAllChildMarkers();
                  const indexMiddle = Math.floor(markers.length / 2);
                  return markers[indexMiddle].options.icon;
                }}
                markerPopup={id => <VehiclePopup id={id} />}
                arrowPopup={(id, {date}) => <ArrowPopup id={id} date={date} />}
              />
            )}
          </Else>
        </IfReportLoading>
    </IfReportImportRunningProgress>
  </div>
);

export default Page;
