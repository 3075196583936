import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import {Form, FormGroup, Label, Col, Row, Table} from 'reactstrap';
import moment from 'moment';
import Modal from 'components/ui/Modal';
import Datetime from 'components/ui/Datetime';
import Button from 'components/ui/Button';
import styles from './style.module.scss';
import Input from 'components/ui/Input';

const defaultTimeConstraints = {
  hours: {
    min: 0,
    max: 23,
    step: 1,
  },
  minutes: {
    min: 0,
    max: 59,
    step: 1,
  },
};

const getValidTimesFrom = (from, to) => {
  if (to && moment(from).isSame(to, 'day')) {
    return {
      hours: {
        min: 0,
        max: moment(to).hours(),
        step: 1,
      },
      minutes: {
        min: 0,
        max: 59,
        step: 1,
      },
    };
  }
  return defaultTimeConstraints;
};

const getValidTimesTo = (from, to) => {
  if (moment(from).isSame(to, 'day')) {
    return {
      hours: {
        min: moment(from).hours(),
        max: 23,
        step: 1,
      },
      minutes: {
        min:  0,
        max: 59,
        step: 1,
      },
    };
  }
  return defaultTimeConstraints;
};

class AttachPopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => ({
    trailerId: null,
    vehicleId: null,
    workTypeId: null,
    driverId: null,
    fromDate: new Date(),
    toDate: null,
    searchValue: null,
  });

  onOk = () => {
    if (!this.props.onOk) {
      return;
    }
    const {workTypeId, driverId, vehicleId, fromDate, toDate, trailerId} = this.state;
    const fromTime = fromDate ? moment(fromDate).toDate().getTime() : null;
    const toTime = toDate ? moment(toDate).toDate().getTime() : null;
    this.props.onOk(this.props.formType, workTypeId, driverId, vehicleId, fromTime, toTime, trailerId, this.props.itemId, this.props.fetchParams);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.isDisplayed && !this.props.isDisplayed) {
      this.setState(this.getDefaultState());
    }
    if (this.props.isDisplayed && !prevProps.isDisplayed) {
      const {workTypeId, driverId, vehicleId, trailerId, fromDate, toDate} = this.props;
      this.setState({
        trailerId,
        vehicleId,
        workTypeId,
        driverId,
        fromDate: fromDate ? new Date(fromDate) : new Date(),
        toDate: toDate ? new Date(toDate) : null
      });
    }
  }

  getItemPropName = () => {
    switch (this.props.formType) {
      case "trailer":
        return "vehicleId";
      case "vehicle":
        return "trailerId";
      case "work_type":
        return "workTypeId";
      case "driver":
        return "driverId";
    }
  };

  selectItem = id => () => {
    this.setState({[this.getItemPropName()]: id});
  };

  onChangeDate = (field) => (m) => {
    this.setState({[field]: m ? moment(m).toDate() : null});
  };

  isOptionSelected = value => this.state[this.getItemPropName()] === value;

  render = () => {
    return this.renderWithProps(this.props);
  };

  onSearchStart = (searchValue) => this.setState({searchValue});

  onSearchChange = ({target: {value}}) => this.setState({searchValue: value});

  getActionType = (formType) => {
    switch (formType) {
      case "trailer":
        return 'Выберите ТС';
      case "vehicle":
        return 'Выберите навесное оборудование';
      case "work_type":
        return 'Выберите вид работ';
      case "driver":
        return 'Выберите водителя'
    }
  };

  getItemsLabel = (formType) => {
    switch (formType) {
      case "trailer":
        return 'ТС';
      case "vehicle":
        return 'Навесное оборудование';
      case "work_type":
        return 'Вид работ';
      case "driver":
        return "Водитель";
    }
  };

  getSearchItemsLabel = (formType) => {
    switch (formType) {
      case "trailer":
        return 'ТС';
      case "vehicle":
        return 'оборудование';
      case "work_type":
        return 'вид работ';
      case "driver":
        return "водителя";
    }
  };
  renderWithProps = props => (
    <Modal
      isDisplayed={props.isDisplayed}
      onToggle={props.onClose}
      className={styles.modal}
      header={this.getActionType(props.formType) + (props.attachTitle ? ' для ' + props.attachTitle : '')}
      content={
        <div className={styles.container}>
          <Form>
            <Row form className={styles.rowContainer}>
              <Col md={6}>
                <FormGroup>
                  <Label for="fromDate">Дата с</Label>
                  <Datetime
                    id="fromDate"
                    value={this.state.fromDate}
                    onChange={this.onChangeDate('fromDate')}
                    isValidDate={(current) => !this.state.toDate || current.isSameOrBefore(moment(this.state.toDate))}
                    timeConstraints={getValidTimesFrom(this.state.fromDate, this.state.toDate)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="toDate">Дата по</Label>
                  <Datetime
                    id="toDate"
                    value={this.state.toDate}
                    onChange={this.onChangeDate('toDate')}
                    isValidDate={
                      (current) => !this.state.fromDate
                        || current.isAfter(moment(this.state.fromDate).subtract(1, 'day'))
                    }
                    timeConstraints={getValidTimesTo(this.state.fromDate, this.state.toDate)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row form className={styles.rowContainer}>
              <Col md={8}>
                <Label className="mt-2"
                       for="list">{this.getItemsLabel(props.formType)}</Label>
              </Col>
              <Col md={4}>
                <Input
                  className="mb-1"
                  placeholder={'Найти ' + this.getSearchItemsLabel(props.formType) + '...'}
                  icon="search"
                  value={this.state.searchValue || ''}
                  onChange={this.onSearchChange}
                  onSearch={this.onSearchStart}
                />
              </Col>
            </Row>

            <Row form className={CN(styles.rowContainer, styles.tableContainer)}>
              <Col md={12}>
                <Table size={'sm'}>
                  <tbody className={styles.tableBody}>
                  {(props.items || [])
                  .filter(({caption}) => !this.state.searchValue || !caption || caption.replace(/\s+/g, " ").toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1)
                  .map(({value, caption}, index) => (
                    <tr
                      key={index}
                      className={CN({
                        [styles.selected]: this.isOptionSelected(value),
                      })}
                      onClick={this.selectItem(value)}
                    >
                      <td>
                        <input
                          name="list"
                          type="radio"
                          value={value}
                          checked={this.isOptionSelected(value)}
                          readOnly
                        />
                      </td>
                      <td>{caption}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Form>
        </div>
      }
      buttons={
        <React.Fragment>
          <Button onClick={this.onOk}>{props.confirmCaption}</Button>
          <Button onClick={props.onClose}>{props.cancelCaption}</Button>
        </React.Fragment>
      }
    />
  );
}

AttachPopup.propTypes = {
  isDisplayed: PT.bool,
  header: PT.string,
  items: PT.arrayOf(
    PT.shape({value: PT.oneOfType([PT.number, PT.string]), caption: PT.string})
  ),
  onOk: PT.func,
  onClose: PT.func,
  vehicleId: PT.string,
  trailerId: PT.oneOfType([PT.number, PT.string]),
  fromDate: PT.oneOfType([PT.number, PT.object]),
  toDate: PT.oneOfType([PT.number, PT.object])
};

AttachPopup.defaultProps = {
  confirmCaption: 'ОК',
  cancelCaption: 'Отмена',
  vehicleId: null,
  trailerId: null,
  fromDate: null,
  toDate: null
};

export default AttachPopup;
