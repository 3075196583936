import React from 'react';
import PT from 'prop-types';
import Checkbox from 'components/ui/Checkbox';
import style from './style.module.scss';

const ActivityCheckboxFilter = props => {
  const { color, title, count, checked, onClick } = props;

  return (
    <div className={style.container} title={title}>
      <Checkbox
        color={color}
        checked={checked}
        disabled={count === 0}
        onClick={onClick}
      />
      <div className={style.counter}>{count}</div>
    </div>
  );
};
ActivityCheckboxFilter.propTypes = {
  color: PT.string,
  title: PT.string,
  count: PT.number,
  checked: PT.bool,
  onClick: PT.func,
};
ActivityCheckboxFilter.defaultProps = {
  count: 0,
};

export default ActivityCheckboxFilter;
