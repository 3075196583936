import Immutable from 'immutable';
import { VEHICLES_TRACKS_RECEIVE } from 'constants/actionTypes.js';
import { VEHICLES_TRACKS_REMOVE } from 'constants/actionTypes.js';
import { REPORT_LOADING_TOGGLE } from 'constants/actionTypes.js';
import { MAP_TILES_CHANGED } from 'constants/actionTypes.js';
import { loadTiles, saveTiles} from 'reducers/reports/helpers';
import {REPORT_IMPORT_RUNNING_TOGGLE} from "constants/actionTypes";

const initialState = Immutable.fromJS({
  tracks: [],
  loading: false,
  runningImport: false,
  progress: null,
  tiles: loadTiles('track'),
});

export default (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case VEHICLES_TRACKS_RECEIVE:
      const tracksMap = Immutable.fromJS(payload.tracks);
      const tracksList = tracksMap
        .keySeq()
        .map(id => Immutable.Map({
          id,
          track:
            tracksMap
              .get(id)
              .filter(dropEqualPositions)
        }))
        .filter(item => item.get('track').size > 0);
      return state.updateIn(['tracks'], item => item.concat(tracksList));

    case VEHICLES_TRACKS_REMOVE:
      const ids = Immutable.fromJS(payload.ids);
      return state.updateIn(['tracks'], item =>
        item.filter(item => ids.every(id => item.get('id') !== id))
      );

    case REPORT_LOADING_TOGGLE:
      if (payload.report !== 'track') {
        return state;
      }
      return state.set('loading', Immutable.fromJS(payload.loading));
    case REPORT_IMPORT_RUNNING_TOGGLE:
      if (payload.report !== 'track') {
        return state;
      }
      return state.set('runningImport', payload.running).set('progress', payload.progress);;
    case MAP_TILES_CHANGED: {
      const { report, tiles } = payload;
      if (report !== 'track') {
        return state;
      }
      saveTiles('track', tiles);
      return state.set('tiles', tiles);
    }

    default:
      return state;
  }
};

const dropEqualPositions = (point, index, self) =>
  self.findLastIndex(item => isPositionEqual(point, item)) === index;

const isPositionEqual = (pointA, pointB) =>
  pointA.get('latitude') === pointB.get('latitude') &&
  pointA.get('longitude') === pointB.get('longitude');
