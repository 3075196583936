import {connect} from 'react-redux';
import PlannerByWorkTypesPage from 'components/planner/PlannerByWorkTypesPage';
import {getSelectedTree} from 'selectors/filters';
import toJS from 'helpers/tojs';
import {fetchTrailerWorkTypes, putTrailerWorkType} from 'actions/planner';
import {getWorkTypesData} from 'selectors/planner';
import {
  plannerAttachTrailerWorkType,
  plannerDeleteWorkTypeTrailer,
  plannerStart
} from 'actions/planner';

const mapStateToProps = (state) => ({
  trailerTree: getSelectedTree(state, 'trailer'),
  data: getWorkTypesData(state),
});

const mapDispatchToProps = dispatch => ({
  onInit: () => dispatch(plannerStart('trailer')),
  loadData: (trailerIds, from, to) => dispatch(fetchTrailerWorkTypes(trailerIds, from, to)),
  putTrailerVehicle: (id, data, fetchParams) => dispatch(putTrailerWorkType(id, data, fetchParams)),
  attachVehicle: (id, params) => dispatch(plannerAttachTrailerWorkType(id, params)),
  deleteVehicleTrailer: (id, fetchParams) => dispatch(plannerDeleteWorkTypeTrailer(id, fetchParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(PlannerByWorkTypesPage));
