import escape from 'regexp.escape';
import Immutable from 'immutable';
import TreeUtils from 'immutable-treeutils';
import {
  TREE_NODE_SELECT,
  TREE_NODE_CHECKBOX_CLICK,
  TREE_NODE_OPENED_TOGGLE,
  TREE_DATA_FETCH_SUCCESS,
  TREE_FILTER,
  TREE_ALL_CHECKBOXES_CLEAR,
} from 'constants/actionTypes.js';
import { removeExtraSpaces, replaceSimilarRuSymbols } from "helpers/utils";
import { LOCAL_STORAGE_KEY_PREFIX } from 'constants/index.js';

const treeUtils = new TreeUtils(Immutable.Seq(), 'id', 'children');

export default (state, { type, payload, ...action }, name) => {
  if (
    type !== TREE_NODE_SELECT &&
    type !== TREE_NODE_CHECKBOX_CLICK &&
    type !== TREE_NODE_OPENED_TOGGLE &&
    type !== TREE_DATA_FETCH_SUCCESS &&
    type !== TREE_FILTER &&
    type !== TREE_ALL_CHECKBOXES_CLEAR
  ) {
    return state;
  }

  const treeName =
    payload.treeName || action.meta.previousAction.payload.treeName;

  if (!treeName.match(name)) {
    return state;
  }

  const { id, leaf, parentId } = payload;
  const keyPath = getTreeNodeKeyPath(state, id, leaf, parentId);
  let path = getKeyPathSeq(keyPath);

  switch (type) {
    case TREE_DATA_FETCH_SUCCESS:
      const serverData = Immutable.fromJS(payload.data);
      const data = convertToFrontendFormat(serverData, true);
      let stateNew = data.get(0);
      // sync opened folders
      const openIds = findIds(state, item => item.get('opened') === true);
      stateNew = update(stateNew, openIds, item => item.set('opened', true));
      // sync checkboxes
      const checkedIds = findIds(
        state, item => !item.get('children') && item.get('checked') === true);
      forEachNodeIds(stateNew, checkedIds, keyPath => {
        if (!!stateNew.getIn(keyPath).get('children')) {
          return; // skip folders
        }
        const path = getKeyPathSeq(keyPath);
        stateNew = toggleNodeChecked(stateNew, path, true);
        // stateNew = toggleChildsChecked(stateNew, path, true);
        stateNew = toggleParents(stateNew, path);
      });
      // sync hidden folders
      const hiddenFoldersIds = findIds(state, item =>
        !!item.get('children') &&
        item.get('hidden') === true
      );
      stateNew = update(stateNew, hiddenFoldersIds, item =>
        !!item.get('children') ? item.set('hidden', true) : item);
      // sync hidden leafs
      const hiddenLeafsIds = findIds(state, item =>
        !item.get('children') &&
        item.get('hidden') === true
      );
      stateNew = update(stateNew, hiddenLeafsIds, item =>
        !item.get('children') ? item.set('hidden', true) : item);

      return stateNew;

    case TREE_NODE_SELECT:
      // const selected = state.getIn(path).get('selected');
      const selected = false;
      state = clearTreeSelection(state);

      return state.updateIn(path, item => item.set('selected', !selected));

    case TREE_NODE_CHECKBOX_CLICK:
      const checkedNext = !isChecked(state, path);
      state = toggleNodeChecked(state, path, checkedNext);
      state = toggleChildsChecked(state, path, checkedNext);
      state = toggleParents(state, path);

      return state;

    case TREE_NODE_OPENED_TOGGLE:
      if (!state.getIn(keyPath).get('children')) {
        return state;
      }
      const opened = state.getIn(keyPath).get('opened');

      return state.updateIn(keyPath, item => item.set('opened', !opened));

    case TREE_FILTER:
      const searchValue = escape(replaceSimilarRuSymbols(removeExtraSpaces(payload.searchValue)));
      const visibleLeafIds = payload.ids;
      const isEmptyValue = searchValue === '';
      const isEmptyIds = !visibleLeafIds || visibleLeafIds.length === 0;
      const isEmptySearch = isEmptyValue && isEmptyIds;
      const regExp = new RegExp(searchValue, "i");

      // close all folders, hide if search enabled
      let newState = update(state, null, item => {
        let result = item.get('children') ? item.set('opened', false) : item;
        result = result.set('hidden', !isEmptySearch);
        return result;
      });

      if (isEmptySearch) {
        return newState; // search empty, so no need to open folders
      }

      const keyPaths = treeUtils.filter(newState, item => {
        const nodeId = item.getIn(['node', 'id']);
        const value = replaceSimilarRuSymbols(removeExtraSpaces((item.getIn(['node', 'name']) || '')));
        const isValueMatched = isEmptyValue || value.search(regExp) >= 0;
        const isNodeMatched = isEmptyIds || visibleLeafIds.indexOf(nodeId) >= 0;

        return isValueMatched && isNodeMatched;
      });

      // console.log('TREE_FILTER', keyPaths.toJS());
      // for all matched nodes
      for (const keyPath of keyPaths) {
        // show matched node
        newState = newState.updateIn(keyPath, item => item.set('hidden', false));
        // console.log('parentKeyPaths', treeUtils.ancestors(newState, keyPath).toJS());
        for (const parentKeyPath of treeUtils.ancestors(newState, keyPath)) {
          // console.log('parentKeyPath', parentKeyPath.toJS());
          // show and open parent folder
          newState = newState.updateIn(parentKeyPath, item => item.set('hidden', false).set('opened', true));
        }
        // show children nodes for matched folders
        if (treeUtils.hasChildNodes(newState, keyPath)) {
          newState = newState.updateIn(keyPath, item => item.set('opened', true));
          for (const childKeyPath of treeUtils.childNodes(newState, keyPath)) {
            // console.log('childKeyPath', childKeyPath.toJS());
            newState = newState.updateIn(childKeyPath, item => item.set('hidden', false));
          }
        }
      }
      // console.log('newState', newState.toJS());

      return newState;

    case TREE_ALL_CHECKBOXES_CLEAR:
      const pathRoot = getKeyPathSeq([]);
      state = toggleNodeChecked(state, pathRoot, false);
      state = toggleChildsChecked(state, pathRoot, false);
      return state;

    default:
      return state;
  }
};

/** Find tree node
 * Tree leafs ids may be not unique, so find leafs using parent folder id
 * Tree folders and leafs may have same ids, so find folders using leaf flag
 * @param {object} tree - tree structure.
 * @param {string|number} id - tree node id.
 * @param {bool} leaf - is tree node is not folder.
 * @param {number} parentId - parent folder id.
 */
const getTreeNodeKeyPath = (tree, id, leaf, parentId) => {
  const keyPathFolder = treeUtils.find(tree, item =>
    item.getIn(['node', 'id']) === parentId && !!item.get('children')
  )

  for (const childKeyPath of treeUtils.childNodes(tree, keyPathFolder)) {
    const item = tree.getIn(childKeyPath);
    if (item.getIn(['node', 'id']) === id && !!item.get('children') !== leaf) {
      return childKeyPath;
    }
  }

  return null; // unreachable
}

const getKeyPathSeq = keyPath => Immutable.Seq(keyPath);

const clearTreeSelection = tree => {
  let result = tree;
  getAllChildPaths(tree, Immutable.Seq(), false).forEach(path => {
    result = result.updateIn(path, item => item.set('selected', false));
  });

  return result;
};

const isChecked = (tree, path) => tree.getIn(path).get('checked');

const toggleNodeChecked = (tree, path, checked) =>
  tree.updateIn(path, item =>
    item.set('checked', checked).set('indeterminate', false)
  );

const toggleChildsChecked = (tree, path, checked) => {
  if (!treeUtils.hasChildNodes(tree, path)) {
    return tree;
  }

  let result = tree;
  const childs = getAllChildPaths(tree, path);

  childs.forEach(path => {
    result = result.updateIn(path, item =>
      item.set('checked', checked).set('indeterminate', false)
    );
  });

  return result;
};

const getAllChildPaths = (tree, path, skipCurrent = true) => {
  let result = Immutable.List();
  treeUtils.walk(
    tree,
    (acc, nodePath) => {
      if (skipCurrent && nodePath === path) {
        return;
      }
      result = result.push(nodePath);
    },
    path
  );

  return result;
};

const toggleParents = (tree, path) => {
  let result = tree;

  treeUtils.ancestors(tree, path).forEach(path => {
    const isAllChecked = isAllChildsHasProp(result, path, 'checked', true);
    const isAllUnChecked = isAllChildsHasProp(result, path, 'checked', false);
    result = result.updateIn(path, item =>
      item
        .set('checked', isAllChecked)
        .set('indeterminate', !isAllChecked && !isAllUnChecked)
    );
  });

  return result;
};

const isAllChildsHasProp = (tree, path, prop, value, defaultValue = false) => {
  let result = true;
  getAllChildPaths(tree, path).forEach(path => {
    const nodeValue = tree.getIn(path).get(prop) || defaultValue;
    if (nodeValue !== value) {
      result = false;
    }
  });

  return result;
};

export const forEachNode = (state, iterator, path = []) => {
  if (!state) {
    return;
  }

  let stack = Immutable.Stack.of(path);

  while (stack.size > 0) {
    let keyPath = stack.first();
    iterator(state.getIn(keyPath), keyPath);
    stack = stack.shift();

    let children = state.getIn(keyPath.concat('children'));
    if (children && children.size > 0) {
      stack = stack.unshiftAll(
        children.keySeq().map(key => keyPath.concat('children', key))
      );
    }
  }
};

export const find = (tree, comparator) => {
  let result = null;
  forEachNode(tree, (item, keyPath) => {
    if (comparator(item)) {
      result = item;
    }
  });

  return result;
};

const convertToFrontendFormat = (serverTree, isRoot) =>
  serverTree.map(item => {
    const id = item.get('id') || item.get('uuid') ;
    const name = item.get('name') || item.get('description');
    const geozoneTypeId = item.get('geozone_type_id');
    const vehicleId = item.get('vehicle_uid');
    const isFolder = item.get('isFolder') || isRoot;
    const node = Immutable.Map({ id, name, geozoneTypeId, vehicleId, isFolder });

    if (!item.get('child_objects') && !item.get('child_folders')) {
      return Immutable.Map({ node });
    }

    return Immutable.Map({
      node,
      children: convertToFrontendFormat(
        item.get('child_folders').map(folder => folder.merge({ isFolder: true })).concat(item.get('child_objects'))
      ),
    });
  });

const findIds = (tree, comparator) => {
  let result = Immutable.List();
  forEachNode(tree, (item, keyPath) => {
    if (comparator(item)) {
      result = result.push(item.getIn(['node', 'id']));
    }
  });
  return result;
}

// todo: leaf flag (?)
const update = (tree, ids, updater) => {
  let result = tree;
  forEachNode(tree, (item, keyPath) => {
    const id = item.getIn(['node', 'id']);
    if (!ids || ids.indexOf(id) >= 0) {
      result = result.updateIn(keyPath, updater);
    }
  });

  return result;
}

const forEachNodeIds = (tree, ids, iterator) => {
  forEachNode(tree, (item, keyPath) => {
    const id = item.getIn(['node', 'id']);
    if (ids.indexOf(id) >= 0) {
      iterator(keyPath);
    }
  });
}

const getLocalStorageKey = name => `${LOCAL_STORAGE_KEY_PREFIX}-tree-${name}`;

export const loadState = name => {
  const initialState = { children: [] };
  const savedState = localStorage.getItem(getLocalStorageKey(name));
  return JSON.parse(savedState) || initialState;
};

export const saveState = (name, state) => {
  // show all tree items (reset search)
  const newState = update(state, null, item => item.set('hidden', false));

  localStorage.setItem(getLocalStorageKey(name), JSON.stringify(newState.toJS()));
};
