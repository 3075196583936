import { showModal } from 'actions/common';
import {
  isServerError,
  isServerErrorNotAuth,
  isServerErrorNotFound,
  isSupressError,
} from 'helpers/api';
import { getAccessTokenValue } from 'selectors/common';
import { AUTH_TOKEN_NAME, AUTH_TOKEN_VALUE_TPL, OMNICOMM_BASE_URL } from 'constants/index';

export default {
  interceptors: {
    request: [
      {
        success: function ({getState, dispatch, getSourceAction}, req) {
          const accessToken = getAccessTokenValue(getState());
          req.headers.common[AUTH_TOKEN_NAME] = AUTH_TOKEN_VALUE_TPL.replace('{token}', accessToken);
          return req;
        },
      }
    ],
    response: [
      {
        success: function({ getState, dispatch }, response) {
          return response;
        },
        error: function({ getState, dispatch }, error) {
          if (error.response) {
            const respStatus = error.response.status;

            // 302 => redirect to omnicomm
            if (isServerErrorNotAuth(respStatus)) {
              window.location.href = OMNICOMM_BASE_URL;
            }

            if (isSupressError(error.config.url)) {
              return Promise.reject(error);
            }

            // 5xx => show popup with error
            if (isServerError(respStatus)) {
              let message = error.toString();
              if (error.response.data && error.response.data.message) {
                message = error.response.data.message;
              }
              dispatch(showModal('server_error', message, 'Ошибка'));
            }

            // 404 => show popup with error
            if (isServerErrorNotFound(respStatus)) {
              dispatch(showModal('server_error', error.toString(), 'Ошибка'));
            }
          }
          return Promise.reject(error);
        },
      },
    ],
  },
};
