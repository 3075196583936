import { combineReducers } from 'redux-immutable';
import period from 'reducers/filters/period';
import territory from 'reducers/filters/territory';
import trailer from 'reducers/filters/trailer';
import vehicle from 'reducers/filters/vehicle';
import workType from 'reducers/filters/WorkType';

export default combineReducers({
  period,
  territory,
  trailer,
  vehicle,
  workType,
});
