import { combineReducers } from 'redux-immutable';
import search from 'reducers/filters/territory/search';
import tree from 'reducers/filters/territory/tree';
import importStatus from 'reducers/filters/territory/importStatus';

export default combineReducers({
  search,
  tree,
  importStatus,
});
