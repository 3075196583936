import React from 'react';
import PT from 'prop-types';
import Modal from 'components/ui/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import WorkTypeForm from '../Form';
import "react-datetime/css/react-datetime.css";


class WorkTypePopup extends React.PureComponent {

  render = () => {
    const props = this.props;
    return (
      <Modal
        isDisplayed={props.isDisplayed}
        onToggle={props.onClose}
        size={'lg'}
        onOpened={this.onOpened}
        header={
          (props.isCreate ? 'Добавление' : 'Редактирование') + ' вида работ'
        }
        content={
          <WorkTypeForm
              data={ props.data }
              onSubmit={props.onOk}
              onClose={props.onClose}
          />
        }
        hasFooter={false}
      />
    );
  }
}

WorkTypePopup.propTypes = {
  isDisplayed: PT.bool,
  isCreate: PT.bool,
  data: PT.object,
  onOk: PT.func,
  onClose: PT.func,
};

export default WorkTypePopup;
