import React from 'react';
import PT from 'prop-types';
import styles from './style.module.scss';
import ListItem from 'components/ui/ListItem';


const renderItem = (item) => (
  <ListItem {...item} />
);

class List extends React.PureComponent {
  render = () => (
    <div className={styles.listContainer}>
      <ul
        className={styles.list}
        style={{ marginLeft: `${this.props.offsetLeft}px` }}
      >
        {this.props.items.map((item, index) => (
          <li className={styles.listItem} key={index}>
            {this.props.renderItem(item)}
          </li>
        ))}
      </ul>
    </div>
  );
}

List.propTypes = {
  // public props
  items: PT.arrayOf(PT.string),
  offsetLeft: PT.number,
  renderItem: PT.func
};

List.defaultProps = {
  items: [],
  offsetLeft: 10,
  renderItem: renderItem
};

export default List;
