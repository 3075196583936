import React from 'react';
import PT from 'prop-types';
import styles from './style.module.scss';

const TrackMarker = React.memo(props => (
  <div className={styles.trackMarker}>
    <svg width="30px" height="30px" viewBox="-15 -15 30 30">
      <polygon
        transform={`rotate(${props.direction})`}
        fill={props.color}
        points="0,-10 5,10 0,7.5 -5,10"
        stroke="white"
        strokeWidth="1px"
      />
    </svg>
  </div>
));

TrackMarker.propTypes = {
  color: PT.string,
  direction: PT.number,
};

TrackMarker.defaultProps = {
  color: 'black',
  direction: 0,
};

export default TrackMarker;
