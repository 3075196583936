import Immutable from 'immutable';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import * as T from 'helpers/tree.js';
import { getDictionary, getDictionaryPropByProp } from 'selectors/common';
import { DRIVEN_TRAILER_COLOR } from 'constants/index.js';
import {
  VEHICLE_ACTIVITY_GRADUATIONS,
  NULL_ACTIVITY_COLOR,
  ACTIVITY_FILTERS
} from 'constants/index.js';

export const isImportDataOORunning = state => state.getIn(['filters', 'territory', 'importStatus', 'isRunning']);

export const importDataOOProgress = state => {
  const processed = state.getIn(['filters', 'territory', 'importStatus', 'processed']);
  const total = state.getIn(['filters', 'territory', 'importStatus', 'total']);
  return processed === null || !total ? null : Math.round((processed || 0) / total * 100);
};

const getPeriod = state => state.getIn(['filters', 'period']);

export const getCalendarModalProp = (state, prop) => state.getIn(['calendarModal', prop]);

export const getPeriodValue = createCachedSelector(
  [getPeriod, (state, prop) => prop],
  (period, prop) => period.get(prop)
)((state, prop) => `${prop}`);

export const getTreeSearchValue = (state, name, prop = 'value') =>
  state.getIn(['filters', name, 'search', prop]);

const getTree = (state, name) => state.getIn(['filters', name, 'tree']);

let flatten = (children, getChildren, level, parent) => Array.prototype.concat.apply(
  children.map(x => ({ ...x.node, level: level || 1, parent: parent || null })),
  children.map(x => flatten(getChildren(x) || [], getChildren, (level || 1) + 1, x.node.id))
);
let extractChildren = x => x.children;

export const getTreeItemsMap = createCachedSelector(
  [getTree],
  (tree) => flatten(extractChildren(tree.toJS()), extractChildren, 0, tree.getIn(['node', 'id']))
)((state, treeName) => `${treeName}`);

const getVisibleTree = createCachedSelector(
  [
    getTree,
  ],
  (tree) => {
    let result = filterHiddenNodes(tree);
    result = filterClosedFolders(result);

    return result;
  }
)((state, treeName) => `${treeName}`);

export const getSelectedTree = createCachedSelector(
  [getTree],
  (tree) => getConvertedTree(filterHiddenNodes(hasCheckedNodes(tree) ? filterCheckedNodes(tree) : tree))
)((state, treeName) => `${treeName}`);

const isNodeChecked = item =>  item.get('checked') || item.get('indeterminate');

const hasCheckedNodes = tree => tree.get("children") && tree.get("children").filter(isNodeChecked).size > 0;

const filterCheckedNodes = tree => {
  return tree.update('children', children =>
    children.filter(isNodeChecked).map(item => {
      if (!item.get('children')) {
        return item;
      }

      let childrenFiltered = filterCheckedNodes(item).get('children');
      return item.set('children', childrenFiltered);
    }));
};

const filterHiddenNodes = tree => {
  return tree.update('children', children =>
    children.filter(item => !item.get('hidden')).map(item => {
      if (!item.get('children')) {
        return item;
      }

      let childrenFiltered = filterHiddenNodes(item).get('children');
      return item.set('children', childrenFiltered);
    }));
};

const filterClosedFolders = tree => {
  return tree.update('children', children => children.map(item => {
    if (!item.get('children')) {
      return item;
    }
    if (!item.get('opened')) {
      return item.set('children', []);
    }
    let childrenFiltered = filterClosedFolders(item).get('children');
    return item.set('children', childrenFiltered);
  }));
};

export const getVisibleConvertedTree = createCachedSelector(
  [
    getVisibleTree,
  ],
  (tree) => getConvertedTree(tree)
)((state, treeName) => `${treeName}`);

/**
 * convert tree structure from internal format:
 * { node, selected, checked, indeterminate, children }
 * to Tree component format:
 * {id, title, children}
 * */
const getConvertedTree = tree => {
  let result = Immutable.Map({
    id: tree.getIn(['node', 'id']),
    title: tree.getIn(['node', 'name']),
    isFolder: tree.getIn(['node', 'isFolder'])
  });

  if (!tree.get('children')) {
    return result;
  }

  let childrenConverted = tree.get('children')
    .map(subTree => getConvertedTree(subTree));
  return result.set('children', childrenConverted);
};

export const getTreeItemProp = createCachedSelector(
  [
    getVisibleTree,
    (state, treeName, keyPath, prop) => treeName,
    (state, treeName, keyPath, prop) => keyPath,
    (state, treeName, keyPath, prop) => prop,
  ],
  (tree, treeName, keyPath, prop) =>
    keyPath && keyPath.length ?
      // tree.getIn(keyPath).get(prop) :
      tree.getIn([...keyPath, prop]) :
      tree.get(prop)
)(
  (state, treeName, keyPath, prop) => `${treeName}:${keyPath.join('.')}:${prop}`
);

const getTreeInnerNodeProp = createCachedSelector(
  [
    (state, treeName, keyPath, prop) => getTreeItemProp(state, treeName, keyPath, 'node'),
    (state, treeName, keyPath, prop) => prop,
  ],
  // (treeNode, prop) => treeNode.get(prop)
  (treeNode, prop) => treeNode ? treeNode.get(prop) : null
)(
  (state, treeName, keyPath, prop) => `${treeName}:${keyPath.join('.')}:${prop}`
);

export const getTreeItemPropById = createCachedSelector(
  [
    getTree,
    (state, treeName, id, leaf, prop) => id,
    (state, treeName, id, leaf, prop) => leaf,
    (state, treeName, id, leaf, prop) => prop,
  ],
  (tree, id, leaf, prop) => {
    const item =
      T.find(
        tree,
        item =>
          !!item.get('children') !== leaf && item.getIn(['node', 'id']) === id
      ) || Immutable.Map();
    return item.get(prop);
  }
)((state, treeName, id, leaf, prop) => `${treeName}:${id}:${leaf}:${prop}`);

export const getTreeInnerNodePropById = createCachedSelector(
  [
    (state, treeName, id, leaf, prop) => getTreeItemPropById(state, treeName, id, leaf, 'node'),
    (state, treeName, id, leaf, prop) => prop,
  ],
  (treeNode, prop) =>
    treeNode ? treeNode.get(prop) : null
)(
  (state, treeName, id, leaf, prop) => `${treeName}:${id}:${leaf}:${prop}`
);

export const getSelectedTreeNode = createCachedSelector(
  [
    getVisibleTree,
    (state, treeName, leaf) => treeName,
    (state, treeName, leaf) => leaf,
  ],
  (tree, treeName, leaf) => {
    const item = T.find(tree, item => item.get('selected') === true);
    return item && !!item.get('children') !== leaf
      ? item.get('node')
      : Immutable.Map();
  }
)((state, treeName, leaf) => `${treeName}:${leaf}`);

export const isTreeNodeSelected = createCachedSelector(
  [getSelectedTreeNode],
  node => node.keySeq().size > 0
)((state, treeName, leaf) => `${treeName}:${leaf}`);

export const getSelectedTreeNodeId = createCachedSelector(
  [getSelectedTreeNode],
  node => (node.keySeq().size > 0 ? node.get('id') : null)
)((state, treeName, leaf) => `${treeName}:${leaf}`);

export const getTreeNodeIds = createCachedSelector(
  [
    getTree,
    (state, treeName, leaf) => treeName,
    (state, treeName, leaf) => leaf,
  ],
  (tree, treeName, leaf) => {
    let result = Immutable.List();

    T.forEachNode(tree, item => {
      if (leaf && item.get('children')) {
        return;
      }
      if (!leaf && !item.get('children')) {
        return;
      }
      result = result.push(item.getIn(['node', 'id']));
    });

    return result;
  }
)((state, treeName, leaf) => `${treeName}:${leaf}`);

export const getCheckedTreeNodeIds = createCachedSelector(
  [
    getTree,
    (state, treeName, leaf) => treeName,
    (state, treeName, leaf) => leaf,
  ],
  (tree, treeName, leaf) => {
    let result = Immutable.List();

    T.forEachNode(tree, item => {
      if (leaf && item.get('children')) {
        return;
      }
      if (!leaf && !item.get('children')) {
        return;
      }
      if (item.get('checked') === true) {
        result = result.push(item.getIn(['node', 'id']));
      }
    });

    return result;
  }
)((state, treeName, leaf) => `${treeName}:${leaf}`);

export const getCheckedTreeNodesCount = (state, treeName, leaf) => {
  const ids = getCheckedTreeNodeIds(state, treeName, leaf);
  return ids === null ? 0 : ids.size;
};

export const getCheckedTreeNodes = createCachedSelector(
  [
    getTree,
    (state, treeName, leaf) => treeName,
    (state, treeName, leaf) => leaf,
  ],
  (tree, treeName, leaf) => {
    let result = Immutable.List();

    T.forEachNode(tree, item => {
      if (leaf && item.get('children')) {
        return;
      }
      if (!leaf && !item.get('children')) {
        return;
      }
      if (item.get('checked') === true) {
        result = result.push(item.get('node'));
      }
    });

    return result;
  }
)((state, treeName, leaf) => `${treeName}:${leaf}`);

export const getTreeItemLeafType = createCachedSelector(
  [
    getVisibleTree,
    (state, treeName, keyPath) => treeName,
    (state, treeName, keyPath) => keyPath,
    (state) => getDictionaryPropByProp(state, 'geozoneTypes', 'alias', 'plot', 'id'),
    (state) => getDictionaryPropByProp(state, 'geozoneTypes', 'alias', 'field', 'id'),
  ],
  (tree, treeName, keyPath, plotId, fieldId) => {
    if (treeName !== 'territory') {
      return null;
    }
    const geozoneTypeId = tree.getIn(keyPath).getIn(['node', 'geozoneTypeId']);
    if (geozoneTypeId === plotId) {
      return 'plot';
    }
    if (geozoneTypeId === fieldId) {
      return 'field';
    }
    return null;
  }
)((state, treeName, keyPath) => `${treeName}:${keyPath.join('.')}`);

export const getTreeItemColor = createCachedSelector(
  [
    getVisibleTree,
    (state, treeName, keyPath, leaf) => treeName,
    (state, treeName, keyPath, leaf) => keyPath,
    (state, treeName, keyPath, leaf) => leaf,
    (state, treeName, keyPath) => getTreeInnerNodeProp(state, treeName, keyPath, 'vehicleId'),
  ],
  (tree, treeName, keyPath, leaf, vehicleId) => {
    if (treeName !== 'trailer' || !leaf || !vehicleId) {
      return undefined;
    }

    return DRIVEN_TRAILER_COLOR;
  }
)((state, treeName, keyPath, leaf) => `${treeName}:${keyPath.join('.')}:${leaf}`);

export const getSelectedTrailerVehicleId = createSelector(
  [
    (state) => getSelectedTreeNode(state, 'trailer', true)
  ],
  (node) => {
    if (!node) {
      return null;
    }

    return node.get('vehicleId');
  }
);

export const isSelectedTrailerVehicleAttached = state =>
  getSelectedTrailerVehicleId(state) !== null;


const getList = (state, name) => state.getIn(['filters', name, 'list']);

export const getListItems = createCachedSelector(
  [getList, (state, listName) => listName],
  (list) => list.filter(item => !item.get('hidden'))
)((state, listName) => `${listName}`);

export const getListItemsIdsSelector = createCachedSelector(
  [ getListItems, (state, listName)  => listName],
  (listItems) => {
    return listItems.keySeq().toArray();
  }
)((state, listName) => `${listName}Ids`);

export const getListItemByIdSelector = createCachedSelector(
  [
    getListItems,
    (state, listName, id) => listName,
    (state, listName, id) => id
  ],
  (listItems, listName, id) => {
    let itemsMap = listItems.toJS();
    return itemsMap[id.toString()];
  }
)((state, listName, id) => `${listName}${id}`);

export const getListItemsSelected = createCachedSelector(
  [getListItems, (state, listName) => listName],
  (list) => {
    return list.filter((item) => {
      return item.get('selected') === true;
    });
  }
)((state, listName) => `${listName}Selected`);

export const getListItemsSelectedCount = (state, listName) => {
  const ids = getListItemsSelected(state, listName);
  return ids === null ? 0 : ids.size;
}

export const isListItemsSelected = createCachedSelector(
  [getListItemsSelected, (state, listName) => listName],
  (selectedList) => {
    return selectedList.keySeq().toArray().length > 0;
  }
)((state, listName) => `${listName}IsSelected`);

export const getListItemSelected = createCachedSelector(
  [getListItemsSelected, (state, listName) => listName],
  (selectedList) => {
    const keys = selectedList.keySeq().toArray();
    if (keys.length) {
      return selectedList.get(keys[0]);
    }
  }
)((state, listName) => `${listName}SelectedItem`);

export const isTrailerAttachPopupDisplayed = state =>
  state.getIn(['trailerAttachPopup', 'isDisplayed'])

export const isTrailerAttachTimelinePopupDisplayed = state =>
  state.getIn(['trailerAttachTimelinePopup', 'isDisplayed'])

export const getVehicleUnattached = createSelector(
  [state => getDictionary(state, 'vehicleUnattached')],
  vehicles => vehicles
    .map(item => Immutable.Map({ value: item.get('uid'), caption: item.get('name') }))
    .sortBy(item => item.get('caption'))
);

export const getTrailerUnattached = createSelector(
  [state => getDictionary(state, 'trailerUnattached')],
  vehicles => vehicles
    .map(item => Immutable.Map({ value: item.get('id'), caption: item.get('name') }))
    .sortBy(item => item.get('caption'))
);

export const getWorkTypes = createSelector(
  [state => getDictionary(state, 'workTypes')],
  workTypes => workTypes
    .map(item => Immutable.Map({ value: item.get('id'), caption: item.get('name') }))
    .sortBy(item => item.get('name'))
);

export const getDrivers = createSelector(
  [state => getDictionary(state, 'drivers')],
  workTypes => workTypes
    .map(item => Immutable.Map({ value: item.get('id'), caption: item.get('name') }))
    .sortBy(item => item.get('name'))
);

const getVehiclesActivity = (state) => state.getIn(['vehicles', 'activity']);

export const getTreeNodeCheckboxColor = createCachedSelector(
  [
    getVehiclesActivity,
    (state, treeName, id, leaf) => treeName,
    (state, treeName, id, leaf) => id,
    (state, treeName, id, leaf) => leaf,
  ],
  (activity, treeName, id, leaf) => {
    if (treeName !== 'vehicle' || !leaf) {
      return null;
    }

    const timestampNow = new Date().getTime();
    const timestampLast = activity.getIn([id, 'timestamp']);
    const diffMs = timestampNow - timestampLast;

    if (!timestampLast) {
      return NULL_ACTIVITY_COLOR;
    }

    for (const item of VEHICLE_ACTIVITY_GRADUATIONS) {
      if (diffMs < item.maxTimeMs) {
        return item.color;
      }
    }

    return NULL_ACTIVITY_COLOR;
  }
)(
  (state, treeName, id, leaf) => `${treeName}:${id}:${leaf}`
);

export const getGroupedActivityIds = createSelector(
  getVehiclesActivity,
  state => getTreeNodeIds(state, 'vehicle', true),
  (activity, vehicleIds) => {
    const timestampNow = new Date().getTime();

    const actual = activity
      .filter(({ timestamp: timestampLast }, key) => {
        const diffMs = timestampNow - timestampLast;
        if (diffMs < VEHICLE_ACTIVITY_GRADUATIONS[0].maxTimeMs) {
          return true;
        }
        return false;
      })
      .keySeq();

    const outdated = activity
      .filter(({ timestamp: timestampLast }, key) => {
        if (actual.indexOf(key) >= 0) {
          return false;
        }

        const diffMs = timestampNow - timestampLast;
        if (diffMs < VEHICLE_ACTIVITY_GRADUATIONS[1].maxTimeMs) {
          return true;
        }
        return false;
      })
      .keySeq();

    const stale = activity
      .filter(({ timestamp: timestampLast }, key) => {
        if (actual.indexOf(key) >= 0 || outdated.indexOf(key) >= 0) {
          return false;
        }

        const diffMs = timestampNow - timestampLast;
        if (diffMs < VEHICLE_ACTIVITY_GRADUATIONS[2].maxTimeMs) {
          return true;
        }
        return false;
      })
      .keySeq();

    const unknown = vehicleIds.filter(
      vehicleId =>
        actual.indexOf(vehicleId) < 0 &&
        outdated.indexOf(vehicleId) < 0 &&
        stale.indexOf(vehicleId) < 0
    );

    return Immutable.fromJS({
      actual: actual,
      outdated: outdated,
      stale: stale,
      unknown: unknown,
      all: vehicleIds,
    });
  }
);

export const getGroupedActivityCounters = createSelector(
  getGroupedActivityIds,
  ActivityIds => ActivityIds.mapEntries(([key, value]) => ([key, value.size]))
);
