import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import styles from './style.module.scss';

const ListItem = ({
  selected,
  id,
  title,
  onClick,
  onSelect,
}) => (
  <div
    className={CN(styles.container, {
      [styles.selected]: selected,
    })}
    onClick={() => onSelect && onSelect(id)}
  >
    <div
      className={CN(styles.leftMargin, styles.caption)}
    >
      {title}
    </div>
  </div>
);

ListItem.propTypes = {
  selected: PT.bool,
  id: PT.string,
  title: PT.string,
  onClick: PT.func,
  onSelect: PT.func,
};

ListItem.defaultProps = {
  selected: false,
};

export default ListItem;
