import React from 'react';
import CN from 'classnames';
import styles from './style.module.scss';

const HR = ({ className, ...props }) => (
  <div
      className={CN([
        styles.container,
        className,
      ])}
      {...props}
  >
    <hr className={styles.hr} />
  </div>
);

export default HR;
