import Immutable from 'immutable';
import { AGRO_EVENTS_REPORT_DATA_COMPLETED } from 'constants/actionTypes.js';
import { REPORT_LOADING_TOGGLE } from 'constants/actionTypes.js';
import {REPORT_IMPORT_RUNNING_TOGGLE} from "constants/actionTypes";

const initialState = Immutable.fromJS({
  data: [],
  loading: false,
  runningImport: false,
  progress: null,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AGRO_EVENTS_REPORT_DATA_COMPLETED:
      const { data } = payload;
      if (!data) {
        return initialState;
      }
      return state.set('data', Immutable.fromJS(data));

    case REPORT_LOADING_TOGGLE:
      if (payload.report !== 'agroEventsReport') {
        return state;
      }
      return state.set('loading', Immutable.fromJS(payload.loading));
    case REPORT_IMPORT_RUNNING_TOGGLE:
      if (payload.report !== 'agroEventsReport') {
        return state;
      }
      return state.set('runningImport', payload.running).set('progress', payload.progress);

    default:
      return state;
  }
};
