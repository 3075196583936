import { delay} from 'redux-saga'
import {take, all, call, put} from "redux-saga/effects";
import { APP_INIT } from 'constants/actionTypes';
import {isNeedRefreshToken, loadRefreshToken, saveTokenRefreshRunning} from 'reducers/auth/helpers.js';
import {
  AUTH_TOKEN_REFRESH_SECONDS_LEFT, AUTH_TOKEN_REFRESH_CHECK_INTERVAL, OMNICOMM_BASE_URL
} from 'constants/index';
import  { makeAuthTokenRefreshRequest, fetchJwtData } from 'services/api/jwt.js';
import { updateAuthData } from 'actions/common';


function* refreshToken() {
  yield take(APP_INIT);
  while (true) {
    const isNeedTokenRefresh = yield call(isNeedRefreshToken, AUTH_TOKEN_REFRESH_SECONDS_LEFT);

    if (isNeedTokenRefresh) {
      let refreshResp;
      // get token refresh resp
      try {
        saveTokenRefreshRunning(1);
        refreshResp = yield call(makeAuthTokenRefreshRequest, loadRefreshToken());
      }
      catch (e) {
        console.log(e);
        refreshResp = null;
        // redirect to login
        window.location.href = OMNICOMM_BASE_URL;
      }
      finally {
        saveTokenRefreshRunning(0);
      }

      // check refresh resp
      if (refreshResp && refreshResp.data && refreshResp.data.jwt && refreshResp.data.refresh) {
        const jwt = refreshResp.data.jwt,
          refreshToken = refreshResp.data.refresh;

        // get encrypted jwt data
        let jwtDataResp;
        try {
          jwtDataResp = yield call(fetchJwtData, jwt);
        }
        catch (e) {
          console.log(e);
          jwtDataResp = null;
        }

        // update auth tokens
        if (jwtDataResp && jwtDataResp.data) {
          yield put(updateAuthData(jwt, jwtDataResp.data, refreshToken));
        }
      }
      else {
        console.log('Token refresh error');
      }
    }

    yield delay(AUTH_TOKEN_REFRESH_CHECK_INTERVAL * 1000);
  }
}

export default function*() {
  yield all([call(refreshToken)]);
}
