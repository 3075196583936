import React from 'react';
import styles from './style.module.scss';

const Home = () => (
  <div className={styles.wrapper}>
    <div className={styles.inner}>
      Выберите отчет в верхнем меню
    </div>
  </div>
);

export default Home;
