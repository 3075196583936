import Immutable from 'immutable';
import { all, call, takeEvery, select } from 'redux-saga/effects';
import { getAppConfigKey } from 'selectors/common';
import {getCheckedTreeNodes, getPeriodValue} from 'selectors/filters';
import { initAmplitude, logEvent } from 'services/amplitude';
import {
  APP_INIT,
  ENTITY_CREATE_SUCCESS,
  TRAILER_ATTACH_SUCCESS,
  REPORT_LOADING_TOGGLE,
  CHANGE_TABS,
  PLANNER_START,
  USER_LOGIN,
  USER_LOGOUT,
} from 'constants/actionTypes';
import {MS_IN_DAY} from 'constants/index';
import {buildDictMap} from 'helpers/dictionary';


const ACTIONS_EVENTS_CONFIG = Immutable.fromJS({
  ENTITY_CREATE_SUCCESS: [
    (action) => {
      let entityTypeName = action.meta.previousAction.payload.entity;
      // Field & plot are territory
      if (entityTypeName === 'territory/field' || entityTypeName === 'territory/plot') {
        entityTypeName = 'territory';
      }

      // log only for specified entity types
      if (!['trailer', 'territory'].includes(entityTypeName)) {
        return;
      }

      return entityTypeName + '_create';
    },
    (action) => action.meta.previousAction.payload.request.data,
  ],
  TRAILER_ATTACH_SUCCESS: [
    (action) =>  'trailer_attach',
    (action) => action.meta.previousAction.payload.request.data,
  ],
  REPORT_LOADING_TOGGLE: [
    (action) =>  {
      if (action.payload.loading !== false) {
        return false;
      }
      const reportName = action.payload.report.replace('Report', '');
      return `show_report_${reportName}`;
    },
    function* (action) {
      if (action.payload.loading !== false) {
        return false;
      }
      let vehicles = (yield select(getCheckedTreeNodes, 'vehicle', true)).toJS();
      vehicles = buildDictMap(vehicles, 'id');

      let commonParams = {
        'from': yield yield select(getPeriodValue, 'from'),
        'to': yield yield select(getPeriodValue, 'to'),
        'vehicles': Object.keys(vehicles),
        'vehicles_count': Object.keys(vehicles).length,
      };
      commonParams['days_count'] = Math.round((commonParams['to'] - commonParams['from']) / MS_IN_DAY);

      // custom params for reports
      let reportParams = {};
      return Object.assign({}, commonParams, reportParams);
    },
  ],
  CHANGE_TABS: [
    (action) =>  {
      let tabsNames;
      if (action.payload.tabsName === 'objectsLists') {
        tabsNames = [
          'vehicle',
          'trailer',
          'territory',
          'work_type',
        ];
      }
      if (tabsNames.length && action.payload.index <= tabsNames.length) {
        return 'tab_open_' + tabsNames[action.payload.index];
      }
      return false;
    },
    function* (action) {
      return {};
    }
  ],
  PLANNER_START: [
    (action) =>  {
      return 'planner_by_' + action.payload.mode;
    },
    function* (action) {
      return {};
    }
  ],
  USER_LOGIN: [
    (action) =>  {
      return 'user_login';
    },
    function* (action) {
      return {};
    }
  ],
  USER_LOGOUT: [
    (action) =>  {
      return 'user_logout';
    },
    function* (action) {
      return {};
    }
  ],
});

function* startInitAmplittude(action) {
  const amplittudeKey = yield select(getAppConfigKey, 'amplitude_key', true);
  yield yield call(initAmplitude, amplittudeKey);
}

function* logAmplitudeEvent(action) {
  const actionConfig = ACTIONS_EVENTS_CONFIG.get(action.type);
  if (typeof actionConfig === 'undefined') {
    return;
  }

  const eventName = yield call(actionConfig.get(0), action);
  const eventProperties = yield call(actionConfig.get(1), action);
  if (!eventName || eventProperties === false) {
    return;
  }

  logEvent(eventName, eventProperties);
}

export default function*() {
  yield all([
    takeEvery(APP_INIT, startInitAmplittude),
    takeEvery(
      [
        CHANGE_TABS,
        ENTITY_CREATE_SUCCESS,
        PLANNER_START,
        TRAILER_ATTACH_SUCCESS,
        REPORT_LOADING_TOGGLE,
        USER_LOGIN,
        USER_LOGOUT,
      ],
      logAmplitudeEvent
    ),
  ]);
}