import * as moment from 'moment';

export const displayIfEquals = (value, target, displayValue) => {
  return (value === target) ? displayValue : value;
};

export const sec2HumanFormat = (value) => {
  value = Math.round(value);
  let hours = Math.floor(value / 3600);
  let minutes = Math.floor((value - hours * 3600) / 60);
  let secs = value - hours * 3600 - minutes * 60;
  let res_text = '';
  if (hours) {
    res_text += `${hours} ч. `;
  }
  if (minutes) {
    res_text += `${minutes} мин. `;
  }
  if (secs) {
    res_text += `${secs} сек.`;
  }
  if (!res_text.length) {
    res_text = 0;
  }

  return res_text;
};

export const sec2ExcelFormat = (value, separator = ':') => {
  value = Math.round(value);
  let hours = Math.floor(value / 3600);
  let minutes = Math.floor((value - hours * 3600) / 60);
  let secs = value - hours * 3600 - minutes * 60;
  let numbers = [hours, minutes, secs].map((v) => {
    return ('0' + v).slice(-2);
  });
  return numbers.join(separator);
};

export const formatNumber = (value, precision = 2) => {
  if (value === Infinity || !value) {
    return 0;
  }
  value = value.toFixed(precision);
  if (precision > 0) {
    return parseFloat(value);
  }
  else {
    return value;
  }
};

export const formatDate = (value, format = 'DD.MM.YYYY HH:mm') => {
  return moment.unix(value).format(format);
};

export const getReportInputInvalidError = (maxValue) => {
  return 'Превышено ограничение по количеству ТС и длительности периода. ' +
    'Пожалуйста выберите меньший период или меньшее количество ТС. ' +
    'Максимальное <количество суток в периоде> * <Количество выбранных ТС> = ' + maxValue;
};

export const checkReportInputValid = (from, to, vehicles, maxValue) => {
  const daysCount = (to - from) / (1000 * 60 * 60 * 24);
  return daysCount * vehicles.length <= maxValue;
};
