import { rootReducer as createRootReducer } from 'reducers/index.js';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import axiosMiddlewareConfig from './configureAxiosMiddleware.js';
import { routerMiddleware } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas/index.js';
import { API_URL_BASE_PATH } from 'constants/index';

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const client = axios.create({
  baseURL: API_URL_BASE_PATH,
  responseType: 'json',
});

const configureStore = (history) => {
  const saga = createSagaMiddleware();

  const store = createStore(
    createRootReducer(history),
    composeEnhancers(
      applyMiddleware(
        thunk,
        axiosMiddleware(client, axiosMiddlewareConfig),
        routerMiddleware(history),
        saga
      )
    )
  );

  saga.run(rootSaga);

  return store;
};

export default configureStore;
