import {connect} from 'react-redux';
import DriversPlannerByVehiclePage from 'components/planner/DriversPlannerByVehiclePage';
import {getSelectedTree} from 'selectors/filters';
import toJS from 'helpers/tojs';
import {putVehicleDriver} from 'actions/planner';
import {getDriversData} from 'selectors/planner';
import {
  plannerAttachVehicleDriver,
  plannerDeleteVehicleDriver,
  plannerStart
} from 'actions/planner';
import {fetchVehicleDrivers} from "actions/reports";

const mapStateToProps = (state) => ({
  vehicleTree: getSelectedTree(state, 'vehicle'),
  data: getDriversData(state),
});

const mapDispatchToProps = dispatch => ({
  onInit: () => dispatch(plannerStart('drivers')),
  loadData: (vehicleIds, from, to) => dispatch(fetchVehicleDrivers(vehicleIds, from, to)),
  putTrailerVehicle: (id, data, fetchParams) => dispatch(putVehicleDriver(id, data, fetchParams)),
  attachVehicle: (id, params) => dispatch(plannerAttachVehicleDriver(id, params)),
  deleteVehicleTrailer: (id, fetchParams) => dispatch(plannerDeleteVehicleDriver(id, fetchParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DriversPlannerByVehiclePage));
