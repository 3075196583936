import Immutable from 'immutable';
import {
  UPDATE_AUTH_DATA,
  CLEAR_AUTH_DATA
} from 'constants/actionTypes.js';
import { loadAccessToken, loadRefreshToken, loadTokenExp, loadCurrentUser, loadAppConfig } from './helpers';
import { saveAccessToken, saveRefreshToken, saveTokenExp, saveCurrentUser, saveAppConfig } from './helpers';
import { clearAuthData } from './helpers';

const initialState = Immutable.fromJS({
  accessToken: loadAccessToken(),
  refreshToken: loadRefreshToken(),
  tokenExp: loadTokenExp(),
  currentUser: loadCurrentUser(),
  appConfig: loadAppConfig()
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_AUTH_DATA:
      const access = payload.jwt;
      const jwtData = payload.jwtData;
      const refresh = payload.refresh;
      const tokenExp = jwtData.exp;
      const currentUser = {
        id: jwtData['id'],
        uuid: jwtData['uuid'],
        login: jwtData['login'],
        name: jwtData['name'],
      };
      const appConfig = jwtData['config'];
      saveAccessToken(access);
      saveRefreshToken(refresh);
      saveTokenExp(tokenExp);
      saveCurrentUser(currentUser);
      saveAppConfig(appConfig);
      return state.set('accessToken', access)
        .set('refreshToken', refresh)
        .set('tokenExp', tokenExp)
        .set('currentUser', Immutable.fromJS(currentUser))
        .set('appConfig', Immutable.fromJS(appConfig));
    case CLEAR_AUTH_DATA:
      clearAuthData();
      return state.set('accessToken', null)
        .set('refreshToken', null)
        .set('tokenExp', null)
        .set('currentUser', null)
        .set('appConfig', null);
    default:
      return state;
  }
};
