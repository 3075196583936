import React from 'react';
import PT from 'prop-types';
import { If, Then, Else, When } from 'react-if';
import { Modal as ReactstrapModal } from 'reactstrap';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'components/ui/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './style.module.scss';

const Modal = ({
  isDisplayed,
  isConfirm,
  header,
  content,
  buttons,
  buttonCaption,
  confirmCaption,
  cancelCaption,
  onToggle,
  onOpened,
  size,
  hasFooter,
  className,
}) => (
  <ReactstrapModal
    backdrop="static"
    backdropClassName={styles.backdrop}
    contentClassName={styles.content}
    isOpen={isDisplayed}
    toggle={(e) => {e.stopPropagation(); onToggle(false); return false; }}
    onOpened={onOpened}
    centered={true}
    size={size}
    className={className}
  >
    <ModalHeader className={styles.header} toggle={(e) => {e.stopPropagation(); onToggle(false); return false; }}>
      {header}
    </ModalHeader>
    <ModalBody>{content}</ModalBody>
    <When condition={hasFooter}>
      <ModalFooter className={styles.footer}>
        <If condition={!!buttons}>
          <Then>{buttons}</Then>
          <Else>
            <React.Fragment>
              <Button onClick={() => onToggle(true)}>
                {isConfirm ? confirmCaption : buttonCaption}
              </Button>
              <When condition={!!isConfirm}>
                <Button onClick={() => onToggle(false)}>{cancelCaption}</Button>
              </When>
            </React.Fragment>
          </Else>
        </If>
      </ModalFooter>
    </When>
  </ReactstrapModal>
);

Modal.propTypes = {
  isDisplayed: PT.bool,
  isConfirm: PT.bool,
  hasFooter: PT.bool,
  header: PT.node,
  content: PT.node,
  buttons: PT.node,
  buttonCaption: PT.string,
  confirmCaption: PT.string,
  cancelCaption: PT.string,
  className: PT.string,
  onToggle: PT.func,
};

Modal.defaultProps = {
  header: 'Ошибка',
  content: 'Ошибка',
  buttonCaption: 'OK',
  confirmCaption: 'Да',
  cancelCaption: 'Нет',
  hasFooter: true,
};

export default Modal;
