import * as C from 'constants/index';

export const isServerError = code => code >= C.SERVER_ERRORS_FIRST_CODE;
export const isServerErrorForbidden = code => code === C.SERVER_ERRORS_FORBIDDEN;
export const isServerErrorNotAuth = code => code === C.SERVER_ERRORS_NOT_AUTH;
export const isServerErrorNotFound = code => code >= C.SERVER_ERRORS_NOT_FOUND;

export const isSupressError = url =>
  C.HIDE_ERROR_POPUP_FOR_URLS.some(
    supressedUrl => url.indexOf(supressedUrl) >= 0
  );