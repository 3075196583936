import React from 'react';
import PT from 'prop-types';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import FormFooter from 'components/ui/FormFooter';
import Label from 'components/ui/Label';
import Button from 'components/ui/Button';
import {InputAdapter, SelectAdapter, DatetimeAdapter} from 'components/ui/adapter';
import "react-datetime/css/react-datetime.css";
import {Form, Field} from 'react-final-form'
import {defaultSubmitForm, parseDecimal} from 'helpers/form';
import styles from './style.module.scss';

const offsetDirections = [
  {id: 'left', name: 'влево'},
  {id: 'right', name: 'вправо'}
];

const isNumber = (value) => Number(value) === value;

const getOffsetValue = ({offset, offset_direction} = {}) => isNumber(offset) ? (offset_direction === "right" ? -1 : 1) * offset : parseFloat(offset);

const getInitialFormData = (data = {}) => {
  const offset = parseDecimal(data.offset);
  return {...data, offset: isNumber(offset) ? Math.abs(offset) : undefined, offset_direction: offset < 0 ? "right" : "left"}
};

class TrailerForm extends React.PureComponent {

  render = () => {
    const props = this.props;
    return (
      <Form
        onSubmit={values => defaultSubmitForm({
          ...values,
          offset: getOffsetValue(values),
          offset_direction: undefined
        }, this.props)}
        initialValues={getInitialFormData(props.data)}
        render={({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit}>
            <div className={'row'}>
              <div className={'col-4'}>
                <Label>ID оборудования (RFID метки):</Label>
                <Field
                  name={'rfid'}
                  placeholder={'RFID'}
                  parse={(value) => !value ? null : value.replace(/[^0-9A-F]/g, '').substring(0, 40)}
                  component={InputAdapter}
                />
              </div>
              <div className={'col-4'}>
                <Label>{'Название:'}</Label>
                <Field
                  name={'name'}
                  placeholder={'Название'}
                  component={InputAdapter}
                />
              </div>

              <div className={'col-4'}>
                <Label>{'Гаражный номер:'}</Label>
                <Field
                  name={'garage_number'}
                  placeholder={'Гаражный номер'}
                  component={InputAdapter}
                />
              </div>

              <div className={'col-12'}>
                <Label>{'Максимально допустимая скорость работы (км/ч):'}</Label>
                <Field
                  name={'max_speed'}
                  placeholder={'Максимальная скорость (км/ч)'}
                  parse={(value) => parseDecimal(value) || null}
                  component={InputAdapter}
                />
              </div>

              <div className={'col-12'}>
                <Label>{'Ширина захвата обрабатываемой площади (м):'}</Label>
                <Field
                  name={'working_width'}
                  placeholder={'Ширина (м)'}
                  parse={(value) => parseDecimal(value) || null}
                  component={InputAdapter}
                />
              </div>

              <div className={'col-12'}>
                <Label>{'Смещение от центра (м)'}</Label>
                <div className={styles.offsetContainer}>
                  <Field
                    name={'offset'}
                    placeholder={'Смещение (м)'}
                    parse={(value) => parseDecimal(value) || null}
                    component={InputAdapter}
                    className={styles.offsetValue}
                    inline
                    errorClassName={styles.offsetError}
                  />
                  <Field
                    name={'offset_direction'}
                    type="select"
                    className="ml-1"
                    options={offsetDirections}
                    component={SelectAdapter}
                    inline
                  />
                </div>
              </div>

            </div>
            <FormFooter right>
              <Button type="submit">Сохранить</Button>
              <Button onClick={props.onClose}>Отмена</Button>
            </FormFooter>
          </form>
        )}
      />
    )
  }
}

TrailerForm.propTypes = {
  workTypes: PT.array,
  data: PT.object,
  onSubmit: PT.func,
  onClose: PT.func,
};

export default TrailerForm;
