// import { push } from 'connected-react-router';
import _ from "lodash";
import { delay } from 'redux-saga';
import { all, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { take } from 'redux-saga/effects';
import { matchCurrentPath } from 'helpers/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { FILTER_APPLY_DELAY_MS, FILTERS_ENTITIES_TYPES_WITH_LIST, REPORTS_AFTER_CHECK_RESULT_DELAY } from 'constants/index';
import { VEHILCE_ACTIVITY_REFRESH_INTERVAL_MS } from 'constants/index';
import { UPDATE_RFID_DATA_INTERVAL_MS } from 'constants/index';
import { APP_INIT, MODAL_HIDE } from 'constants/actionTypes';
import { GEOZONES_IMPORT_SUCCESS } from 'constants/actionTypes';
import { ENTITY_CREATE_SUCCESS } from 'constants/actionTypes';
import { ENTITY_UPDATE_SUCCESS } from 'constants/actionTypes';
import { TREE_FOLDER_DELETE_SUCCESS, PLANNER_DELETE_WORK_TYPE_TRAILER, PLANNER_DELETE_VEHICLE_DRIVER,VEHICLE_DRIVER_DELETE_SUCCESS, VEHICLE_DRIVER_PUT_SUCCESS, VEHICLE_DRIVER_ATTACH_SUCCESS,  } from 'constants/actionTypes';
import { TRAILER_ATTACH_SUCCESS, TRAILER_DETACH_SUCCESS, VEHICLE_TRAILER_DELETE_SUCCESS, PLANNER_ATTACH_WORK_TYPE_SHOW, TRAILER_WORK_TYPES_ATTACH_SUCCESS, TRAILER_WORK_TYPES_PUT_SUCCESS, WORK_TYPE_TRAILER_DELETE_SUCCESS } from 'constants/actionTypes';
import { TREE_NODE_CHECKBOX_CLICK } from 'constants/actionTypes';
import { PERIOD_FILTER_UPDATE } from 'constants/actionTypes';
import { RFID_UPDATE_DATA_SUCCESS } from 'constants/actionTypes';
import { TREE_NODE_DROP, TREE_FOLDER_NODE_DROP } from 'constants/actionTypes';
import {
  deleteTreeFolder, loadList, importDataOO, importDataOOSuccess, attachTrailer, detachTrailer, deleteVehicleTrailer, updateRfidData, deleteWorkTypeTrailer,
  showModal, deleteVehicleDriver
} from 'actions/common';
import { fetchTreeData, updateTreeNodeParent, updateTreeFolderNodeParent } from 'actions/filters';
import {
  fetchTerritoryList,
  fetchTrailerHistory,
  updateFactWorksReportData,
  fetchVehiclesTracks,
  fetchVehicleDrivers
} from 'actions/reports';
import { fetchVehicleActivity } from 'actions/reports';
import { fetchVehiclesDetailsThunk } from 'actions/reports';
import { receiveVehiclesDetails } from 'actions/reports';
import { removeVehiclesDetails } from 'actions/reports';
import { removeVehiclesTracks } from 'actions/reports';
import { receiveVehiclesTracks } from 'actions/reports';
import { revokeFactWorksReport, toggleReportId, toggleReportLoading } from 'actions/reports';
import { getSelectedTreeNodeId, getCheckedTreeNodeIds } from 'selectors/filters';
import {getNewVehiclesIds, getReportProp} from 'selectors/reports';
import { getOldVehiclesIds } from 'selectors/reports';
import { getPeriodValue, getCheckedTreeNodes } from 'selectors/filters';
import { FactWorksReport } from 'services/reports/FactWorkReport';
import {
  fetchVehicleEvents, fetchVehicleTrailers, updateAgroEventsReportData, fetchVehicleFilter, createReportFactWorkRequest,
  checkReportFactWorkResult
} from "actions/reports";
import {AgroEventsReport} from "../../services/reports/AgroEventsReport";
import {buildDictMap} from 'helpers/dictionary';
import {onlyUnique} from 'helpers/utils';
import {TRAILER_VEHICLES_PUT_SUCCESS} from 'constants/actionTypes';
import {fetchTrailerVehicles, putTrailerVehicle, fetchTrailerWorkTypes, attachDriverToVehicle, putVehicleDriver} from 'actions/planner';
import {TRAILER_ATTACH_TIMELINE_POPUP_CLOSE} from 'constants/actionTypes';
import {PLANNER_ATTACH_VEHICLE_SHOW, PLANNER_ATTACH_TRAILER_SHOW, PLANNER_DETACH_TRAILER, PLANNER_DELETE_VEHICLE_TRAILER, PLANNER_ATTACH_DRIVER_SHOW} from 'constants/actionTypes';
import {REPORT_FACT_WORK_REQUEST_SUCCESS, REPORT_FACT_WORK_RESULT_CHECK_SUCCESS} from  'constants/actionTypes';
import {putTrailerWorkType, attachWorkTypeToTrailer} from "actions/planner";
import {fetchGeozonesImportStatus} from "actions/filters";
import {IMPORT_DATA_OO_CHECK_INTERVAL_MS} from "constants/index";
import {isImportDataOORunning} from "selectors/filters";
import {completedGeozonesImportStatus} from "actions/filters";
import {COMPLETED_GEOZONES_IMPORT_STATUS} from "constants/actionTypes";
import {FETCH_GEOZONES_IMPORT_STATUS_SUCCESS} from "constants/actionTypes";
import {toggleReportGeozonesImportRunning} from "actions/reports";
import {TERRITORY_LIST_FETCH_SUCCESS} from "constants/actionTypes";
import {importDataOOProgress} from "selectors/filters";
import {checkReportInputValid, getReportInputInvalidError} from 'helpers/reports';
import {getAppConfigKey} from "selectors/common";
import {SEASON_CREATE_FAIL} from "constants/actionTypes";
import {SEASON_UPDATE_FAIL} from "constants/actionTypes";
import {SEASON_DELETE_FAIL} from "constants/actionTypes";

function* fetchTreesAndDictionaries(action) {
  yield put(fetchTreeData('vehicle'));
  yield put(fetchTreeData('trailer'));
  yield put(loadList('territory', 'geozone_type'));
  yield put(loadList('trailer', 'work_type'));
  if (action.payload.forceImportDataOO) {
    yield put(importDataOO());
  } else {
    yield put(importDataOOSuccess());
  }
}

function* refreshUserRfidData(action) {
  while (true) {
    yield put(updateRfidData());
    yield delay(UPDATE_RFID_DATA_INTERVAL_MS);
  }
}

function* refreshVehicleStateList(action) {
  while (true) {
    yield put(fetchVehicleActivity());
    yield delay(VEHILCE_ACTIVITY_REFRESH_INTERVAL_MS);
  }
}

function* fetchTerritoryData(action) {
  yield put(fetchTreeData('territory'));
  yield put(fetchTerritoryList());
}

function* fetchImportStatus(action) {
  while (true) {
    const isRunning = (yield select(isImportDataOORunning));
    if (!isRunning) {
      break;
    }
    yield put(fetchGeozonesImportStatus());
    yield delay(IMPORT_DATA_OO_CHECK_INTERVAL_MS);
  }
}

function* completeGeozonesImportStatus(action) {
    const isRunning = (yield select(isImportDataOORunning));
    if (!isRunning) {
        yield put(completedGeozonesImportStatus());
    }
}

function* onModalHide({ payload: { type, isConfirmed } }) {
  let treeName = null;

  if (type === 'trailer_detach' && isConfirmed === true) {
    const id = yield select(getSelectedTreeNodeId, 'trailer', true);
    if (id) {
      yield put(detachTrailer(id));
    }
    return;
  }

  if (type === 'trailer_force_attach' && isConfirmed === true) {
    yield put(loadList('vehicle', 'vehicle'));
    return;
  }

  if (type === 'trailer_folder_delete' && isConfirmed === true) {
    treeName = 'trailer';
  }
  if (type === 'territory_folder_delete' && isConfirmed === true) {
    treeName = 'territory';
  }
  if (!treeName) {
    return;
  }

  const id = yield select(getSelectedTreeNodeId, treeName, false);
  if (!id) {
    return;
  }

  yield put(deleteTreeFolder(treeName, id));
}

function* updateTree(action) {
  const payload = action.meta.previousAction.payload;
  const treeName = payload.section.split('/')[0];
  if (!FILTERS_ENTITIES_TYPES_WITH_LIST.includes(payload.entity)) {
    yield put(fetchTreeData(treeName));
  }
}

function* updateList(action) {
  const payload = action.meta.previousAction.payload;
  if (FILTERS_ENTITIES_TYPES_WITH_LIST.includes(payload.entity)) {
    yield put(loadList(payload.section, payload.entity));
  }
}

function* updateTrailerTree() {
  yield put(fetchTreeData('trailer'));
}

function* updateLocationReportData(action) {
  const isCheckboxClicked = action.type === TREE_NODE_CHECKBOX_CLICK;
  const isReportSelected = action.type === LOCATION_CHANGE;
  const treeName = action.payload.treeName;

  if (!isReportSelected) {
    yield delay(FILTER_APPLY_DELAY_MS);
  }

  if (!matchCurrentPath('/report/vehicle-location')) {
    return;
  }

  // territories fetched on app start and no used in this saga
  if (isCheckboxClicked && treeName !== 'vehicle') {
    return; // vehicles list not changed
  }

  const isRunning = (yield select(isImportDataOORunning));
  const progress = (yield select(importDataOOProgress));
  if (isRunning) {
    yield put(toggleReportGeozonesImportRunning('location', true, progress));
    return;
  }
  yield put(toggleReportLoading('location', true));
  yield put(toggleReportGeozonesImportRunning('location', false));

  let idsNew;
  let idsOld;
  if (isReportSelected) {
    idsNew = (yield select(getCheckedTreeNodeIds, 'vehicle', true)).toJS();
    idsNew = idsNew.filter(onlyUnique);
    idsOld = idsNew;
  } else {
    idsNew = (yield select(getNewVehiclesIds, 'location', 'vehicles')).toJS();
    idsOld = (yield select(getOldVehiclesIds, 'location', 'vehicles')).toJS();
  }
  let locations = [];
  try {
    locations = yield yield put(fetchVehiclesDetailsThunk(idsNew));
  } catch (e) {
    const ids = (yield select(getCheckedTreeNodeIds, 'vehicle', true)).toJS();
    yield put(removeVehiclesDetails(ids));
  }
  yield put(removeVehiclesDetails(idsOld));
  yield put(receiveVehiclesDetails(locations));
  yield put(toggleReportLoading('location', false));
}

function* updateTrackReportData(action) {
  const isCheckboxClicked = action.type === TREE_NODE_CHECKBOX_CLICK;
  const isPeriodUpdated = action.type === PERIOD_FILTER_UPDATE;
  const isReportSelected = action.type === LOCATION_CHANGE;
  const treeName = action.payload.treeName;

  if (!isReportSelected) {
    yield delay(FILTER_APPLY_DELAY_MS);
  }

  if (!matchCurrentPath('/report/vehicle-track')) {
    return; // report not visible
  }

  if (isCheckboxClicked && treeName !== 'vehicle') {
    return; // vehicles list not changed
  }

  const isRunning = (yield select(isImportDataOORunning));
  const progress = (yield select(importDataOOProgress));
  if (isRunning) {
    yield put(toggleReportGeozonesImportRunning('track', true, progress));
    return;
  }
  yield put(toggleReportGeozonesImportRunning('track', false));

  let idsNew;
  let idsOld;
  if (isReportSelected || isPeriodUpdated) {
    idsNew = (yield select(getCheckedTreeNodeIds, 'vehicle', true)).toJS();
    idsNew = idsNew.filter(onlyUnique);
    idsOld = idsNew;
  } else {
    idsNew = (yield select(getNewVehiclesIds, 'track', 'tracks')).toJS();
    idsOld = (yield select(getOldVehiclesIds, 'track', 'tracks')).toJS();
  }

  const from = (yield select(getPeriodValue, 'from'));
  const to = (yield select(getPeriodValue, 'to'));
  let locations = [];
  let tracks = [];

  const maxTimeVehicle = yield select(getAppConfigKey, 'reports_time_vehicle_restrict', true);
  if (!checkReportInputValid(from, to, idsNew, maxTimeVehicle)) {
    yield put(showModal('server_error', getReportInputInvalidError(maxTimeVehicle), 'Ошибка'));
    return;
  }

  yield put(toggleReportLoading('track', true));

  try {
    locations = yield yield put(fetchVehiclesDetailsThunk(idsNew));
    tracks = yield yield put(fetchVehiclesTracks(idsNew, from, to));
    yield put(removeVehiclesDetails(idsOld));
    yield put(receiveVehiclesDetails(locations));
    yield put(removeVehiclesTracks(idsOld));
    yield put(receiveVehiclesTracks(tracks));
  } catch (e) {
    const ids = (yield select(getCheckedTreeNodeIds, 'vehicle', true)).toJS();
    yield put(removeVehiclesDetails(ids));
    yield put(removeVehiclesTracks(ids));
  }
  yield put(toggleReportLoading('track', false));
}

function* _updateFactWorkReportData(action) {
  const isCheckboxClicked = action.type === TREE_NODE_CHECKBOX_CLICK;
  const isReportSelected = action.type === LOCATION_CHANGE;
  const treeName = action.payload.treeName;

  if (!isReportSelected) {
    yield delay(FILTER_APPLY_DELAY_MS);
  }

  if (!matchCurrentPath('/report/fact-works')) {
    return; // report not visible
  }

  if (isCheckboxClicked && !['vehicle', 'territory'].includes(treeName)) {
    return; // vehicles or territory list not changed
  }

  const isRunning = (yield select(isImportDataOORunning));
  const progress = (yield select(importDataOOProgress));
  if (isRunning) {
    yield put(toggleReportGeozonesImportRunning('factWorksReport', true, progress));
    return;
  }
  yield put(toggleReportLoading('factWorksReport', true));
  yield put(toggleReportGeozonesImportRunning('factWorksReport', false));

  let selectedVehicles = (yield select(getCheckedTreeNodes, 'vehicle', true)).toJS();
  let selectedGeozones = (yield select(getCheckedTreeNodes, 'territory', true)).toJS();
  let from = (yield select(getPeriodValue, 'from'));
  let to = (yield select(getPeriodValue, 'to'));

  let geozones = [];
  let vehicles = [];

  // for debug
  /*
  vehicles = [
    {id: 'ac1f763c-eff1-39df-ba1f-bcfe43739ffc', 'name': '1144уа 26'}
  ];
  from = 1548190800 * 1000;
  to = 1548277199 * 1000;
  */


  // Validation
  if ((!selectedVehicles.length && !selectedGeozones.length) || !from || !to) {
    yield put(updateFactWorksReportData(null));
    return;
  }
  selectedVehicles = buildDictMap(selectedVehicles, 'id');
  selectedGeozones = buildDictMap(selectedGeozones, 'id');

  yield put(
    createReportFactWorkRequest(from, to, Object.keys(selectedVehicles), Object.keys(selectedGeozones))
  );
}

function* processFactWorkReportRequestResp(action) {
  const response = action.payload.data;

  let errorMsg = null;
  if (!response.is_success) {
    errorMsg = response.messages && response.messages.length ? response.messages[0] : 'Ошибка подготовки отчёта';
  }
  else if (!response.data || !response.data.id) {
    errorMsg= 'Не получен ID запроса на отчёт';
  }

  if (errorMsg) {
    yield put(showModal('server_error', errorMsg, 'Ошибка'));
    yield put(toggleReportLoading('factWorksReport', false));
    return;
  }
  const reportTaskId = (yield select(getReportProp, 'factWorksReport', 'reportTaskId'));
  console.log(reportTaskId);
  if (reportTaskId) {
    yield put(revokeFactWorksReport(reportTaskId));
  }
  yield put(toggleReportId('factWorksReport', response.data.id));

  while (true) {
    yield delay(REPORTS_AFTER_CHECK_RESULT_DELAY);
    yield put(checkReportFactWorkResult(response.data.id));
    const action = yield take(REPORT_FACT_WORK_RESULT_CHECK_SUCCESS);
    const isComplete = yield processFactWorkReportResult(action);
    if (isComplete) {
      break;
    }
  }
}

function* processFactWorkReportResult(action) {
  const data = action.payload.data;
  // Report prepare not completed
  if (data.is_complete === false) {
    return false;
  }
  // Report generate error
  else if (!data || data.is_success === false) {
    yield put(showModal('server_error', 'Ошибка подготовки отчёта', 'Ошибка'));
    yield put(updateFactWorksReportData(null));
    yield put(toggleReportLoading('factWorksReport', false));
  }
  // Report data generated
  else {
    //empty data
    if (!data.data || !data.data.records || !data.data.records.length) {
      yield put(updateFactWorksReportData(null));
    }
    else {
      // update report data
      const reportData = data.data;
      yield put(updateFactWorksReportData('data', reportData.records));

      // transform map data & update map
      if (reportData.map) {
        yield updateFactWorksReportMapData(reportData.map);
      }
    }

    yield put(toggleReportLoading('factWorksReport', false));
  }
  return true;
}

function* updateFactWorksReportMapData(data) {
  let result = {
    geozones: [],
    tracks: [],
    speedTracks: [],
  };

  const trackDisplayParams = {
    original: {
      style: {color: '#8f8b8f', fillOpacity: 0.5, fillColor: '#b4b1b4', opacity: 1}
    },
    speed_exceed: {
      style: {color: '#ba030b', fillOpacity: 1, fillColor: '#fc2512', opacity: 1}
    },
    speed_valid: {
      style: {color: '#0eac0b', fillOpacity: 1, fillColor: '#0ecc0b', opacity: 1}
    }
  };

  // Geozones data
  result.geozones = data.geozones;

  // Prepare original track data
  for (let [vehicleId, vehicleData] of Object.entries(data['tracks'])) {
    for (const [trackType, trackData] of Object.entries(vehicleData)) {
      const curTrackParams = trackDisplayParams[trackType];
      const positions = trackData.map(item => ({lat: item[1], lng: item[0]}));
      result.tracks.push({positions, color: curTrackParams.style.color});
    }
  }

  // Prepare vehicle speed tracks
  const polygonMapper = item => item.map(item => ({ lat: item[1], lng: item[0] }));
  const multiPolygonMapper = item => item.map(item => item.map(item => ({ lat: item[1], lng: item[0] })));
  for (const vehicleData of Object.values(data['speed_tracks'])) {
    for (const [trackType, tracks] of Object.entries(vehicleData)) {
      if (!tracks) {
        continue;
      }
      for (let i=0; i<tracks.length; i++) {
        let trackData = tracks[i];
        const curTrackParams = trackDisplayParams['speed_' + trackType];
        const mapper = trackData.type === 'MultiPolygon' ? multiPolygonMapper : polygonMapper;
        result.speedTracks.push({
          positions: trackData.coordinates.map(mapper),
          ...curTrackParams.style
        });
      }
    }
  }

  yield put(updateFactWorksReportData('map', result));
}

function* updateSeasonsData(action) {

  if (!matchCurrentPath('/season')) {
    return;
  }
  yield put(loadList('territory', 'season'));

}

function* _updateAgroEventReportData(action) {
  const isCheckboxClicked = action.type === TREE_NODE_CHECKBOX_CLICK;
  const isReportSelected = action.type === LOCATION_CHANGE;
  const treeName = action.payload.treeName;

  if (!isReportSelected) {
    yield delay(FILTER_APPLY_DELAY_MS);
  }

  if (!matchCurrentPath('/report/agro-events')) {
    return; // report not visible
  }

  if (isCheckboxClicked && treeName !== 'vehicle') {
    return; // vehicles list not changed
  }

  const isRunning = (yield select(isImportDataOORunning));
  const progress = (yield select(importDataOOProgress));
  if (isRunning) {
    yield put(toggleReportGeozonesImportRunning('agroEventsReport', true, progress));
    return;
  }

  let vehicleList = (yield select(getCheckedTreeNodes, 'vehicle', true)).toJS();
  let from = (yield select(getPeriodValue, 'from'));
  let to = (yield select(getPeriodValue, 'to'));

  yield put(toggleReportGeozonesImportRunning('agroEventsReport', false));

  const maxTimeVehicle = yield select(getAppConfigKey, 'reports_time_vehicle_restrict', true);
  if (!checkReportInputValid(from, to, vehicleList, maxTimeVehicle)) {
    yield put(showModal('server_error', getReportInputInvalidError(maxTimeVehicle), 'Ошибка'));
    return;
  }

  yield put(toggleReportLoading('agroEventsReport', true));

  // Parameterf for debug
  // vehicleList = [
  //   {id: 'ac1f763c-eff1-39df-ba1f-bcfe43739ffc', 'name': '1144уа 26'}
  // ];
  // from = 1548219417 * 1000;
  // to = 1548231915 * 1000;


  // vehicleList = [
  //   {id: 'b1ded851-4567-377f-970c-620cd53fc551', 'name': '9611'},
  //   {id: 'cc344e83-4cfc-3e40-abac-a89f4c720e4b', 'name': '4906'},
  //
  // ];
  // from = 1550696400000;//1550782800000;
  // // from = 1550782800000;
  //
  // to = 1550869199999;
  // // to = 1550782800000;

  let vehicles = buildDictMap(vehicleList, 'id');
  let vehicleUds = Object.keys(vehicles);

  // Load trailer history
  const trailersResp = yield yield put(fetchVehicleTrailers(vehicleUds, from, to));
  const trailerHistory = (trailersResp.payload || {}).data || [];

  // Load events
  let events = [];
  for (let i = 0; i < vehicleUds.length; i++) {
    const eventsResp = yield yield put(fetchVehicleEvents(vehicleUds, from, to));
    let data = (eventsResp.payload || {}).data || [];
    if (!Array.isArray(eventsResp.payload.data)) {
      continue;
    }
    data.forEach(item => { item.vehicle_uid = vehicleUds[i]});
    events.push.apply(events, data);
  }

  // Load geozones
  let geozones = {};
  const territoryListParams = new URLSearchParams();
  yield(put(loadList('territory', 'all', territoryListParams)));
  const geozonesResp = yield take('TERRITORY_ALL_GET_LIST_SUCCESS'),
    geozonesList = geozonesResp.payload.data || [];
  geozonesList.forEach((item) => {
    geozones[item['id']] = item;
  });

  // Load vehicles tracks
  let trackData;
  try {
    trackData = yield yield put(fetchVehiclesTracks(vehicleUds, from, to));
  } catch (e) {
    yield put(updateAgroEventsReportData(null));
    return;
  }

  const reportDataMaker = new AgroEventsReport(
    {from: from, to: to, vehicles: vehicles},
    trailerHistory, trackData, geozones, events
  );
  let data = reportDataMaker.getData();
  yield put(updateAgroEventsReportData(data));
  yield put(toggleReportLoading('agroEventsReport', false));
}

function* attachVehicleToTrailer({payload}) {
  const { formType, workTypeId, driverId, trailerId, vehicleId, fromTime, toTime, isUpdateData, itemId, fetchParams } = payload;
  if (formType === "driver") {
    if (itemId) {
      yield put(putVehicleDriver(itemId, {
          driver_id: driverId,
          vehicle_uid: vehicleId,
          start_at: fromTime,
          finish_at: toTime,
      }, fetchParams));
      return;
    }
    yield put(attachDriverToVehicle(driverId, vehicleId, fromTime, toTime, isUpdateData ? fetchParams : {}));
    return;
  }
  if (!trailerId) {
    return;
  }
  if (formType === "work_type") {
    if (itemId) {
      yield put(putTrailerWorkType(itemId, {
          work_type_id: workTypeId,
          trailer_id: trailerId,
          attached_at: fromTime,
          unattached_at: toTime,
      }, fetchParams));
      return;
    }
    yield put(attachWorkTypeToTrailer(trailerId, workTypeId, fromTime, toTime, isUpdateData ? fetchParams : {}));
    return;
  }
  if (itemId) {
    yield put(putTrailerVehicle(itemId, {
        vehicle_uid: vehicleId,
        trailer_id: trailerId,
        attached_at: fromTime,
        unattached_at: toTime,
    }, fetchParams));
    return;
  }
  yield put(attachTrailer(trailerId, vehicleId, fromTime, toTime, isUpdateData ? fetchParams : {}));
}

function* updatePlannerData(action) {
  const {type, vehicleIds, trailerIds, from, to} = action.meta.previousAction.payload.fetchParams || {};
    if (type==="trailer") {
      yield put(fetchTrailerVehicles(trailerIds, from, to));
    } else if (type==="vehicle") {
      yield put(fetchVehicleTrailers(vehicleIds, from, to));
    } else if (type==="work_type") {
      yield put(fetchTrailerWorkTypes(trailerIds, from, to));
    } else if (type==="driver") {
      yield put(fetchVehicleDrivers(vehicleIds, from, to));
    }

}

function* processRfidUpdateResult(action) {
  if (action.payload.status) {
    const data = action.payload.data;
    if (data['result'] === 'success' && parseInt(data['data']['created_count']) > 0) {
      yield put(fetchTreeData('trailer'));
    }
  }
}


function* plannerAttachVehicle(action) {
  yield put(loadList('vehicle', 'vehicle'));
}

function* plannerAttachWorkType() {
  yield put(loadList('trailer', 'work_type'));
}

function* plannerAttachTrailer(action) {
  yield put(loadList('trailer', 'trailer'));
}

function* plannerAttachDriver() {
  yield put(loadList('driver', 'driver'));
}

function* plannerDetachTrailer(action) {
  const {id, fetchParams} = action.payload;
  yield put(detachTrailer(id, fetchParams));
}

function *plannerDeleteVehicleTrailer(action) {
  const {id, fetchParams} = action.payload;
  yield put(deleteVehicleTrailer(id, fetchParams));
}

function *plannerDeleteWorkTypeTrailer(action) {
  const {id, fetchParams} = action.payload;
  yield put(deleteWorkTypeTrailer(id, fetchParams));
}

function *plannerDeleteVehicleDriver(action) {
  const {id, fetchParams} = action.payload;
  yield put(deleteVehicleDriver(id, fetchParams));
}

function *changeTreeParent(action) {
  const {listName, entityId, oldFolderId, newFolderId} = action.payload;
  const response = yield yield put(updateTreeNodeParent(listName, entityId, oldFolderId, newFolderId));
  yield put(fetchTreeData(listName));
}

function *changeTreeFolderParent(action) {
  const {listName, entityId, newFolderId} = action.payload;
  const response = yield yield put(updateTreeFolderNodeParent(listName, entityId, newFolderId));
  yield put(fetchTreeData(listName));
}

function *processSeasonFail(action) {
  const error = _.get(action, "error.response.data.message");
  yield put(showModal('server_error', error || 'Произошла ошибка', 'Ошибка'));
}

export default function*() {
  yield all([
    takeEvery(APP_INIT, fetchTreesAndDictionaries),
    takeLatest(APP_INIT, refreshVehicleStateList),
    takeLatest(APP_INIT, refreshUserRfidData),
    takeEvery([RFID_UPDATE_DATA_SUCCESS], processRfidUpdateResult),
    takeEvery(GEOZONES_IMPORT_SUCCESS, fetchTerritoryData),
    takeLatest(GEOZONES_IMPORT_SUCCESS, fetchImportStatus),
    takeLatest(FETCH_GEOZONES_IMPORT_STATUS_SUCCESS, completeGeozonesImportStatus),
    takeEvery(COMPLETED_GEOZONES_IMPORT_STATUS, fetchTerritoryData),
    takeEvery(MODAL_HIDE, onModalHide),
    takeEvery(
      [
        ENTITY_CREATE_SUCCESS,
        ENTITY_UPDATE_SUCCESS,
        TREE_FOLDER_DELETE_SUCCESS,
      ],
      updateTree
    ),
    takeEvery(
      [
        ENTITY_CREATE_SUCCESS,
        ENTITY_UPDATE_SUCCESS,
      ],
      updateList
    ),
    takeEvery([TRAILER_ATTACH_SUCCESS, TRAILER_DETACH_SUCCESS, VEHICLE_TRAILER_DELETE_SUCCESS, WORK_TYPE_TRAILER_DELETE_SUCCESS, VEHICLE_DRIVER_DELETE_SUCCESS], updateTrailerTree),
    takeLatest([TRAILER_DETACH_SUCCESS, TRAILER_ATTACH_SUCCESS, TRAILER_VEHICLES_PUT_SUCCESS, VEHICLE_TRAILER_DELETE_SUCCESS, TRAILER_WORK_TYPES_PUT_SUCCESS, TRAILER_WORK_TYPES_ATTACH_SUCCESS, WORK_TYPE_TRAILER_DELETE_SUCCESS, VEHICLE_DRIVER_PUT_SUCCESS, VEHICLE_DRIVER_ATTACH_SUCCESS, VEHICLE_DRIVER_DELETE_SUCCESS], updatePlannerData),
    takeLatest([TREE_NODE_CHECKBOX_CLICK, LOCATION_CHANGE, TERRITORY_LIST_FETCH_SUCCESS, FETCH_GEOZONES_IMPORT_STATUS_SUCCESS], updateLocationReportData),
    takeLatest([TREE_NODE_CHECKBOX_CLICK, PERIOD_FILTER_UPDATE, LOCATION_CHANGE, FETCH_GEOZONES_IMPORT_STATUS_SUCCESS], updateTrackReportData),
    takeLatest([TREE_NODE_CHECKBOX_CLICK, PERIOD_FILTER_UPDATE, LOCATION_CHANGE, FETCH_GEOZONES_IMPORT_STATUS_SUCCESS], _updateFactWorkReportData),
    takeLatest([TREE_NODE_CHECKBOX_CLICK, PERIOD_FILTER_UPDATE, LOCATION_CHANGE, FETCH_GEOZONES_IMPORT_STATUS_SUCCESS], _updateAgroEventReportData),
    takeEvery(TRAILER_ATTACH_TIMELINE_POPUP_CLOSE, attachVehicleToTrailer),
    takeEvery(PLANNER_ATTACH_VEHICLE_SHOW, plannerAttachVehicle),
    takeEvery(PLANNER_ATTACH_TRAILER_SHOW, plannerAttachTrailer),
    takeEvery(PLANNER_ATTACH_WORK_TYPE_SHOW, plannerAttachWorkType),
    takeEvery(PLANNER_ATTACH_DRIVER_SHOW, plannerAttachDriver),
    takeEvery(PLANNER_DETACH_TRAILER, plannerDetachTrailer),
    takeEvery(PLANNER_DELETE_VEHICLE_TRAILER, plannerDeleteVehicleTrailer),
    takeEvery(PLANNER_DELETE_WORK_TYPE_TRAILER, plannerDeleteWorkTypeTrailer),
    takeEvery(PLANNER_DELETE_VEHICLE_DRIVER, plannerDeleteVehicleDriver),
    takeLatest(REPORT_FACT_WORK_REQUEST_SUCCESS, processFactWorkReportRequestResp),
    takeLatest(TREE_NODE_DROP, changeTreeParent),
    takeLatest(TREE_FOLDER_NODE_DROP, changeTreeFolderParent),
    takeLatest(LOCATION_CHANGE, updateSeasonsData),
    takeLatest([SEASON_CREATE_FAIL, SEASON_UPDATE_FAIL, SEASON_DELETE_FAIL], processSeasonFail),
  ]);
}
