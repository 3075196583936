import { connect } from 'react-redux';
import { decrement, increment, updateCounter } from 'actions/example';
import Counter from 'components/example/Counter';
import { getCounterValue } from 'selectors/example';

const mapStateToProps = (state, props) => ({
  value: getCounterValue(state, props.id),
});

const mapDispatchToProps = dispatch => ({
  onDecrement: id => dispatch(decrement(id)),
  onIncrement: id => dispatch(increment(id)),
  onChange: (id, value) => dispatch(updateCounter(id, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Counter);
