import React from 'react';
import CN from 'classnames';
import Button from 'components/ui/Button';
import IconButton from 'components/ui/IconButton';
import Popup from "components/ui/Popup";
import Checkbox from "components/ui/Checkbox";
import {container, innerContainer, group, smallButton, popupContent} from './Pagination.style.module.scss';

const getPageNumber = page => page.toString().padStart(2, '0');

const Pagination = props => {
  const {
    page,
    pages,
    pageSizeOptions,
    pageSize,
    canPrevious,
    canNext,
    onPageChange,
    onPageSizeChange,
    className,
    onExport,
    hideExport,
    extButtons,
    columns,
    defaultPopupOpen,
    onToggleColumn,
    onTogglePopup
  } = props;

  const handleToggleColumn = ({accessor, visible}) => () => {
    onToggleColumn && onToggleColumn(accessor, !visible);
  }
  const handleToggle = (opened) => {
    onTogglePopup && onTogglePopup(opened);
  };

  return (
    <div className={CN(className, '-pagination')}>
      {extButtons && extButtons}
      <div className={container}>
        <div className={innerContainer}>
          <Popup
            container={
              <Button className={smallButton} color="warning" size="sm">
                ...
              </Button>
            }
            popup={
              <div className={popupContent}>
                {columns.map(column => <div key={column.accessor}>
                  <Checkbox checked={column.visible} onClick={handleToggleColumn(column)}/>
                  <div onClick={handleToggleColumn(column)}>{column.Header}</div>
                </div>)}
              </div>
            }
            allowHoverOnPopup
            fastShow
            defaultOpen={defaultPopupOpen}
            onToggle={handleToggle}
          />
          
          {!hideExport && <Button
            className={smallButton}
            color="warning"
            size="sm"
            onClick={onExport}
          >
            CSV
          </Button>}
          <div className={group}>
          <span>
            {getPageNumber(page + 1)} / {getPageNumber(pages || 1)}
          </span>
            <IconButton
              icon="chevron_double_left"
              disabled={!canPrevious}
              size={18}
              onClick={() => onPageChange(0)}
            />
            <IconButton
              icon="chevron_left"
              disabled={!canPrevious}
              size={18}
              onClick={() => onPageChange(page - 1)}
            />
            <IconButton
              icon="chevron_right"
              disabled={!canNext}
              size={18}
              onClick={() => onPageChange(page + 1)}
            />
            <IconButton
              icon="chevron_double_right"
              disabled={!canNext}
              size={18}
              onClick={() => onPageChange(pages - 1)}
            />
            <select
              onChange={e => onPageSizeChange(Number(e.target.value))}
              value={pageSize}
            >
              {pageSizeOptions.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
