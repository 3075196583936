import React from 'react';
import { /*BrowserRouter as Router,*/ Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';
import MainMenu from 'containers/MainMenu';
import Calendar from 'containers/filters/Calendar';
import Periods from 'containers/filters/Periods';
import CalendarModal from 'containers/filters/CalendarModal';
import TrailerPopup from 'containers/features/TrailerPopup';
import TrailerTreeFolderPopup from 'containers/filters/trailer/TreeFolderPopup';
import TerritoryPopup from 'containers/features/TerritoryPopup';
import TerritoryTreeFolderPopup from 'containers/filters/territory/TreeFolderPopup';
import Vehicle from 'containers/filters/vehicle/Panel';
import Trailer from 'containers/filters/trailer/Panel';
import TrailerAttachFromTimelinePopup from 'containers/features/planner/TrailerAttachFromTimelinePopup';
import Territory from 'containers/filters/territory/Panel';
import Menu from 'components/ui/Menu';
import Home from 'components/home/Home';
import Sidebar from 'components/ui/Sidebar';
import Example from 'components/example/Page';
import ReportVehicleLocation from 'components/report-vehicle-location/Page';
import ReportVehicleTrack from 'components/report-vehicle-track/Page';
import ReportFactWorks from 'containers/features/reports/FactWorksReport';
import ReportAgroEvents from 'containers/features/reports/AgroEventsReport';
import WorkTypePopup from 'containers/features/WorkTypePopup';
import WorkType from 'containers/filters/WorkType/Panel';
import PlannerByTrailer from 'containers/features/planner/PlannerByTrailer';
import PlannerByVehicle from 'containers/features/planner/PlannerByVehicle';
import PlannerByWorkTypes from 'containers/features/planner/PlannerByWorkTypes';
import DriversPlannerByVehicle from 'containers/features/planner/DriversPlannerByVehicle';
import SidebarTab from 'containers/ui/SidebarTab';
import SidebarTabs from 'containers/ui/SidebarTabs';
import { SidebarTabsList, SidebarTabPanel } from 'components/ui/SidebarTabs';
import Modals from 'containers/ui/Modals';
import SeasonList from "containers/features/dicts/SeasonList";
import styles from './style.module.scss';

const App = ({ history }) => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route path="/login" component={null} />
      {/* always matched route below */}
      <Route
        path="/"
        component={() => (
          <div className={styles.wrapper}>
            <Sidebar>
              <MainMenu />
              <div className={styles.border}>
                <Calendar />
                <Periods className={styles.periods}/>
                <SidebarTabs className={styles.tabs} tabsname={"objectsLists"}>
                  <SidebarTabsList className={styles.tabs_list}>
                    <SidebarTab title="Транспортные средства" type="tree" name="vehicle" icon="vehicles" />
                    <SidebarTab title="Навесное оборудование" type="tree" name="trailer" icon="trailer" />
                    <SidebarTab title="Поля" type="tree" name="territory" icon="territory" />
                    <SidebarTab title="Виды работ" type="list" name="workType" icon="work_type" />
                  </SidebarTabsList>
                  <SidebarTabPanel className={styles.panel}><Vehicle className={styles.panel}/></SidebarTabPanel>
                  <SidebarTabPanel className={styles.panel}><Trailer className={styles.panel}/></SidebarTabPanel>
                  <SidebarTabPanel className={styles.panel}><Territory className={styles.panel}/></SidebarTabPanel>
                  <SidebarTabPanel className={styles.panel}><WorkType className={styles.panel}/></SidebarTabPanel>
                </SidebarTabs>
              </div>
            </Sidebar>
            <div className={styles.wrapper_right}>
              <Menu />
              <div className={styles.wrapper_page}>
                <Route path="/" exact component={Home} />
                <Route path="/example/" component={Example} />
                <Route path="/report/vehicle-location/" component={ReportVehicleLocation} />
                <Route path="/report/vehicle-track/" component={ReportVehicleTrack} />
                <Route path="/report/fact-works/" component={ReportFactWorks} />
                <Route path="/report/agro-events/" component={ReportAgroEvents} />
                <Route path="/planning/" exact component={PlannerByTrailer} />
                <Route path="/planning/trailer" exact component={PlannerByTrailer} />
                <Route path="/planning/vehicle" exact component={PlannerByVehicle} />
                <Route path="/planning/work_types" exact component={PlannerByWorkTypes} />
                <Route path="/planning/drivers" exact component={DriversPlannerByVehicle} />
                <Route path="/season" exact component={SeasonList} />
              </div>
              <TrailerTreeFolderPopup />
              <TrailerPopup />
              <TerritoryTreeFolderPopup />
              <TerritoryPopup />
              <WorkTypePopup />
              <TrailerAttachFromTimelinePopup />
              <CalendarModal />
              <Modals/>
            </div>
          </div>
        )}
      />
    </Switch>
  </ConnectedRouter>
);

export default App;
