import React, {Component} from 'react';
import _ from "lodash";
import PT from 'prop-types';
import CN from 'classnames';
import {TimelineHeaders, SidebarHeader, DateHeader} from 'react-calendar-timeline/lib';
import {withRouter} from 'react-router-dom';
import Timeline from "./Timeline";
import 'react-calendar-timeline/lib/Timeline.css';
import Modal from 'components/ui/Modal';
import Button from 'components/ui/Button';
import Input from "components/ui/Input";
import './timeline.scss';
import moment from 'moment';
import styles from './style.module.scss';


const minTime = moment().add(-5, 'years').startOf('year').valueOf();
const maxTime = moment().add(5, 'years').endOf('year').valueOf();
const maxOpenTime = maxTime + (maxTime - minTime);
const offset = moment().utcOffset() * 60 * 1000;
export const getLocalTime = (utcTime) => utcTime ? utcTime : null;
export const getUtcTime = (time) => time ? time : null;
export const getMaxOpenTime = (utcTime) => utcTime ? getLocalTime(utcTime) : maxOpenTime;
export const getId = id => id.split("|")[0];
export const isFolderGroup = group => group < 0 || (typeof group === 'string' && group.substr(0, 6) === "group_");

const plannerTypes = [
  {id: "1", title: "ТС", url: "/planning/vehicle"},
  {id: "2", title: "Навесное оборудование", url: "/planning/trailer"},
  {id: "3", title: "Виды работ по навесному оборудованию", url: "/planning/work_types"},
  {id: "4", title: "Водители по ТС", url: "/planning/drivers"},
];

const headerFormats = {
  year: {
    long: 'YYYY',
    mediumLong: 'YYYY',
    medium: 'YYYY',
    short: 'YY'
  },
  month: {
    long: 'MMMM YYYY',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY'
  },
  day: {
    long: 'dd DD',
    mediumLong: 'dd DD',
    medium: 'dd DD',
    short: 'DD'
  },
  hour: {
    long: 'dddd, LL, HH:00',
    mediumLong: 'L, HH:00',
    medium: 'HH:00',
    short: 'HH'
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm',
  }
};

class TimelinePlanner extends Component {

  state = {
    selecting: false,
    closedGroups: {},
    showConfirmDeleteDialog: false,
  };

  getChildGroups = (groups, id) => {
    let childGroups = groups.filter(group => group.parent === id).filter((group) => isFolderGroup(group.id)).map(group => group.id);
    childGroups.forEach(groupId => this.getChildGroups(groups, groupId));
    return childGroups;
  };


  toggleGroup = (groups, id) => {
    const {closedGroups} = this.state;
    const toggle = !closedGroups[id];
    let closing = [id].concat(this.getChildGroups(groups, id));
    let newClosedGroups = {...closedGroups};
    closing.forEach(groupId => newClosedGroups[groupId] = toggle);
    this.setState({
      closedGroups: newClosedGroups
    });
  };

  isAllGroupsClosed = () => {
    const groups = this.getChildGroups(this.props.getGroups());
    const {closedGroups} = this.state;
    const closed = Object.keys(closedGroups).filter(k => closedGroups[k]);
    return (closed.length === groups.length);
  };

  toggleAllGroups = () => {
    const groups = this.getChildGroups(this.props.getGroups());
    const toggle = !this.isAllGroupsClosed();
    const closedGroups = {};
    groups.forEach(group => closedGroups[group] = toggle);
    this.setState({closedGroups});
  };

  componentDidMount() {
    if (this.props.onInit) {
      this.props.onInit();
    }
    this.props.loadTimelineData();
  }

  componentDidUpdate(prevProps) {
    const isEqual = this.props.isEqualTreeData(prevProps.tree, this.props.tree);
    if (!isEqual) {
      this.props.loadTimelineData();
    }
  }

  getRenderedGroupsData() {
    const {closedGroups} = this.state;
    const groups = this.props.getGroups();
    return groups
      .filter(group => !(group.parent && closedGroups[group.parent]))
      .map(group => {
        const level = (group.level ? group.level * 15 : 0) + 'px';
        return Object.assign({id2: group.id + "|" + group.parent}, group, group.root || !closedGroups[group.parent] ? {
          displayTitle: group.title,
          title: group.root ? (
            <div onClick={() => this.toggleGroup(groups, group.id)}
                 style={{cursor: 'pointer', marginLeft: level}}>
              <div className={CN(
                styles.smallIcon,
                {[styles.minus]: !closedGroups[group.id]},
                {[styles.plus]: closedGroups[group.id]}
              )} />
              {group.title}
            </div>
          ) : (
            <div style={group.parent ? {paddingLeft: 15, marginLeft: level} : {}}>{group.title}</div>
          )
        } : {});
      })
  }

  handleCanvasClick = (group, time, e) => {
    if (isFolderGroup(group)) {
      return true;
    }
    const {selecting} = this.state;
    if (!selecting) {
      const cont = document.querySelector('.react-calendar-timeline .rct-scroll');

      const {left: scrollX} = this.timeline.scrollComponent.getBoundingClientRect();
      const selectedLeft = e.nativeEvent.clientX - scrollX;
      const y = e.nativeEvent.target.offsetTop;
      const scrollY = document.querySelector('.react-calendar-timeline').scrollTop;
      const {height: h} = e.nativeEvent.target.getBoundingClientRect();
      this.setState({selecting: true, selectedGroup: getId(group), intervalStart: time, startX: selectedLeft}, () => {
        this.selector.style.left = cont.offsetLeft + 'px';
        this.selector.style.top = (cont.offsetTop) + 'px';
        this.selected.style.left = selectedLeft + 'px';
        this.selected.style.width = '0px';
        this.selected.style.right = 'auto';
        this.selected.style.top = (y - scrollY) + 'px';
        this.selected.style.height = h + 'px';
      });
    }
    window.addEventListener('keydown', this.onSelectorKeyDown);

  };

  onSelectorKeyDown = (e) => {
    if (e.key === "Escape" || e.keyCode === 27) {
      window.removeEventListener('keydown', this.onSelectorKeyDown);
      this.setState({selecting: false});
    }
  };

  handleSelectorMouseMove = (e) => {
    const {startX} = this.state;
    const {left: scrollX, width} = this.timeline.scrollComponent.getBoundingClientRect();
    const x = e.nativeEvent.clientX - scrollX;
    const selectedWidth = x - startX;
    if (selectedWidth <= 0) {
      this.selected.style.left = x + 'px';
      if (this.selected.style.right === 'auto') {
        this.selected.style.width = 'auto';
        this.selected.style.right = (width - x) + 'px';
      }
    } else {
      this.selected.style.width = selectedWidth + 'px';
      this.selected.style.right = 'auto';
    }
  };

  handleSelectorClick = (e) => {
    window.removeEventListener('keydown', this.onSelectorKeyDown);
    const time = this.timeline.timeFromItemEvent(e);
    this.setState({selecting: false, intervalEnd: time}, () => {
      const {selectedGroup, intervalStart, intervalEnd} = this.state;
      if (isFolderGroup(selectedGroup)) {
        return;
      }
      const start = Math.min(intervalStart, intervalEnd);
      const end = Math.max(intervalStart, intervalEnd);
      this.props.onIntervalSelected(getId(selectedGroup), start, end);
    });
  };

  handleItemMove = (itemId, dragTime, newGroupOrder) => {
    const {data} = this.props;
    if (isFolderGroup(itemId)) {
      return;
    }
    const group = this.getRenderedGroupsData()[newGroupOrder];
    if (!group || isFolderGroup(group.id)) {
      return;
    }
    const item = data.find(item => "" + item.id === getId(itemId));
    this.props.onItemMove(item, group, dragTime);
  };

  selectorRef = (element) => this.selector = element;

  selectedRef = (element) => this.selected = element;

  timelineRef = (element) => this.timeline = element;

  itemRenderer = ({
                    item,
                    timelineContext,
                    itemContext,
                    getItemProps,
                    getResizeProps
                  }) => {
    const {left: leftResizeProps, right: rightResizeProps} = getResizeProps();
    const itemProps = getItemProps(item.itemProps);
    itemProps.style = {
      ...itemProps.style,
      background: itemContext.selected ? "#f5812f" : "#659ace",
      border: itemContext.selected ? "1px solid #f5812f" : "1px solid #659ace",
    };
    let leftOffset = 0;
    let maxWidth = "100%";
    if (item.start_time < timelineContext.visibleTimeStart && item.end_time > timelineContext.visibleTimeStart) {
      const k = timelineContext.timelineWidth / (timelineContext.visibleTimeEnd - timelineContext.visibleTimeStart);
      leftOffset = (timelineContext.visibleTimeStart - Math.max(item.start_time, timelineContext.canvasTimeStart)) * k;
      maxWidth = (itemContext.dimensions.width - leftOffset);
    }

    return (
      <div {...itemProps}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
        {itemContext.selected && <>{itemContext.canResizeLeft &&
        <span className="left-arrow" />}{itemContext.canResizeRight && <span className="right-arrow" />}</>}
        <div className={styles.rctWrapper} style={{left: leftOffset, maxWidth: maxWidth}}>
          <div
            className="rct-item-content"
            style={{maxHeight: `${itemContext.dimensions.height}`}}
          >
            {itemContext.title}
          </div>
          {itemContext.selected && <span
            className={CN([styles.iconDelete, itemContext.dimensions.top < 30 ? styles.iconDeleteLeft : styles.iconDeleteTop])}
            onClick={() => this.onShowDeleteItemDialog(item)}></span>}
        </div>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
      </div>
    )
  };

  onShowDeleteItemDialog = (item) => {
    this.setState({...this.state, showConfirmDeleteDialog: true, deletedItem: item});
  };

  onCloseDeleteItemDialog = () => {
    this.setState({...this.state, showConfirmDeleteDialog: false, deletedItem: null});
  };

  onConfirmDeleteItem = () => {
    const {deletedItem} = this.state;
    if (deletedItem) {
      this.props.onDeleteItem(deletedItem);
    }
    this.onCloseDeleteItemDialog();
  };

  handleTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart));
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime);
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
    this.setState({});
  };

  handleZoomOut = () => this.timeline.changeZoom(1.4);

  handleZoomIn = () => this.timeline.changeZoom(1.0 / 1.4);

  handleScrollLeft = () => this.timeline.onScroll(this.timeline.scrollComponent.scrollLeft - this.timeline.state.width / 3);

  handleScrollRight = () => this.timeline.onScroll(this.timeline.scrollComponent.scrollLeft + this.timeline.state.width / 3);

  handleItemContextMenu = (itemId, e, time) => {

  };

  changePlannerType = (event) => {
    const plannerType = plannerTypes.find(({id}) => id === event.target.value);
    const {history} = this.props;
    if (plannerType) {
      history.push(plannerType.url);
      this.setState({});
      return false;
    }
  };

  render() {
    const {data, isLoading, plannerType, pageTitle} = this.props;
    const {showConfirmDeleteDialog, selecting} = this.state;
    if (isLoading || !data) {
      return (<div className={styles.wrapper}>
        <div className={styles.loader}>Загрузка данных...</div>
      </div>);
    }
    const selectorClassName = CN(styles.intervalSelector, {[styles.intervalSelectorActive]: selecting});
    const groups = this.getRenderedGroupsData();
    const items = this.props.getItems(groups);
    return (
      <>
        {showConfirmDeleteDialog && (
          <Modal
            isDisplayed
            onToggle={this.onCloseDeleteItemDialog}
            header="Удаление привязки"
            content="Вы уверены, что хотите удалить привязку?"
            buttons={
              <React.Fragment>
                <Button onClick={this.onConfirmDeleteItem}>Да</Button>
                <Button onClick={this.onCloseDeleteItemDialog}>Нет</Button>
              </React.Fragment>
            }
          />
        )}
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles.toolbar}>
              <div className={CN(styles.iconButton, styles.zoomIn)} onClick={this.handleZoomIn} />
              <div className={CN(styles.iconButton, styles.zoomOut)} onClick={this.handleZoomOut} />
              <div className={CN(styles.iconButton, styles.scrollLeft)} onClick={this.handleScrollLeft} />
              <div className={CN(styles.iconButton, styles.scrollRight)} onClick={this.handleScrollRight} />
            </div>
            <div className="clearfix" />
          </div>
          <div className={styles.timelineContainerWrapper}>
            <div className={styles.timelineContainer}>
              <div ref={this.selectorRef} className={selectorClassName} onMouseMove={this.handleSelectorMouseMove}
                   onClick={this.handleSelectorClick}>
                <div className={styles.selected} ref={this.selectedRef} />
              </div>
              <Timeline
                ref={this.timelineRef}
                sidebarWidth={250}
                groups={groups}
                itemHeightRatio={0.74}
                items={items}
                keys={{
                  groupIdKey: 'id2',
                  groupTitleKey: 'title',
                  groupRightTitleKey: 'rightTitle',
                  groupLabelKey: 'displayTitle', // key for what to show in `InfoLabel`
                  itemIdKey: 'id2',
                  itemTitleKey: 'title',    // key for item div content
                  itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
                  itemGroupKey: 'group',
                  itemTimeStartKey: 'start_time',
                  itemTimeEndKey: 'end_time',
                }}
                lineHeight={35}
                minUnit="day"
                defaultTimeStart={moment().subtract(14, 'day')}
                defaultTimeEnd={moment().add(14, 'day')}
                dragSnap={3600 * 1000}
                canResize='both'
                minZoom={1800 * 60 * 1000}
                maxZoom={3 * 365.24 * 86400 * 1000}
                traditionalZoom={true}
                onItemMove={this.handleItemMove}
                onItemResize={this.props.onItemResize}
                onItemDoubleClick={this.props.onItemDoubleClick}
                onCanvasClick={this.handleCanvasClick}
                onCanvasDoubleClick={this.props.onCanvasDoubleClick}
                onTimeChange={this.handleTimeChange}
                onItemContextMenu={this.handleItemContextMenu}
                itemRenderer={this.itemRenderer}
              >
                <TimelineHeaders>
                  <SidebarHeader>
                    {({getRootProps}) => {
                      const isAllGroupsClosed = this.isAllGroupsClosed();
                      return (
                        <div {...getRootProps()} className={styles.sidebarHeader}>
                          <Input className={styles.siderbarPlannerType} type="select" onChange={this.changePlannerType} value={plannerType}>
                            {plannerTypes.map(({id, title}) => (<option key={id} value={id}>{title}</option>))}
                          </Input>
                          <div className={styles.toggleAllCont}>
                            <span className={styles.toggleAll} onClick={this.toggleAllGroups}>
                              <span className={CN(styles.smallIcon, {[styles.plus]: isAllGroupsClosed, [styles.minus]: !isAllGroupsClosed})} />
                              <span>{isAllGroupsClosed ? "Раскрыть все" : "Скрыть все"}</span>
                            </span>
                          </div>
                        </div>);
                    }}
                  </SidebarHeader>
                  <DateHeader unit="primaryHeader" />
                  <DateHeader
                    height={33}
                    intervalRenderer={({getIntervalProps, intervalContext, data}) => {
                      return <div {...getIntervalProps()}
                                  className={CN("rct-dateHeader", {weekend: intervalContext.interval.endTime.diff(intervalContext.interval.startTime, "days") === 1 && intervalContext.interval.startTime.isoWeekday() >= 6})}>
                        <span>{intervalContext.intervalText}</span>
                      </div>
                    }}
                    labelFormat={(
                      [timeStart, timeEnd],
                      unit,
                      labelWidth,
                      formatOptions = headerFormats
                    ) => {
                      let format;
                      if (labelWidth >= 150) {
                        format = formatOptions[unit]['long']
                      } else if (labelWidth >= 100) {
                        format = formatOptions[unit]['mediumLong']
                      } else if (labelWidth >= 50) {
                        format = formatOptions[unit]['medium']
                      } else {
                        format = formatOptions[unit]['short']
                      }
                      return timeStart.format(format)
                    }}
                    style={{height: 33}}
                  />
                </TimelineHeaders>
              </Timeline>
            </div>
          </div>
        </div>
      </>
    );
  }
}

TimelinePlanner.propTypes = {
  onInit: PT.func,
  tree: PT.object,
  data: PT.array,
  isLoading: PT.bool,
  pageTitle: PT.string,
  plannerType: PT.number,
  loadTimelineData: PT.func,
  isEqualTreeData: PT.func,
  getGroups: PT.func,
  getItems: PT.func,
  onIntervalSelected: PT.func,
  onDeleteItem: PT.func,
  onItemMove: PT.func,
  onItemResize: PT.func,
  onItemDoubleClick: PT.func,
  onCanvasDoubleClick: PT.func
};

export default withRouter(TimelinePlanner);
