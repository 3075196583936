import * as actionsTypes from 'constants/actionTypes.js';

export const closeTrailerPopup = () => ({
  type: actionsTypes.TRAILER_POPUP_CLOSE,
  payload: {},
});


export const newTrailer = () => ({
  type: actionsTypes.TRAILER_NEW,
  payload: { },
});

export const editTrailer = (data) => ({
  type: actionsTypes.TRAILER_EDIT,
  payload: { data },
});


export const closeTrailerTreeFolderPopup = () => ({
  type: actionsTypes.TRAILER_TREE_FOLDER_POPUP_CLOSE,
  payload: {},
});

export const newFolder = () => ({
  type: actionsTypes.TRAILER_FOLDER_NEW,
  payload: { },
});

export const editFolder = (data) => ({
  type: actionsTypes.TRAILER_FOLDER_EDIT,
  payload: { data },
});

export const closeTrailerAttachTimelinePopup = (formType, workTypeId, driverId, trailerId, vehicleId, fromTime, toTime, isUpdateData, fetchParams, itemId) => ({
  type: actionsTypes.TRAILER_ATTACH_TIMELINE_POPUP_CLOSE,
  payload: { formType, workTypeId, driverId, trailerId, vehicleId, fromTime, toTime, isUpdateData, fetchParams, itemId },
});
