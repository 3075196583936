import Immutable from 'immutable';
import { FACT_WORKS_REPORT_DATA_COMPLETED } from 'constants/actionTypes.js';
import { REPORT_ID_TOGGLE, REPORT_LOADING_TOGGLE } from 'constants/actionTypes.js';
import { MAP_TILES_CHANGED } from 'constants/actionTypes.js';
import { loadTiles, saveTiles} from 'reducers/reports/helpers';
import {REPORT_IMPORT_RUNNING_TOGGLE} from "constants/actionTypes";

const initialState = Immutable.fromJS({
  table: {},
  data: [],
  map: {
    geozones: [],
    tracks: [],
    speedTracks: [],
  },
  reportTaskId: null,
  loading: false,
  runningImport: false,
  progress: null,
  tiles: loadTiles('factWorksReport'),
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FACT_WORKS_REPORT_DATA_COMPLETED: {
      const { prop, data } = payload;
      if (!prop) {
        return initialState;
      }
      let stateNew = state.set(prop, Immutable.fromJS(data));
      return stateNew;
    }

    case REPORT_LOADING_TOGGLE:
      if (payload.report !== 'factWorksReport') {
        return state;
      }
      return state.set('loading', Immutable.fromJS(payload.loading));

    case REPORT_ID_TOGGLE:
      if (payload.report !== 'factWorksReport') {
        return state;
      }
      return state.set('reportTaskId', Immutable.fromJS(payload.reportTaskId));

    case REPORT_IMPORT_RUNNING_TOGGLE:
      if (payload.report !== 'factWorksReport') {
        return state;
      }
      return state.set('runningImport', payload.running).set('progress', payload.progress);;

    case MAP_TILES_CHANGED: {
        const { report, tiles } = payload;
        if (report !== 'factWorksReport') {
          return state;
        }
        saveTiles('factWorksReport', tiles);
        return state.set('tiles', tiles);
      }

    default:
      return state;
  }
};
