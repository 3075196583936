import {connect} from 'react-redux';
import PlannerByTrailerPage from 'components/planner/PlannerByTrailerPage';
import {getSelectedTree} from 'selectors/filters';
import toJS from 'helpers/tojs';
import {fetchTrailerVehicles, putTrailerVehicle} from 'actions/planner';
import {getTrailersData} from 'selectors/planner';
import {
  plannerAttachTrailerVehicle,
  plannerDetachTrailer,
  plannerDeleteVehicleTrailer,
  plannerStart
} from 'actions/planner';

const mapStateToProps = (state) => ({
  trailerTree: getSelectedTree(state, 'trailer'),
  data: getTrailersData(state),
});

const mapDispatchToProps = dispatch => ({
  onInit: () => dispatch(plannerStart('trailer')),
  loadData: (trailerIds, from, to) => dispatch(fetchTrailerVehicles(trailerIds, from, to)),
  putTrailerVehicle: (id, data, fetchParams) => dispatch(putTrailerVehicle(id, data, fetchParams)),
  attachVehicle: (id, params) => dispatch(plannerAttachTrailerVehicle(id, params)),
  detachTrailer: (id, fetchParams) => dispatch(plannerDetachTrailer(id, fetchParams)),
  deleteVehicleTrailer: (id, fetchParams) => dispatch(plannerDeleteVehicleTrailer(id, fetchParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(PlannerByTrailerPage));
