import React from 'react';
import {NavLink} from 'react-router-dom';
import VR from 'components/ui/VR';
import styles from './style.module.scss';

const pages = [
  {url: '/report/vehicle-location/', caption: 'Местоположение ТС'},
  {url: '/report/vehicle-track/', caption: 'Трек ТС'},
  {url: '/report/fact-works/', caption: 'Фактические работы'},
  {url: '/report/agro-events/', caption: 'События'},
  {
    url: '/planning/', caption: 'Планировщик', children: [
      {url: '/planning/trailer', caption: 'В разрезе по навесному оборудованию'},
      {url: '/planning/vehicle', caption: 'В разрезе по ТС'},
      {url: '/planning/work_types', caption: 'Виды работы в разрезе по навесному оборудованию'},
      {url: '/planning/drivers', caption: 'Водители в разрезе по ТС'},
    ]
  },
  {url: '/season/', caption: 'Сезоны'},
];

const Submenu = ({items}) => (
  <ul className={styles.submenu}>
    {items.map(item => (
      <li key={item.url}><NavLink to={item.url} exact activeClassName={styles.selected}>{item.caption}</NavLink></li>))}
  </ul>
);

const Menu = () => (
  <nav className={styles.menu}>
    {pages.map((page, index) => (
      [
        <div key={page.url}>
          <NavLink
            to={page.url}
            exact={!page.children}
            activeClassName={styles.selected}
          >
            {page.caption}
          </NavLink>
          {page.children && <Submenu items={page.children} />}
        </div>
      ]
    ))}
  </nav>
);

export default Menu;
