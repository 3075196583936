import React, { useState } from 'react';
import PT from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';
import { Portal } from 'react-portal';
import { POPUP_TRESHOLD_MS } from 'constants/index';
import { popper } from './style.module.scss';

const getTimeoutValue = (allowHoverOnPopup, fastShow, newOpened) => {
  if (
    (newOpened && !fastShow) ||
    (!newOpened && allowHoverOnPopup)
  ) {
    return POPUP_TRESHOLD_MS;
  }

  return 0;
}

const Popup = ({ container, popup, allowHoverOnPopup, fastShow, defaultOpen, onToggle }) => {
  const [opened, setOpened] = useState(defaultOpen);
  const [timer, setTimer] = useState(false);

  const toggleOpened = (newOpened) => () => {
    clearTimeout(timer);
    if (opened === newOpened) {
      return;
    }
    const newTimer = setTimeout(() => {
      setOpened(newOpened);
      onToggle && onToggle(newOpened);
    }, getTimeoutValue(allowHoverOnPopup, fastShow, newOpened) );
    setTimer(newTimer);
  };

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div
            ref={ref}
            onMouseOver={toggleOpened(true)}
            onMouseOut={toggleOpened(false)}
          >
            {container}
          </div>
        )}
      </Reference>
      {opened && (
        <Portal>
          <Popper placement="bottom">
            {({ ref, style, placement, arrowProps }) => (
              <div
                className={popper}
                ref={ref}
                style={style}
                data-placement={placement}
                onMouseOver={allowHoverOnPopup && toggleOpened(true)}
                onMouseOut={allowHoverOnPopup && toggleOpened(false)}
              >
                {popup}
                {/* TODO: add tooltip arrow */}
                {/* <div ref={arrowProps.ref} style={arrowProps.style} /> */}
              </div>
            )}
          </Popper>
        </Portal>
      )}
    </Manager>
  );
};

Popup.propTypes = {
  container: PT.node.isRequired,
  popup: PT.node.isRequired,
  allowHoverOnPopup: PT.bool,
  fastShow: PT.bool,
  defaultOpen: PT.bool,
};
Popup.defaultProps ={
  defaultOpen: false,
};

export default Popup;
