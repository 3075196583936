export const APP_INIT = 'APP_INIT';

export const UPDATE_AUTH_DATA = 'UPDATE_AUTH_DATA';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const COUNTER_UPDATE = 'COUNTER_UPDATE';
export const EXAMPLE_DATA_REQUEST = 'EXAMPLE_DATA_REQUEST';
export const EXAMPLE_DATA_REQUEST_SUCCESS = 'EXAMPLE_DATA_REQUEST_SUCCESS';
export const EXAMPLE_DATA_REQUEST_FAIL = 'EXAMPLE_DATA_REQUEST_FAIL';

export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';

export const PERIOD_FILTER_UPDATE = 'PERIOD_FILTER_UPDATE';
export const MODAL_CALENDAR_SHOW = 'MODAL_CALENDAR_SHOW';
export const MODAL_CALENDAR_CLOSE = 'MODAL_CALENDAR_CLOSE';

export const CHANGE_TABS = 'CHANGE_TABS';

export const TREE_NODE_SELECT = 'TREE_NODE_SELECT';
export const TREE_NODE_CHECKBOX_CLICK = 'TREE_NODE_CHECKBOX_CLICK';
export const TREE_NODE_OPENED_TOGGLE = 'TREE_NODE_OPENED_TOGGLE';
export const TREE_FILTER_UPDATE = 'TREE_FILTER_UPDATE';
export const TREE_ACTIVITY_FILTER_UPDATE = 'TREE_ACTIVITY_FILTER_UPDATE';
export const TREE_FILTER = 'TREE_FILTER';
export const TREE_ALL_CHECKBOXES_CLEAR = 'TREE_ALL_CHECKBOXES_CLEAR';
export const TREE_NODE_DROP = 'TREE_NODE_DROP';
export const TREE_FOLDER_NODE_DROP = 'TREE_FOLDER_NODE_DROP';

export const UPDATE_PARENT_FETCH = 'UPDATE_PARENT_FETCH';
export const UPDATE_PARENT_FETCH_SUCCESS = 'UPDATE_PARENT_FETCH_SUCCESS';
export const UPDATE_PARENT_FETCH_FAIL = 'UPDATE_PARENT_FETCH_FAIL';
export const UPDATE_FOLDER_PARENT_FETCH = 'UPDATE_FOLDER_PARENT_FETCH';

export const TREE_DATA_FETCH = 'TREE_DATA_FETCH';
export const TREE_DATA_FETCH_SUCCESS = 'TREE_DATA_FETCH_SUCCESS';
export const TREE_DATA_FETCH_FAIL = 'TREE_DATA_FETCH_FAIL';

export const TREE_FOLDER_DELETE = 'TREE_FOLDER_DELETE';
export const TREE_FOLDER_DELETE_SUCCESS = 'TREE_FOLDER_DELETE_SUCCESS';

export const ENTITY_CREATE = 'ENTITY_CREATE';
export const ENTITY_CREATE_SUCCESS = 'ENTITY_CREATE_SUCCESS';
export const ENTITY_UPDATE = 'ENTITY_UPDATE';
export const ENTITY_UPDATE_SUCCESS = 'ENTITY_UPDATE_SUCCESS';

export const LIST_ITEM_SELECT = 'LIST_ITEM_SELECT';
export const LIST_FILTER_UPDATE = 'LIST_FILTER_UPDATE';
export const LIST_FILTER = 'LIST_FILTER';

export const VEHICLE_VEHICLE_GET_LIST_SUCCESS = 'VEHICLE_VEHICLE_GET_LIST_SUCCESS';
export const VEHICLE_STATE_FETCH = 'VEHICLE_STATE_FETCH';
export const VEHICLE_TRAILER_FETCH = 'VEHICLE_TRAILER_FETCH';
export const VEHICLE_TRAILER_FETCH_SUCCESS = 'VEHICLE_TRAILER_FETCH_SUCCESS';
export const VEHICLES_DETAILS_RECEIVE = 'VEHICLES_DETAILS_RECEIVE';
export const VEHICLES_DETAILS_REMOVE = 'VEHICLES_DETAILS_REMOVE';
export const VEHICLES_TRACKS_RECEIVE = 'VEHICLES_TRACKS_RECEIVE';
export const VEHICLES_TRACKS_REMOVE = 'VEHICLES_TRACKS_REMOVE';
export const VEHICLE_TRAILERS_FETCH = 'VEHICLE_TRAILERS_FETCH';
export const VEHICLE_TRAILERS_FETCH_SUCCESS = 'VEHICLE_TRAILERS_FETCH_SUCCESS';
export const VEHICLE_TRAILERS_FETCH_FAIL = 'VEHICLE_TRAILERS_FETCH_FAIL';
export const VEHICLE_CURRENT_TRAILER_FETCH = 'VEHICLE_CURRENT_TRAILER_FETCH';
export const VEHICLE_CURRENT_TRAILER_FETCH_SUCCESS = 'VEHICLE_CURRENT_TRAILER_FETCH_SUCCESS';
export const VEHICLE_EVENTS_FETCH = 'VEHICLE_EVENTS_FETCH';
export const VEHICLE_FUEL_FETCH = 'VEHICLE_FUEL_FETCH';
export const VEHICLE_FUEL_RECEIVE = 'VEHICLE_FUEL_RECEIVE';
export const VEHICLE_ACTIVITY_FETCH = 'VEHICLE_ACTIVITY_FETCH';
export const VEHICLE_ACTIVITY_FETCH_SUCCESS = 'VEHICLE_ACTIVITY_FETCH_SUCCESS';
export const VEHICLE_FILTER_FETCH = 'VEHICLE_FILTER_FETCH';

export const VEHICLE_DRIVERS_FETCH = 'VEHICLE_DRIVERS_FETCH';
export const VEHICLE_DRIVERS_FETCH_SUCCESS = 'VEHICLE_DRIVERS_FETCH_SUCCESS';
export const VEHICLE_DRIVERS_FETCH_FAIL = 'VEHICLE_DRIVERS_FETCH_FAIL';

export const TRAILER_POPUP_CLOSE = 'TRAILER_POPUP_CLOSE';
export const TRAILER_NEW = 'TRAILER_NEW';
export const TRAILER_EDIT = 'TRAILER_EDIT';
export const TRAILER_TRAILER_GET_LIST_SUCCESS = 'TRAILER_TRAILER_GET_LIST_SUCCESS';
export const TRAILER_TRAILER_GET_ITEM_SUCCESS = 'TRAILER_TRAILER_GET_ITEM_SUCCESS';
export const TRAILER_WORK_TYPE_GET_LIST_SUCCESS = 'TRAILER_WORK_TYPE_GET_LIST_SUCCESS';
export const TRAILER_WORK_TYPE_GET_ITEM_SUCCESS = 'TRAILER_WORK_TYPE_GET_ITEM_SUCCESS';
export const TRAILER_TRAILER_UPDATE = 'TRAILER_TRAILER_UPDATE';
export const TRAILER_HISTORY_FETCH = 'TRAILER_HISTORY_FETCH';
export const TRAILER_HISTORY_FETCH_SUCCESS = 'TRAILER_HISTORY_FETCH_SUCCESS';
export const TRAILER_VEHICLES_FETCH = 'TRAILER_VEHICLES_FETCH';
export const TRAILER_VEHICLES_FETCH_SUCCESS = 'TRAILER_VEHICLES_FETCH_SUCCESS';
export const TRAILER_VEHICLES_FETCH_FAIL = 'TRAILER_VEHICLES_FETCH_FAIL';
export const TRAILER_VEHICLES_PUT = 'TRAILER_VEHICLES_PUT';
export const TRAILER_VEHICLES_PUT_SUCCESS = 'TRAILER_VEHICLES_PUT_SUCCESS';


export const TRAILER_WORK_TYPES_FETCH = 'TRAILER_WORK_TYPES_FETCH';
export const TRAILER_WORK_TYPES_FETCH_SUCCESS = 'TRAILER_WORK_TYPES_FETCH_SUCCESS';
export const TRAILER_WORK_TYPES_FETCH_FAIL = 'TRAILER_WORK_TYPES_FETCH_FAIL';
export const TRAILER_WORK_TYPES_PUT = 'TRAILER_WORK_TYPES_PUT';
export const TRAILER_WORK_TYPES_PUT_SUCCESS = 'TRAILER_WORK_TYPES_PUT_SUCCESS';
export const TRAILER_WORK_TYPES_ATTACH = 'TRAILER_WORK_TYPES_ATTACH';
export const TRAILER_WORK_TYPES_ATTACH_SUCCESS = 'TRAILER_WORK_TYPES_ATTACH_SUCCESS';
export const PLANNER_DELETE_WORK_TYPE_TRAILER = 'PLANNER_DELETE_WORK_TYPE_TRAILER';
export const WORK_TYPE_TRAILER_DELETE = 'WORK_TYPE_TRAILER_DELETE';
export const WORK_TYPE_TRAILER_DELETE_SUCCESS = 'WORK_TYPE_TRAILER_DELETE_SUCCESS';
export const PLANNER_ATTACH_WORK_TYPE_SHOW = 'PLANNER_ATTACH_WORK_TYPE_SHOW';
export const PLANNER_DELETE_VEHICLE_DRIVER = 'PLANNER_DELETE_VEHICLE_DRIVER';
export const VEHICLE_DRIVER_DELETE = 'VEHICLE_DRIVER_DELETE';
export const VEHICLE_DRIVER_DELETE_SUCCESS = 'VEHICLE_DRIVER_DELETE_SUCCESS';
export const VEHICLE_DRIVER_PUT = 'VEHICLE_DRIVER_PUT';
export const VEHICLE_DRIVER_PUT_SUCCESS = 'VEHICLE_DRIVER_PUT_SUCCESS';
export const VEHICLE_DRIVER_ATTACH = 'VEHICLE_DRIVER_ATTACH';
export const VEHICLE_DRIVER_ATTACH_SUCCESS = 'VEHICLE_DRIVER_ATTACH_SUCCESS';

export const TRAILER_TREE_FOLDER_POPUP_CLOSE = 'TRAILER_TREE_FOLDER_POPUP_CLOSE';
export const TRAILER_FOLDER_NEW = 'TRAILER_FOLDER_NEW';
export const TRAILER_FOLDER_EDIT = 'TRAILER_FOLDER_EDIT';
export const TRAILER_FOLDER_GET_ITEM_SUCCESS = 'TRAILER_FOLDER_GET_ITEM_SUCCESS';

export const TERRITORY_POPUP_CLOSE = 'TERRITORY_POPUP_CLOSE';
export const TERRITORY_NEW = 'TERRITORY_NEW';
export const TERRITORY_EDIT = 'TERRITORY_EDIT';
export const TERRITORY_TERRITORY_GET_ITEM_SUCCESS = 'TERRITORY_TERRITORY_GET_ITEM_SUCCESS';
export const TERRITORY_GEOZONE_TYPE_GET_LIST_SUCCESS = 'TERRITORY_GEOZONE_TYPE_GET_LIST_SUCCESS';
export const TERRITORY_CADASTER_SPOT_TYPE_GET_LIST_SUCCESS = 'TERRITORY_CADASTER_SPOT_TYPE_GET_LIST_SUCCESS';
export const TERRITORY_CULTURE_GET_LIST_SUCCESS = 'TERRITORY_CULTURE_GET_LIST_SUCCESS';
export const TERRITORY_SEASON_GET_LIST_SUCCESS = 'TERRITORY_SEASON_GET_LIST_SUCCESS';
export const TERRITORY_SEASON_GET_LIST = 'TERRITORY_SEASON_GET_LIST';
export const DRIVER_DRIVER_GET_LIST_SUCCESS = 'DRIVER_DRIVER_GET_LIST_SUCCESS';

export const TERRITORY_TREE_FOLDER_POPUP_CLOSE = 'TERRITORY_TREE_FOLDER_POPUP_CLOSE';
export const TERRITORY_FOLDER_NEW = 'TERRITORY_FOLDER_NEW';
export const TERRITORY_FOLDER_EDIT = 'TERRITORY_FOLDER_EDIT';
export const TERRITORY_FOLDER_GET_ITEM_SUCCESS = 'TERRITORY_FOLDER_GET_ITEM_SUCCESS';
export const TERRITORY_UPDATE_DATA = 'TERRITORY_UPDATE_DATA';
export const TERRITORY_UPDATE_DATA_SUCCESS = 'TERRITORY_UPDATE_DATA_SUCCESS';

export const SEASON_CREATE = 'SEASON_CREATE';
export const SEASON_CREATE_SUCCESS = 'SEASON_CREATE_SUCCESS';
export const SEASON_CREATE_FAIL = 'SEASON_CREATE_FAIL';

export const SEASON_UPDATE = 'SEASON_UPDATE';
export const SEASON_UPDATE_SUCCESS = 'SEASON_UPDATE_SUCCESS';
export const SEASON_UPDATE_FAIL = 'SEASON_UPDATE_FAIL';

export const SEASON_DELETE = 'SEASON_DELETE';
export const SEASON_DELETE_SUCCESS = 'SEASON_DELETE_SUCCESS';
export const SEASON_DELETE_FAIL = 'SEASON_DELETE_FAIL';

export const TERRITORY_LIST_FETCH = 'TERRITORY_LIST_FETCH';
export const TERRITORY_LIST_FETCH_SUCCESS = 'TERRITORY_LIST_FETCH_SUCCESS';

export const TRACK_VEHICLE_FETCH = 'TRACK_VEHICLE_FETCH';
export const TRACK_VEHICLE_FETCH_SUCCESS = 'TRACK_VEHICLE_FETCH_SUCCESS';

export const GEOZONES_IMPORT = 'GEOZONES_IMPORT';
export const GEOZONES_IMPORT_SUCCESS = 'GEOZONES_IMPORT_SUCCESS';
export const FETCH_GEOZONES_IMPORT_STATUS = 'FETCH_GEOZONES_IMPORT_STATUS';
export const FETCH_GEOZONES_IMPORT_STATUS_SUCCESS = 'FETCH_GEOZONES_IMPORT_STATUS_SUCCESS';
export const COMPLETED_GEOZONES_IMPORT_STATUS = 'COMPLETED_GEOZONES_IMPORT_STATUS';

export const TRAILER_ATTACH = 'TRAILER_ATTACH';
export const TRAILER_ATTACH_SUCCESS = 'TRAILER_ATTACH_SUCCESS';
export const TRAILER_DETACH = 'TRAILER_DETACH';
export const TRAILER_DETACH_SUCCESS = 'TRAILER_DETACH_SUCCESS';
export const VEHICLE_TRAILER_DELETE = 'VEHICLE_TRAILER_DELETE';
export const VEHICLE_TRAILER_DELETE_SUCCESS = 'VEHICLE_TRAILER_DELETE_SUCCESS';

export const WORK_TYPE_POPUP_CLOSE = 'WORK_TYPE_POPUP_CLOSE';
export const WORK_TYPE_NEW = 'WORK_TYPE_NEW';
export const WORK_TYPE_EDIT = 'WORK_TYPE_EDIT';

export const FACT_WORKS_REPORT_DATA_COMPLETED = 'FACT_WORKS_REPORT_DATA_COMPLETED';
export const AGRO_EVENTS_REPORT_DATA_COMPLETED = 'AGRO_EVENTS_REPORT_DATA_COMPLETED';

export const REPORT_LOADING_TOGGLE = 'REPORT_LOADING_TOGGLE';
export const REPORT_ID_TOGGLE = 'REPORT_ID_TOGGLE';
export const REPORT_IMPORT_RUNNING_TOGGLE = 'REPORT_IMPORT_RUNNING_TOGGLE';
export const MAP_TILES_CHANGED = 'MAP_TILES_CHANGED';

export const RFID_UPDATE_DATA = 'RFID_UPDATE_DATA';
export const RFID_UPDATE_DATA_SUCCESS = 'RFID_UPDATE_DATA_SUCCESS';

export const TRAILER_ATTACH_TIMELINE_POPUP_CLOSE = 'TRAILER_ATTACH_TIMELINE_POPUP_CLOSE';

export const PLANNER_START = 'PLANNER_START';
export const PLANNER_ATTACH_VEHICLE_SHOW = 'PLANNER_ATTACH_VEHICLE_SHOW';
export const PLANNER_ATTACH_TRAILER_SHOW = 'PLANNER_ATTACH_TRAILER_SHOW';
export const PLANNER_DETACH_TRAILER = 'PLANNER_DETACH_TRAILER';
export const PLANNER_DELETE_VEHICLE_TRAILER = 'PLANNER_DELETE_VEHICLE_TRAILER';
export const PLANNER_ATTACH_DRIVER_SHOW = 'PLANNER_ATTACH_DRIVER_SHOW';

export const REPORT_FACT_WORK_REQUEST = 'REPORT_FACT_WORK_REQUEST';
export const REPORT_FACT_WORK_REQUEST_SUCCESS = 'REPORT_FACT_WORK_REQUEST_SUCCESS';
export const REVOKE_REPORT_FACT = 'REVOKE_REPORT_FACT';
export const REPORT_FACT_WORK_RESULT_CHECK = 'REPORT_FACT_WORK_RESULT_CHECK';
export const REPORT_FACT_WORK_RESULT_CHECK_SUCCESS = 'REPORT_FACT_WORK_RESULT_CHECK_SUCCESS';
