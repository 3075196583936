import { connect } from 'react-redux';
import Panel from 'components/filters/vehicle/Panel';
import {
  getTreeSearchValue,
  getGroupedActivityCounters,
  getGroupedActivityIds,
} from 'selectors/filters';
import { updateTreeFilter, updateTreeActivityFilter } from 'actions/filters';
import { filterTree } from 'actions/filters';
import toJS from 'helpers/tojs';
import { ALL_ACTIVITY_FILTER } from 'constants/index';

const mapStateToProps = (state, props) => ({
  searchValue: getTreeSearchValue(state, 'vehicle'),
  activityValue: getTreeSearchValue(state, 'vehicle', 'activityValue'),
  counters: getGroupedActivityCounters(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSearchChange: event => {
    dispatch(updateTreeFilter('vehicle', event.target.value));
  },
  onActivityFilterChange: activityName =>
    dispatch((dispatch, getState) => {
      dispatch(updateTreeActivityFilter('vehicle', activityName));
      filterTreeThunk(dispatch, getState);
    }),
  onSearchStart: searchValue =>
    dispatch((dispatch, getState) => {
      filterTreeThunk(dispatch, getState);
    }),
});

const filterTreeThunk = (dispatch, getState) => {
  const searchValue = getTreeSearchValue(getState(), 'vehicle');
  const groupedIds = getGroupedActivityIds(getState()).toJS();
  const activity = getTreeSearchValue(getState(), 'vehicle', 'activityValue');
  const ids = groupedIds[activity];
  const isAllActivity = activity === ALL_ACTIVITY_FILTER;

  dispatch(filterTree('vehicle', searchValue, isAllActivity ? null : ids));
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Panel));
