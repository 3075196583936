import { LOCAL_STORAGE_KEY_PREFIX } from 'constants/index.js';
import { DEFAULT_TILES } from 'constants/index';

const KEY = `${LOCAL_STORAGE_KEY_PREFIX}-tiles`;

export const loadTiles = reportName =>
  localStorage.getItem(`${KEY}-${reportName}`) || DEFAULT_TILES;

export const saveTiles = (reportName, tiles) => {
  localStorage.setItem(`${KEY}-${reportName}`, tiles);
};
