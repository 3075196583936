import * as actionsTypes from 'constants/actionTypes.js';
import { getSelectedTreeNode } from 'selectors/filters';
import { loadEntity, loadList } from 'actions/common';
import { editTerritory } from 'actions/territory';

export const updatePeriodFilter = (from, to) => ({
  type: actionsTypes.PERIOD_FILTER_UPDATE,
  payload: { from, to },
});

export const changeTabs = (tabsName, index, prevIndex) => ({
  type: actionsTypes.CHANGE_TABS,
  payload: { tabsName, index, prevIndex },
});

export const selectTreeNode = (treeName, id, leaf, parentId) => ({
  type: actionsTypes.TREE_NODE_SELECT,
  payload: { treeName, id, leaf, parentId },
});

export const clickTreeNodeCheckbox = (treeName, id, leaf, parentId) => ({
  type: actionsTypes.TREE_NODE_CHECKBOX_CLICK,
  payload: { treeName, id, leaf, parentId },
});

export const toggleTreeNodeOpened = (treeName, id, leaf, parentId) => ({
  type: actionsTypes.TREE_NODE_OPENED_TOGGLE,
  payload: { treeName, id, leaf, parentId },
});

export const clearAllTreeCheckbockes = (treeName) => ({
  type: actionsTypes.TREE_ALL_CHECKBOXES_CLEAR,
  payload: { treeName },
});

export const updateTreeFilter = (treeName, searchValue) => ({
  type: actionsTypes.TREE_FILTER_UPDATE,
  payload: { treeName, searchValue },
});

export const updateTreeActivityFilter = (treeName, activity) => ({
  type: actionsTypes.TREE_ACTIVITY_FILTER_UPDATE,
  payload: { treeName, activity },
});

export const filterTree = (treeName, searchValue, ids) => ({
  type: actionsTypes.TREE_FILTER,
  payload: { treeName, searchValue, ids },
});

export const fetchTreeData = treeName => ({
  type: actionsTypes.TREE_DATA_FETCH,
  payload: {
    request: {
      url: `/${treeName}/folder/tree`,
    },
    treeName,
  },
});

export const fetchGeozonesImportStatus = () => ({
  type: actionsTypes.FETCH_GEOZONES_IMPORT_STATUS,
  payload: {
    request: {
      url: '/reports/import_data_oo/check_status',
    },
  },
});

export const completedGeozonesImportStatus = () => ({
  type: actionsTypes.COMPLETED_GEOZONES_IMPORT_STATUS,
});

export const selectListItem = (listName, itemId) => ({
  type: actionsTypes.LIST_ITEM_SELECT,
  payload: { listName, itemId },
});
export const updateListFilter = (listName, searchValue) => ({
  type: actionsTypes.LIST_FILTER_UPDATE,
  payload: { listName, searchValue },
});
export const filterList = (listName, searchValue)  => ({
  type: actionsTypes.LIST_FILTER,
  payload: { listName, searchValue },
})

export const editTerritoryThunk = territoryId => (dispatch, getState) => {
  const id =
    territoryId || getSelectedTreeNode(getState(), 'territory').toJS().id;
  // dispatch(loadList('territory', 'geozone_type'));
  dispatch(loadList('territory', 'cadaster_spot_type'));
  dispatch(loadList('territory', 'season'));
  dispatch(loadList('territory', 'culture'));
  dispatch(loadEntity('territory', 'territory', id));
  dispatch(editTerritory());
};

export const dropTreeNode = (listName, entityId, oldFolderId, newFolderId) => ({
  type: actionsTypes.TREE_NODE_DROP,
  payload: { listName, entityId, oldFolderId, newFolderId },
});


export const dropTreeFolderNode = (listName, entityId, newFolderId) => ({
  type: actionsTypes.TREE_FOLDER_NODE_DROP,
  payload: { listName, entityId, newFolderId },
});

export const updateTreeNodeParent = (listName, entityId, oldFolderId, newFolderId) => ({
  type: actionsTypes.UPDATE_PARENT_FETCH,
  payload: {
    request: {
      method: "PUT",
      url: `/${listName}/${listName}/change_parent`,
      data: { entity_id: entityId, old_folder_id: oldFolderId, new_folder_id: newFolderId }
    },
  },
});

export const updateTreeFolderNodeParent = (listName, entityId, newFolderId) => ({
  type: actionsTypes.UPDATE_FOLDER_PARENT_FETCH,
  payload: {
    request: {
      method: "PUT",
      url: `/${listName}/folder/change_parent`,
      data: { folder_id: entityId, parent_id: newFolderId }
    },
  },
});
