import { connect } from 'react-redux';
import ReportFactWorks from 'components/report-fact-works/Page';
import { getReportData } from 'selectors/reports';
import { getReportProp, getInReportProp } from 'selectors/reports';
import { updateFactWorksReportData } from 'actions/reports';

import toJS from 'helpers/tojs';

const mapStateToProps = (state, props) => ({
  data: getReportData(state, 'factWorksReport'),
  loading: getReportProp(state, 'factWorksReport', 'loading'),
  runningImport: getReportProp(state, 'factWorksReport', 'runningImport'),
  progress: getReportProp(state, 'factWorksReport', 'progress'),
  selectedIndex: getInReportProp(state, 'factWorksReport', ['table', 'selectedIndex']),
});

const mapDispatchToProps = dispatch => ({
  onRowClick: (selectedIndex, selectedGeozoneId) => {
    dispatch(updateFactWorksReportData('table', {selectedIndex, selectedGeozoneId}));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(ReportFactWorks));
