import React from 'react';
import CN from "classnames";
import PT from 'prop-types';
import _ from "lodash";
import Modal from 'components/ui/Modal';
import {Form, Field} from 'react-final-form'
import FormFooter from 'components/ui/FormFooter';
import Label from 'components/ui/Label';
import Button from 'components/ui/Button';
import {InputAdapter, SelectAdapter, DatetimeAdapter, FileBase64Adapter} from 'components/ui/adapter';
import Input from 'components/ui/Input';
import "react-datetime/css/react-datetime.css";
import {defaultSubmitForm, parseDecimal} from "helpers/form";
import 'bootstrap/dist/css/bootstrap.min.css';
import {buildDictMap} from "helpers/dictionary";
import CircularColor from 'react-circular-color';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import styles from './style.module.scss';
import moment from "moment";
import uuid from "uuid/v4";

const GEOZONE_TYPE_PLOT = 'plot';
const GEOZONE_TYPE_FIELD = 'field';
const GEOZONE_DEFAULT_COLOR = '#00ff00';

const Condition = ({when, is, children, geozoneTypeMap}) => (
  <Field name={when} subscription={{value: true}}>
    {({input: {value}}) => (
      geozoneTypeMap && geozoneTypeMap[is] && geozoneTypeMap[is].id === value ? children : null
    )}
  </Field>
);

Condition.propTypes = {
  geozoneTypeMap: PT.object,
};

class CropItems extends React.PureComponent {
  state = {
    showSeason: false,
    season: "",
    startDate: null,
    endDate: null,
    error: null,
  };

  getItems = () => {
    let {value: items} = this.props;
    if (!_.isArray(items) || items.length === 0) {
      items = [];
    }
    return items;
  };

  handleChangeItemValue = (index) => (name, value) => {
    const {onChange} = this.props;
    const items = this.getItems();
    const item = items[index] || {};
    onChange([...items.slice(0, index), {...item, [name]: value}, ...items.slice(index + 1)]);
  };

  handleItemAdd = () => {
    const {onChange} = this.props;
    const items = this.getItems();
    onChange([...items, {id: uuid()}]);
  };

  handleDeleteItem = (index) => () => {
    const {onChange} = this.props;
    const items = this.getItems();
    onChange([...items.slice(0, index), ...items.slice(index + 1)]);
  };

  handleAddSeasonBlock = () => {
    const {showSeason} = this.state;
    this.setState({showSeason: !showSeason, error: null});
  };

  handleOpened = () => {
    if (this.innerInput) {
      this.innerInput.focus();
    }
  };

  handleChangeSeason = ({target: {value}}) => {
    this.setState({season: value});
  };

  handleChangeStartDate = (value) => this.setState({startDate: value, error: null});

  handleChangeEndDate = (value) => this.setState({endDate: value, error: null});

  handleAddSeason = async () => {
    const {onAddSeason} = this.props;
    const {season, startDate, endDate} = this.state;
    if (!season) {
      this.setState({error: "Заполните название сезона"});
      return;
    }
    if (startDate && (!(startDate > 0) || !moment(startDate).isValid() || moment(startDate).year() > 9999)) {
      this.setState({error: "Некорректная дата старта сезона"});
      return;
    }
    if (endDate && (!(endDate > 0) || !moment(endDate).isValid() || moment(endDate).year() > 9999)) {
      this.setState({error: "Некорректная дата завершения сезона"});
      return;
    }
    if (startDate && endDate && moment(startDate).isAfter(moment(endDate))) {
      this.setState({error: "Дата завершения сезона раньше даты начала"});
      return;
    }
    await onAddSeason({name: season, alias: season, start_date: startDate ? moment(startDate).toDate().getTime() : null, end_date: endDate ? moment(endDate).toDate().getTime() : null});
    this.handleCloseSeason();
  };

  handleCloseSeason = () => {
    this.setState({showSeason: false, season: '', startDate: null, endDate: null, error: null});
  };

  render() {
    const {errors = [], seasons, cultures} = this.props;
    const {showSeason, season, startDate, endDate, error, submitting} = this.state;
    const items = this.getItems();
    const errorWithIndices = (errors || []).find((err) => _.isObject(err) && _.isArray(err.indices));
    const errorIndices = errorWithIndices ? errorWithIndices.indices : [];
    return (
      <>
        <div className={CN("container-fluid", styles.cropTable)}>
          {items.length === 0 ?
            <div className="col-12 text-center p-3"><Label>Нет данных о севобороте для данного поля</Label></div> :
            <>
              <div className="row align-items-center">
                <Label className="col-2">
                  <span>Сезон</span>
                  <Button className={styles.addSeasonBtn} type="button" onClick={this.handleAddSeasonBlock}>+</Button>
                  {showSeason && (
                    <Modal
                      disableToggle
                      isDisplayed={true}
                      onClose={this.handleCloseSeason}
                      onToggle={this.handleCloseSeason}
                      onOpened={this.handleOpened}
                      size={'lg'}
                      header="Добавить сезон"
                      content={
                        <>
                          <Label>Название</Label>
                          <Input ref={el => this.input = el} innerRef={el => this.innerInput = el} type="text" onChange={this.handleChangeSeason} value={season} />
                          <Label>Дата начала</Label>
                          <DatetimeAdapter className={styles.fixedDateTime} value={startDate} meta={{}} onChange={this.handleChangeStartDate}
                                           isValidDate={(current) => current && (!endDate || current.isBefore(moment(endDate)))}
                                     timeFormat={false} />
                          <Label>Дата завершения</Label>
                          <DatetimeAdapter className={styles.fixedDateTime} value={endDate} meta={{}} onChange={this.handleChangeEndDate}
                                           isValidDate={(current) => current && (!startDate || current.isAfter(moment(startDate)))}
                                     timeFormat={false} />
                          {error && <div className={styles.error}>{error}</div>}
                          <FormFooter right>
                            <Button type="submit" disabled={submitting} onClick={this.handleAddSeason}>Добавить</Button>
                            <Button onClick={this.handleCloseSeason}>Отмена</Button>
                          </FormFooter>
                        </>
                      }
                      hasFooter={false}
                    />
                  )}
                </Label>
                <Label className="col-4">Культура</Label>
                <Label className="col-2">Дата сева</Label>
                <Label className="col-2">Дата уборки</Label>
                <Label className="col-2">Средняя <nobr>урожайность, ц/га</nobr></Label>
              </div>
              <div className={CN("row", styles.cropTableBody)}>
                {items.map((item, index) => (
                  <CropItem
                    className={_.includes(errorIndices, index) ? styles.errorRow : undefined}
                    key={item.id}
                    seasons={seasons}
                    cultures={cultures}
                    item={item}
                    onDeleteItem={this.handleDeleteItem(index)}
                    onChangeItemValue={this.handleChangeItemValue(index)}
                  />
                ))}
              </div>
            </>}
        </div>
        {errors && errors.length > 0 && (
          <div className={styles.error}>{errors.map((err) => _.isObject(err) ? err.title : err).join(", ")}</div>
        )}
        <div className="my-2">
          <Button onClick={this.handleItemAdd}>Добавить</Button>
        </div>
      </>
    );
  }
}

class CropItem extends React.PureComponent {
  handleCultureChange = ({target: {value}}) => {
    const {onChangeItemValue} = this.props;
    onChangeItemValue("cultureId", parseInt(value) || null);
  };

  handleSeasonChange = ({target: {value}}) => {
    const {onChangeItemValue} = this.props;
    onChangeItemValue("seasonId", parseInt(value) || null);
  };

  handleProductivityChange = ({target: {value}}) => {
    const {onChangeItemValue} = this.props;
    onChangeItemValue("productivity", parseDecimal(value));
  };

  handleSowAt = (value) => {
    const {onChangeItemValue} = this.props;
    onChangeItemValue("sowAt", value ? moment(value).toDate().getTime() : null);
  };

  handleHarvestAt = (value) => {
    const {onChangeItemValue} = this.props;
    onChangeItemValue("harvestAt", value ? moment(value).toDate().getTime() : null);
  };

  render() {
    const {className, seasons, cultures, onDeleteItem, item: {cultureId, seasonId, sowAt, harvestAt, productivity}} = this.props;
    const options = seasons.map(({id, name, start_date, end_date}) =>
      ({id, name: `${name}${start_date || end_date ? ` - ${start_date ? moment(start_date).format("DD.MM.YYYY") : "..."}-${end_date ? moment(end_date).format("DD.MM.YYYY") : "..."}` : ""}`}));
    return (
      <div className={CN("row", className)}>
        <div className="col-2">
          <SelectAdapter options={options} type="select" onChange={this.handleSeasonChange} value={seasonId}
                         withEmptyItem meta={{}} />
        </div>
        <div className="col-4">
          <SelectAdapter options={cultures} type="select" onChange={this.handleCultureChange} value={cultureId}
                         withEmptyItem meta={{}} />
        </div>
        <div className="col-2">
          <DatetimeAdapter className={styles.fixedDateTime} value={sowAt} meta={{}} onChange={this.handleSowAt}
                    isValidDate={(current) => current && (!harvestAt || current.isBefore(moment(harvestAt)))}
                           timeFormat={false} />
        </div>
        <div className="col-2">
          <DatetimeAdapter className={styles.fixedDateTime} value={harvestAt} meta={{}} timeFormat={false}
                    isValidDate={(current) => current && (!sowAt || current.isAfter(moment(sowAt)))}
                           onChange={this.handleHarvestAt} />
        </div>
        <div className="col-2 d-flex flex-row align-items-center">
          <InputAdapter value={productivity} type="number" min={0} step={0.01} onChange={this.handleProductivityChange}
                        meta={{}} />
          <span className={styles.delete} onClick={onDeleteItem}>X</span>
        </div>
      </div>
    );
  }
}

class WeatherItems extends React.PureComponent {
  state = {
    error: null,
  };

  getItems = () => {
    let {value: weather} = this.props;
    if (!_.isArray(weather) || weather.length === 0) {
      weather = [];
    }
    return weather;
  };

  render() {
    const items = this.getItems();
    return (
      <>
        <div className={CN("container-fluid", styles.weatherTable)}>
          {items.length === 0 ?
            <div className="col-12 text-center p-3"><Label>Нет данных о погоде для данного поля</Label></div> :
            <>
              <div className="row">
                <Label className="col-2">Дата</Label>
                <Label className="col-2">Температура</Label>
                <Label className="col-2">Темп. мин</Label>
                <Label className="col-2">Темп. макс</Label>
                <Label className="col-1">Давление</Label>
                <Label className="col-1">Влажность</Label>
                <Label className="col-1">Ветер, м/с</Label>
                <Label className="col-1">Облачность</Label>
              </div>
              <>
                {items.map((item, index) => (
                  <WeatherItem
                    key={item.id}
                    item={item}
                  />
                ))}
              </>
            </>}
        </div>
      </>
    );
  }
}

class WeatherItem extends React.PureComponent {
  render() {
    const {className, item: {weather_at, temp_day, temp_min, temp_max, pressure, humidity, wind_speed, clouds}} = this.props;
    return (
      <div className={CN("row", "align-items-center", className)}>
        <div className="col-2">{moment(weather_at).format("DD.MM.YYYY")}</div>
        <div className="col-2">{temp_day}</div>
        <div className="col-2">{temp_min}</div>
        <div className="col-2">{temp_max}</div>
        <div className="col-1">{pressure}</div>
        <div className="col-1">{humidity}</div>
        <div className="col-1">{wind_speed}</div>
        <div className="col-1">{clouds}</div>
      </div>
    );
  }
}

class TerritoryForm extends React.PureComponent {
  render() {
    const geozoneTypeMap = buildDictMap(this.props.geozoneTypes);
    return (
      <div className="row">
        <div className="col-6">

          <div className="row">
            {this.props.isCreate &&
            <div className="col-12">
              <Label>Файл с координатами геозоны:</Label>
              <Field
                name="coord_file"
                component={FileBase64Adapter}
                accept=".kml,.kmz,.mif,.json"
                onDone={(file) => this.props.setCoordFile(file)}
              />
            </div>
            }
          </div>

          <div className="row">
            <div className="col-12">
              <Label>Тип геозоны:</Label>
              <Field
                type="select"
                name="geozone_type_id"
                placeholder="Тип геозоны"
                parse={(value) => parseInt(value) || null}
                options={this.props.geozoneTypes}
                withEmptyItem
                disabled={(!this.props.data || this.props.data.geozone_type_id) && !this.props.isCreate}
                component={SelectAdapter}
              />
            </div>
          </div>

          <div className="row">
            <Condition when="geozone_type_id" is={GEOZONE_TYPE_PLOT} geozoneTypeMap={geozoneTypeMap}>
              <div className="col-12">
                <Label>№ кадастрового участка</Label>
                <Field
                  name="cadastre_number"
                  placeholder="№ кадастрового участка"
                  component={InputAdapter}
                />
              </div>
              <div className="col-12">
                <Label>Тип земельного участка по кадастру:</Label>
                <Field
                  type="select"
                  name="cadaster_spot_type_id"
                  placeholder="Тип земельного участка по кадастру"
                  parse={(value) => parseInt(value) || null}
                  options={this.props.cadasterSpotTypes}
                  withEmptyItem
                  component={SelectAdapter}
                />
              </div>
            </Condition>

            <Condition when="geozone_type_id" is={GEOZONE_TYPE_FIELD} geozoneTypeMap={geozoneTypeMap}>
              <div className="col-12">
                <Label>Название или номер поля</Label>
                <Field
                  name="name"
                  placeholder="Название или номер поля"
                  component={InputAdapter}
                />
              </div>
              <div className="col-12">
                <Label>Тип поля:</Label>
                <Field
                  name="field_type"
                  placeholder="Тип поля"
                  component={InputAdapter} />
              </div>
              <div className="col-12">
                <Label>Тип земли:</Label>
                <Field
                  name="ground_type"
                  placeholder="Тип земли"
                  component={InputAdapter} />
              </div>
              <div className="col-12">
                <Label>{'Урожайность (ц/га):'}</Label>
                <Field
                  name={'crop_capacity'}
                  placeholder={'Урожайность (ц/га)'}
                  parse={(value) => parseDecimal(value)}
                  component={InputAdapter}
                />
              </div>
            </Condition>
          </div>
        </div>

        <div className="col-6">
          <Label>Цвет геозоны:</Label>
          <Field
            name={'color'}
            render={({input, meta}) => {
              if (input.value || this.props.isCreate) {
                return (
                  <CircularColor
                    centerRect
                    onChange={input.onChange}
                    color={input.value || GEOZONE_DEFAULT_COLOR}
                  />
                )
              } else {
                return (<div></div>)
              }
            }}
          />
        </div>
      </div>

    );
  }
}


class TerritoryPopup extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      fileCoord: null,
      data: {},
    }
  }

  setCoordFile = (file) => {
    this.setState({
      fileCoord: file,
    });
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isDisplayed && this.props.isDisplayed) {
      this.setState({fileCoord: null});
    }
    if (!_.isEqual(this.props.data, prevProps.data) && _.isObject(this.props.data)) {
      this.setState({
        ...this.state,
        data: {...this.props.data, items: this.mapItemsToFrontend(_.get(this.props, "data.items"))}
      });
    }
  }


  validateItems = (value) => {
    const errors = [];
    const indices = [];
    (value || []).forEach(({cultureId, seasonId, productivity, sowAt, harvestAt}, index) => {
      if (!cultureId || !seasonId || !_.isFinite(parseDecimal(productivity)) || !(harvestAt > 0) || !(sowAt > 0) || parseDecimal(productivity) < 0 || parseDecimal(productivity) > 1000000
        || !moment(harvestAt).isValid() || !moment(sowAt).isValid()
        || moment(harvestAt).year() > 9999 || moment(sowAt).year() > 9999
        || moment(harvestAt).isBefore(moment(sowAt))) {
        indices.push(index);
      }
    });
    if (!_.isEmpty(indices)) {
      errors.push({title: "Некорректно заполнены данные севоооброта", indices});
    }
    return !_.isEmpty(errors) ? errors : undefined;
  };

  mapItemsToBackend = (items) => {
    return (items || []).map(({id, cultureId: culture_id, seasonId: season_id, sowAt: sow_at, harvestAt: harvest_at, ...rest}) => ({
      ...rest,
      culture_id,
      season_id,
      sow_at,
      harvest_at
    }));
  };

  mapItemsToFrontend = (items) => {
    return (items || []).map(({culture_id: cultureId, season_id: seasonId, sow_at: sowAt, harvest_at: harvestAt, ...rest}) => ({
      ...rest,
      id: uuid(),
      cultureId,
      seasonId,
      sowAt,
      harvestAt
    }));
  };

  render = () => {
    const props = this.props;
    const state = this.state;
    const geozoneTypeMap = buildDictMap(this.props.geozoneTypes);

    return (
      <Modal
        isDisplayed={props.isDisplayed}
        onToggle={props.onClose}
        size={'lg'}
        header={
          (props.isCreate ? 'Добавление' : 'Редактирование') + ' геозоны'
        }
        content={
          <Form
            data={state.data}
            onSubmit={values => {
              if (state.fileCoord) {
                values.coord_file = state.fileCoord;
              }
              return defaultSubmitForm({...values, items: this.mapItemsToBackend(values.items)}, props);
            }}
            initialValues={state.data}
            render={({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit}>

                <Tabs>
                  <TabList>
                    <Tab>Основное</Tab>
                    <Tab>Севооборот</Tab>
                    <Tab>Погода</Tab>
                  </TabList>

                  <TabPanel className={styles.tabContent}>
                    <TerritoryForm {...props} setCoordFile={this.setCoordFile} />
                  </TabPanel>
                  <TabPanel className={styles.tabContent}>
                    {geozoneTypeMap[GEOZONE_TYPE_PLOT] && values.geozone_type_id === geozoneTypeMap[GEOZONE_TYPE_PLOT].id && (
                      <>
                        <Label>№ кадастрового участка</Label>
                        <Input disabled value={values.cadastre_number} />
                      </>
                    )}
                    {geozoneTypeMap[GEOZONE_TYPE_FIELD] && values.geozone_type_id === geozoneTypeMap[GEOZONE_TYPE_FIELD].id && (
                      <>
                        <Label>Название или номер поля</Label>
                        <Input disabled value={values.name} />
                      </>
                    )}
                    <h5 className="mt-2 mb-1">Севооборот</h5>
                    <Field
                      name="items"
                      validate={this.validateItems}
                      render={({input, meta}) => (
                        <CropItems
                          {...input}
                          onAddSeason={props.onAddSeason}
                          seasons={props.seasons}
                          cultures={props.cultures}
                          errors={meta.touched && (meta.error || meta.submitError) ? meta.error || meta.submitError : undefined}
                        />
                      )}
                    />
                  </TabPanel>
                  <TabPanel className={styles.tabContent}>
                    {geozoneTypeMap[GEOZONE_TYPE_PLOT] && values.geozone_type_id === geozoneTypeMap[GEOZONE_TYPE_PLOT].id && (
                      <>
                        <Label>№ кадастрового участка</Label>
                        <Input disabled value={values.cadastre_number} />
                      </>
                    )}
                    {geozoneTypeMap[GEOZONE_TYPE_FIELD] && values.geozone_type_id === geozoneTypeMap[GEOZONE_TYPE_FIELD].id && (
                      <>
                        <Label>Название или номер поля</Label>
                        <Input disabled value={values.name} />
                      </>
                    )}
                    <Field
                      name="weather"
                      render={({input, meta}) => (
                        <WeatherItems
                          {...input}
                        />
                      )}
                    />
                  </TabPanel>
                </Tabs>

                <FormFooter right>
                  <Button type="submit" disabled={submitting}>Сохранить</Button>
                  <Button onClick={props.onClose}>Отмена</Button>
                </FormFooter>
              </form>
            )}
          />
        }
        hasFooter={false}
      />
    );
  }
}

TerritoryPopup.propTypes = {
  isDisplayed: PT.bool,
  isCreate: PT.bool,
  data: PT.object,
  geozoneTypes: PT.array,
  cadasterSpotTypes: PT.array,
  onAddSeason: PT.func,
  onSubmit: PT.func,
  onClose: PT.func,
  seasons: PT.array,
  cultures: PT.array,
};

export default TerritoryPopup;
