import { connect } from 'react-redux';
import Modals from 'components/ui/Modals';
import { getDisplayedModals } from 'selectors/common';
import toJS from 'helpers/tojs';

const mapStateToProps = (state, props) => ({
  items: getDisplayedModals(state),
});

export default connect(mapStateToProps)(toJS(Modals));
