import { connect } from 'react-redux';
import Map from 'components/ui/Map';
import { editTerritoryThunk } from 'actions/filters';
import { mapTilesChanged } from 'actions/reports';
import { getFactReportPolygons, getFactReportTracks } from 'selectors/reports';
import { getReportProp } from 'selectors/reports';
import toJS from 'helpers/tojs';

const mapStateToProps = (state, props) => ({
  polygons: getFactReportPolygons(state),
  polylines: getFactReportTracks(state),
  tiles: getReportProp(state, 'factWorksReport', 'tiles'),
});

const mapDispatchToProps = dispatch => ({
  onClickPolygon: (id) => id ? dispatch(editTerritoryThunk(id)) : null,
  onBaseLayerChange: (tiles) => dispatch(mapTilesChanged('factWorksReport', tiles)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Map));
