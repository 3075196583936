import {connect} from 'react-redux';
import {closeTerritoryPopup, updateTerritoryData} from 'actions/territory';
import {storeEntity} from 'actions/common';
import TerritoryPopup from 'components/features/Territory/Popup';
import {getTerritoryPopupProp} from "selectors/territory";
import {getDictionary, getDictionaryPropByProp} from "selectors/common";
import toJS from 'helpers/tojs';
import {addSeason} from "actions/territory";


const mapStateToProps = (state, props) => ({
  isDisplayed: getTerritoryPopupProp(state, 'isDisplayed'),
  isCreate: getTerritoryPopupProp(state, 'isCreate'),
  data: getTerritoryPopupProp(state, 'data'),
  geozoneTypes: getDictionary(state, 'geozoneTypes'),
  cadasterSpotTypes: getDictionary(state, 'cadasterSpotTypes'),
  seasons: getDictionary(state, 'seasons'),
  cultures: getDictionary(state, 'cultures'),
});

const mapDispatchToProps = dispatch => ({
  onAddSeason: async (data) => dispatch(async (dispatch) => await dispatch(addSeason(data))),
  onSubmit: async (isDisplayed, data) =>
    dispatch(async (dispatch, getState) => {
      let payload = data;
      let geozoneType = getDictionaryPropByProp(
        getState(),
        'geozoneTypes',
        'id',
        data.geozone_type_id,
        'alias'
      );
      const entity = geozoneType ? `territory/${geozoneType}` : 'territory';

      const result = await dispatch(storeEntity('territory', entity, payload));
      if (result && (result.type === "ENTITY_CREATE_SUCCESS" || result.type === "ENTITY_UPDATE_SUCCESS")) {
        await dispatch(updateTerritoryData(result.payload.data.id));
      }
      return result;
    }),
  onClose: () => dispatch(closeTerritoryPopup()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(TerritoryPopup));
