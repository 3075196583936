import { connect } from 'react-redux';
import { selectTreeNode, clickTreeNodeCheckbox, toggleTreeNodeOpened } from 'actions/filters';
import { clearAllTreeCheckbockes } from 'actions/filters';
import TreeNode from 'components/filters/TreeNode';
import { getTreeItemProp } from 'selectors/filters';
import { getTreeItemLeafType } from 'selectors/filters';
import { getTreeNodeCheckboxColor } from 'selectors/filters';
import { getTreeItemColor } from 'selectors/filters';
import { getTerritoryName } from 'selectors/reports'
import {resizeLeftPanelList} from 'appEvents';

const mapStateToProps = (state, {treeName, id, title, keyPath, leaf}) => ({
  title: treeName === 'territory' && leaf ? getTerritoryName(state, id) || title : title,
  selected: getTreeItemProp(state, treeName, keyPath, 'selected'),
  checked: getTreeItemProp(state, treeName, keyPath, 'checked'),
  indeterminate: getTreeItemProp(state, treeName, keyPath, 'indeterminate'),
  opened: getTreeItemProp(state, treeName, keyPath, 'opened'),
  leafType: getTreeItemLeafType(state, treeName, keyPath),
  checkboxColor: getTreeNodeCheckboxColor(state, treeName, id, leaf),
  textColor: getTreeItemColor(state, treeName, keyPath, leaf),
});

const mapDispatchToProps = (dispatch, {treeName, leaf}) => ({
    onSelect: (id, keyPath, parentId) => {
      dispatch(selectTreeNode(treeName, id, leaf, parentId));
      if (!leaf) {
        resizeLeftPanelList();
        dispatch(toggleTreeNodeOpened(treeName, id, leaf, parentId));
      } else {
        dispatch(clearAllTreeCheckbockes(treeName));
        dispatch(clickTreeNodeCheckbox(treeName, id, leaf, parentId));
      }
    },
    onClick: (id, keyPath, parentId) =>
      dispatch(clickTreeNodeCheckbox(treeName, id, leaf, parentId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreeNode);
