import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

const getExamlpe = state => state.get('example');
const getServerData = state => state.get('serverData');

export const getCounterValue = createCachedSelector(
  [getExamlpe, (state, id) => id],
  (example, id) => example.getIn([id, 'value'])
)((state, id) => `${id}`);

export const isLoading = createSelector(
  [getServerData],
  data => data.get('isLoading')
);
export const getCode = createSelector(
  [getServerData],
  data => data.get('code')
);
