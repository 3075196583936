import { connect } from 'react-redux';
import { closeTrailerTreeFolderPopup } from 'actions/trailer';
import { storeEntity } from 'actions/common';
import TreeFolderPopup from 'components/common/tree/folder/Popup';
import { getTreeFolderPopupProp } from "selectors/trailer";
import toJS from 'helpers/tojs';

const mapStateToProps = (state, props) => ({
  isDisplayed: getTreeFolderPopupProp(state, 'isDisplayed'),
  isCreate: getTreeFolderPopupProp(state, 'isCreate'),
  data: getTreeFolderPopupProp(state, 'data'),
});

const mapDispatchToProps = dispatch => ({
  onOk: async (isDisplayed, data) => {
    return await dispatch(storeEntity('trailer', 'folder', data));
  },
  onClose: () => dispatch(closeTrailerTreeFolderPopup()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(TreeFolderPopup));
