import { connect } from 'react-redux';
import { closeCalendarModal } from '../../actions/calendarModal';
import { updatePeriodFilter } from '../../actions/filters';
import CalendarModal from '../../components/features/CalendarModal';
import { getPeriodValue } from '../../selectors/filters';
import {getCalendarModalProp} from "../../selectors/filters";

const mapStateToProps = (state, props) => ({
  isDisplayed: getCalendarModalProp(state, 'isDisplayed'),
  from: getPeriodValue(state, 'from'),
  to: getPeriodValue(state, 'to'),
});

const mapDispatchToProps = dispatch => ({
  onOk: (isDisplayed, from, to) => dispatch(updatePeriodFilter(from, to)),
  onClose: () => dispatch(closeCalendarModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarModal);
