import Immutable from 'immutable';
import {
  TREE_FILTER_UPDATE,
  TREE_ACTIVITY_FILTER_UPDATE,
} from 'constants/actionTypes.js';
import { ALL_ACTIVITY_FILTER } from "constants/index";

const initialState = Immutable.fromJS({
  value: '',
  activityValue: ALL_ACTIVITY_FILTER,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TREE_FILTER_UPDATE: {
      if (payload.treeName !== 'vehicle') {
        return state;
      }

      return state.set('value', payload.searchValue || '');
    }

    case TREE_ACTIVITY_FILTER_UPDATE: {
      if (payload.treeName !== 'vehicle') {
        return state;
      }

      return state.set('activityValue', payload.activity);
    }

    default:
      return state;
  }
};
