import { connect } from 'react-redux';
import Map from 'components/ui/Map';
import { editTerritoryThunk } from 'actions/filters';
import { mapTilesChanged } from 'actions/reports';
import { getLocationReportTerritories, getLocationReportVehicleMarkers } from 'selectors/reports';
import { getReportProp } from 'selectors/reports';
import toJS from 'helpers/tojs';

const mapStateToProps = (state, props) => ({
  polygons: getLocationReportTerritories(state),
  markers: getLocationReportVehicleMarkers(state),
  tiles: getReportProp(state, 'location', 'tiles'),
  runningImport: getReportProp(state, 'location', 'runningImport'),
  progress: getReportProp(state, 'location', 'progress'),
});

const mapDispatchToProps = dispatch => ({
  onClickPolygon: (id) => dispatch(editTerritoryThunk(id)),
  onBaseLayerChange: (tiles) => dispatch(mapTilesChanged('location', tiles)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Map));
