import { connect } from 'react-redux';
import { showModal } from 'actions/common';
import { closeTrailerAttachTimelinePopup } from 'actions/trailer';
import AttachPopup from 'components/features/Trailer/AttachPopup';
import { getVehicleUnattached, getTrailerUnattached, getWorkTypes, getDrivers } from 'selectors/filters';
import toJS from 'helpers/tojs';

const getItems = (state, formType) => {
  switch(formType) {
    case "trailer":
      return getVehicleUnattached(state);
    case "vehicle":
      return getTrailerUnattached(state);
    case "work_type":
      return getWorkTypes(state);
    case "driver":
      return getDrivers(state);
  }
};

const mapStateToProps = (state) => ({
  ...state.get('trailerAttachTimelinePopup').toJS(),
  items: getItems(state, state.get('trailerAttachTimelinePopup').toJS().formType),
});

const mapDispatchToProps = dispatch => ({
  onOk: (formType, workTypeId, driverId, vehicleId, fromTime, toTime, trailerId, itemId, fetchParams) => dispatch((dispatch) => {
    if (fromTime>=toTime || !toTime || !fromTime) {
      dispatch(showModal('incorrect_datetime_interval', 'Введите корректный временной интервал для привязки', 'Внимание'));
      return
    }
    if (formType === "trailer" && !vehicleId) {
      dispatch(showModal('unattached_vehicle_not_selected', 'Выберите ТС', 'Внимание'));
      return;
    }
    if (formType === "workType" && !workTypeId) {
      dispatch(showModal('unattached_work_type_not_selected', 'Выберите вид работ', 'Внимание'));
      return;
    }
    if (formType === "driver" && !driverId) {
      dispatch(showModal('unattached_driver_not_selected', 'Выберите водителя', 'Внимание'));
      return;
    }
    if (formType === "vehicle" && !trailerId) {
      dispatch(showModal('unattached_trailer_not_selected', 'Выберите навесное оборудование', 'Внимание'));
      return;
    }
    dispatch(closeTrailerAttachTimelinePopup(formType, workTypeId, driverId, trailerId, vehicleId, fromTime, toTime, true, fetchParams, itemId));
  }),
  onClose: () => dispatch(closeTrailerAttachTimelinePopup()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(AttachPopup));
