import * as actionsTypes from 'constants/actionTypes.js';

export const closeWorkTypePopup = () => ({
  type: actionsTypes.WORK_TYPE_POPUP_CLOSE,
  payload: {},
});


export const newWorkType = () => ({
  type: actionsTypes.WORK_TYPE_NEW,
  payload: { },
});

export const editWorkType = (data) => ({
  type: actionsTypes.WORK_TYPE_EDIT,
  payload: { data },
});
