import React from 'react';
import PT from 'prop-types';
import moment from 'moment';
import styles from './style.module.scss';

const NULL_VALUE = '- - -';

const formatDateTime = timestamp =>
  moment(timestamp).format('DD.MM.YYYY HH:mm');

const ArrowPopup = props => {
  const { vehicleName, date, speed, mileage } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>{vehicleName}</div>

      <div className={styles.twoColumns}>
        <div>
          <div className={styles.label}>Дата:</div>
          <div className={styles.value}>{formatDateTime(date)}</div>
        </div>
        <div>
          <div className={styles.label}>Скорость:</div>
          <div className={styles.value}>{speed} км/ч</div>
        </div>
      </div>

      <div className={styles.twoColumns}>
        <div>
        <div className={styles.label}>Пробег:</div>
          <div className={styles.value}>{mileage} км</div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

ArrowPopup.propTypes = {
  vehicleName: PT.string,
  date: PT.number,
  speed: PT.oneOfType([PT.number, PT.string]),
  mileage: PT.oneOfType([PT.number, PT.string]),
};

ArrowPopup.defaultProps = {
  vehicleName: NULL_VALUE,
  speed: NULL_VALUE,
  mileage: NULL_VALUE,
};

export default ArrowPopup;
