import React from 'react';
import {Then, Else} from 'react-if';
import IfReportLoading from 'containers/features/reports/IfReportLoading';
import LocationMap from 'containers/features/reports/LocationMap';
import VehiclePopup from 'containers/features/reports/VehiclePopup';
import styles from './style.module.scss';
import IfReportImportRunningProgress from "containers/features/reports/IfReportImportRunningProgress";

const Page = () => (
  <div className={styles.wrapper}>
    <IfReportImportRunningProgress report={'location'}>
        <IfReportLoading report={'location'}>
          <Then>{() => <div>Загрузка</div>}</Then>
          <Else>
            {() => (
              <LocationMap fitToBounds markerPopup={id => <VehiclePopup id={id} />}
              />
            )}
          </Else>
        </IfReportLoading>
    </IfReportImportRunningProgress>
  </div>
);

export default Page;
