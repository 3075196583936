import { connect } from 'react-redux';
import Pagination from 'components/reports/ReactTable/Pagination';
import { getReportProp } from 'selectors/reports';
import { exportCsv } from 'helpers/export';

const mapDispatchToProps = (
  dispatch,
  { reportName, prepareRow, exportHeaders }
) => ({
  onExport: () =>
    dispatch((dispatch, getState) => {
      const data = getReportProp(getState(), reportName, 'data');
      exportCsv(prepareRow, exportHeaders, data.toJS());
    }),
});

export default connect(
  null,
  mapDispatchToProps
)(Pagination);
