import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import Button from 'components/ui/Button';
import styles from './style.module.scss';

const NULL_VALUE = '---';

class Counter extends React.PureComponent {
  // use currying for performance optimization
  // https://medium.freecodecamp.org/reactjs-pass-parameters-to-event-handlers-ca1f5c422b9
  onDecrement = () => e => {
    this.props.onDecrement && this.props.onDecrement(this.props.id);
  };

  onIncrement = () => e => {
    this.props.onIncrement && this.props.onIncrement(this.props.id);
  };

  onChange = () => e => {
    this.props.onChange && this.props.onChange(this.props.id, e.target.value);
  };

  render = () => (
    <div className={styles.container}>
      <div className={CN(styles.value, styles.centered)}>
        {this.props.value !== null ? this.props.value : NULL_VALUE}
      </div>
      <div className={styles.centered}>
        <input
          className={styles.input}
          type="text"
          value={this.props.value}
          onChange={this.onChange()}
        />
      </div>
      <div className={styles.buttons}>
        {/* bad */}
        {/* <Button onClick={() => this.props.onDecrement(this.props.id)}>-</Button> */}
        {/* <Button onClick={() => this.props.onIncrement(this.props.id)}>+</Button> */}
        {/* good */}
        <Button onClick={this.onDecrement()}>-</Button>
        <Button onClick={this.onIncrement()}>+</Button>
      </div>
    </div>
  );
}

Counter.propTypes = {
  id: PT.string.isRequired,
  value: PT.number,
  onDecrement: PT.func,
  onIncrement: PT.func,
  onChange: PT.func,
};

export default Counter;
