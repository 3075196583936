import { connect } from 'react-redux';
import { requestServerData } from 'actions/example';
import ApiExample from 'components/example/ApiExample';
import { isLoading, getCode } from 'selectors/example';

const mapStateToProps = (state, props) => ({
  isLoading: isLoading(state),
  code: getCode(state),
});

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(requestServerData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiExample);
