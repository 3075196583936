import React from 'react';
import CN from 'classnames';
import * as RDatetime from "react-datetime";
import styles from './style.module.scss';

const Datetime = ({ className, errorText, children, ...props }) => (
    <div>
      <RDatetime
        className={CN([
          styles.datetime,
          className,
        ])}
        {...props}
      >
        {children}
      </RDatetime>
      {errorText !== null && (
        <span className={ styles.error }>{ errorText }</span>
      )}
    </div>
);

export default Datetime;
