import * as turf from '@turf/turf';
import { territoryCoords2MapCoords } from 'helpers/territory';
import { MS_IN_SECOND, AGRO_EVENTS, STAY_IF_MIN_SEC } from "constants/index.js";
import * as moment from "moment";

export const AgroEventsReport = class {
  constructor(params, trailerHistory, trackData, geozones, events) {
    this.params = params;
    this.events = events;
    this.trailerHistory = trailerHistory;
    this.geozones = new Map();
    this.tracks = new Map();
    this.trailers = new Map();

    for (let geozoneId in geozones) {
      if (!geozones.hasOwnProperty(geozoneId)) {
        continue;
      }
      this.geozones.set(geozoneId, geozones[geozoneId]);
    }

    for (let vehicleUid in trackData) {
      if (!trackData.hasOwnProperty(vehicleUid)) {
        continue;
      }
      this.tracks.set(vehicleUid, trackData[vehicleUid]);
    }

    for (let i = 0; i < trailerHistory.length; i ++) {
      const item = trailerHistory[i];
      if (!this.trailers.has(item.vehicle_uid)) {
        this.trailers.set(item.vehicle_uid, []);
      }
      this.trailers.get(item.vehicle_uid).push(item);
    }

    // Create polygons for geozones
    this.geozonesPolygons = this.constructor.createGeozonesPolygons(geozones);
    this.filterGeozonesAndTracks();
  }

  /**
   * Get report data
   * @returns {[]}
   */
  getData() {
    let data = [];
    if (!Object.keys(this.params.vehicles).length) {
      return data;
    }
    data.push.apply(data, this.processTrailers(this.trailerHistory, this.params.from, this.params.to));
    data.push.apply(data, this.processEvents(this.events, this.params.from, this.params.to));

    this.fillTrackData();
    data.push.apply(data, this.processGeozoneIntersects());
    data.push.apply(data, this.processStays());
    data.push.apply(data, this.processSpeedLimits());

    return data;
  }

  processTrailers(trailers, from, to) {
    let data = [];
    trailers.forEach(item => {
      if (item.attached_at >= from && item.attached_at <= to) {
        const geozone = this.getGeozone(item.vehicle_uid, item.attached_at);
        data.push({
          vehicle_name: this.getVehicleName(item.vehicle_uid),
          event_at: this.formatTimestamp(item.attached_at),
          even_name: 'Смена навесного оборудования',
          parameters: `Присоединено оборудование ${item.trailer.name}`,
          territory_name: this.getGeozoneName(geozone),
        });
      }
      if (item.unattached_at >= from && item.unattached_at <= to) {
        const geozone = this.getGeozone(item.vehicle_uid, item.unattached_at);
        data.push({
          vehicle_name: this.getVehicleName(item.vehicle_uid),
          event_at: this.formatTimestamp(item.unattached_at),
          even_name: 'Смена навесного оборудования',
          parameters: `Отсоединено оборудование ${item.trailer.name}`,
          territory_name: this.getGeozoneName(geozone),
        });
      }
    });
    return data;
  }

  getVehicleName(vehicleUid) {
    const vehicle = this.params.vehicles[vehicleUid] || {};
    return vehicle.name;
  }

  getGeozoneName(geozone) {
    if (!geozone) {
      return null;
    }
    if (geozone.plot && geozone.plot.cadastre_number) {
      return geozone.plot.cadastre_number
    }
    if (geozone.field && geozone.field.name) {
      return geozone.field.name
    }
    return geozone.description;
  }

  getGeozone(vehicleUid, date) {
    const trackData = this.tracks.get(vehicleUid) || [];

    // Skip tracks with only one point
    if (!trackData || trackData.length < 2) {
      return null;
    }

    let prevTrackItem = trackData[0];
    for (let i = 1; i < trackData.length; i++) {
      const trackItem = trackData[i];
      if (prevTrackItem.date <= date && trackItem > date) {
        const geozoneIds = trackItem.inGeozones.keys();
        if (geozoneIds.length > 0) {
          const geozoneId = geozoneIds[0];
          return this.geozones[geozoneId];
        }
      }
      prevTrackItem = trackItem;
    }
  }

  processEvents(events, from, to) {
    let data = [];
    let fromS = from / MS_IN_SECOND;
    let toS = to / MS_IN_SECOND;
    events.forEach(item => {
      if (item.type in AGRO_EVENTS && item.timestamp >= fromS && item.timestamp <= toS) {
        const geozone = this.getGeozone(item.vehicle_uid, item.timestamp);
        data.push({
          vehicle_name: this.getVehicleName(item.vehicle_uid),
          event_at: this.formatTimestamp(item.timestamp * MS_IN_SECOND),
          even_name: AGRO_EVENTS[item.type],
          parameters: item.eventAddress,
          territory_name: this.getGeozoneName(geozone),
        });
      }
    });
    return data;
  }

  fillTrackData() {
    for (const trackData of this.tracks.values()) {

      // Skip tracks with only one point
      if (trackData.length < 2) {
        continue;
      }

      // Fill inGeozones
      for (let i = 0; i < trackData.length; i++) {
        const trackItem = trackData[i];
        trackItem.inGeozones = new Map();
        trackItem.calcSpeed = null;
        trackItem.point = turf.point(AgroEventsReport.getTrackPoint(trackItem));

        for (const [geozoneId, geozonePolygon] of this.geozonesPolygons.entries()) {
          const inGeozone = turf.booleanPointInPolygon(trackItem.point, geozonePolygon);
          if (inGeozone) {
            trackItem.inGeozones.set(geozoneId, inGeozone);
          }
        }
      }

      // Fill speed
      let prevTrackItem = trackData[0];
      for (let i = 1; i < trackData.length; i++) {
        const trackItem = trackData[i];

        // Inside of geozone
        if ((prevTrackItem.inGeozones.size > 0 || trackItem.inGeozones.size > 0) && prevTrackItem.calcSpeed === null) {
          prevTrackItem.calcSpeed = this.constructor.getSpeed(prevTrackItem, trackItem);
        }

        prevTrackItem = trackItem;
      }
    }
  }

  processGeozoneIntersects() {
    //  console.log(this.geozonesPolygons);
    let data = [];
    for (const [vehicleUid, trackData] of this.tracks.entries()) {

      // Skip tracks with only one point
      if (trackData.length < 2) {
        continue;
      }

      let prevTrackItem = trackData[0];
      for (let i = 1; i < trackData.length; i++) {
        const trackItem = trackData[i];

        for (const geozoneId of this.geozonesPolygons.keys()) {
          const geozone = this.geozones.get(geozoneId);

          if (!prevTrackItem.inGeozones.get(geozoneId) && trackItem.inGeozones.get(geozoneId)) {
            data.push({
              vehicle_name: this.getVehicleName(vehicleUid),
              event_at: this.formatTimestamp(trackItem.date),
              even_name: 'Вход в Поле/кадастровый участок',
              parameters: null,
              territory_name: this.getGeozoneName(geozone),
            });
          }
          else if (prevTrackItem.inGeozones.get(geozoneId) && !trackItem.inGeozones.get(geozoneId)) {
            data.push({
              vehicle_name: this.getVehicleName(vehicleUid),
              event_at: this.formatTimestamp(trackItem.date),
              even_name: 'Выход из Поля/кадастрового участка',
              parameters: null,
              territory_name: this.getGeozoneName(geozone),
            });
          }
        }

        prevTrackItem = trackItem;
      }
    }

    return data;
  }

  processStays() {
    let data = [];
    for (const [vehicleUid, trackData] of this.tracks.entries()) {

      // Skip tracks with only one point
      if (trackData.length < 2) {
        continue;
      }

      for (const geozoneId of this.geozonesPolygons.keys()) {

        let prevTrackItem = trackData[0];
        let startStayAt = 0;
        let trackItemStayAt = 0;
        for (let i = 1; i < trackData.length; i++) {
          const trackItem = trackData[i];

          // Inside of geozone
          if (prevTrackItem.inGeozones.get(geozoneId) || trackItem.inGeozones.get(geozoneId)) {

            // Stay finished
            if (prevTrackItem.calcSpeed !== 0 && startStayAt) {
              const stayInterval = trackItem.date - startStayAt;
              if (stayInterval / MS_IN_SECOND > STAY_IF_MIN_SEC) {
                const geozone = this.geozones.get(geozoneId);
                data.push({
                  vehicle_name: this.getVehicleName(vehicleUid),
                  event_at: this.formatTimestamp(trackItem.date),
                  even_name: `Стоянка ${stayInterval / MS_IN_SECOND} секунд`,
                  parameters: `${this.formatTimestamp(trackItemStayAt.date)} - ${this.formatTimestamp(trackItem.date)}`,
                  territory_name: this.getGeozoneName(geozone),
                });
              }
              startStayAt = 0;
            }
            // Stay started
            else if (prevTrackItem.calcSpeed === 0 && !startStayAt) {
              startStayAt = trackItem.date;
              trackItemStayAt = trackItem;
            }
          }

          prevTrackItem = trackItem;
        }
      }
    }

    return data;
  }

  processSpeedLimits() {
    let data = [];
    for (const [vehicleUid, trackData] of this.tracks.entries()) {

      // Skip tracks with only one point
      if (trackData.length < 2) {
        continue;
      }

      for (const geozoneId of this.geozonesPolygons.keys()) {

        let prevTrackItem = trackData[0];
        let speedingAt = 0;
        let trackItemSpeedingAt = 0;
        let maxSpeed = 0;
        for (let i = 1; i < trackData.length; i++) {
          const trackItem = trackData[i];

          // Inside of geozone
          if (prevTrackItem.inGeozones.get(geozoneId) || trackItem.inGeozones.get(geozoneId)) {

            let speedLimit = this.getTrailerSpeedLimit(vehicleUid, prevTrackItem.date);

            // Speeding finished
            if ((prevTrackItem.calcSpeed <= speedLimit || speedLimit === null || trackItem.inGeozones.size === 0) && speedingAt) {
              const speedingInterval = trackItem.date - speedingAt;
              const geozone = this.geozones.get(geozoneId);
              data.push({
                vehicle_name: this.getVehicleName(vehicleUid),
                event_at: this.formatTimestamp(trackItemSpeedingAt.date),
                even_name: `Превышение в течении ${speedingInterval / MS_IN_SECOND} секунд ` +
                           `с максимальной скоростью ${maxSpeed.toFixed(2)} км/ч`,
                parameters: `${this.formatTimestamp(trackItemSpeedingAt.date)} - ${this.formatTimestamp(trackItem.date)}`,
                territory_name: this.getGeozoneName(geozone),
              });
              speedingAt = 0;
              maxSpeed = 0;
            }
            // Speeding started
            else if (speedLimit !== null && prevTrackItem.calcSpeed > speedLimit && !speedingAt) {
              speedingAt = trackItem.date;
              trackItemSpeedingAt = trackItem;
              maxSpeed = prevTrackItem.calcSpeed;
            }
            // Speeding continues
            else if (speedLimit !== null && prevTrackItem.calcSpeed > speedLimit) {
              maxSpeed = Math.max(maxSpeed, prevTrackItem.calcSpeed);
            }
          }

          prevTrackItem = trackItem;
        }
      }
    }

    return data;
  }

  getTrailerSpeedLimit(vehicleUid, date, startFromIndex = 0) {
    // startFromIndex - for optimization
    const trailers = this.trailers.get(vehicleUid) || [];
    for (let i = startFromIndex; i < trailers.length; i++) {
      const trailer = trailers[i];
      if (trailer.attached_at <= date && (trailer.unattached_at > date || trailer.unattached_at === null)) {
        return trailer.trailer.max_speed;
      }
    }
    return null;
  }

  static getSpeed(prevTrackItem, trackItem) {
    const distance = turf.distance(prevTrackItem.point, trackItem.point);
    const intervalTime = (trackItem.date - prevTrackItem.date) / MS_IN_SECOND;
    return intervalTime ? distance / (intervalTime / (60 * 60)) : 0; // km/hour
  }

  /**
   * Create polygons for geozones
   * @param geozones
   * @returns {{}}
   */
  static createGeozonesPolygons(geozones) {
    let geozonesPolygons = new Map();
    for (let geozoneId in geozones) {
      if (!geozones.hasOwnProperty(geozoneId)) {
        continue;
      }
      const geozone = geozones[geozoneId];
      let coords = territoryCoords2MapCoords(geozone['coordinates']);
      // Broken geozones
      if (coords.length < 3) {
        continue
      }
      coords.push(coords[0]);
      geozonesPolygons.set(geozoneId, turf.polygon([coords], { name: geozone['name'] }));
    }
    return geozonesPolygons;
  }

  /**
   * This method using just for optimization. Code of this method may be commented if it necessary.
   */
  filterGeozonesAndTracks() {
    let polyMap = new Map();
    let actualVehicleUids = new Map();
    for (const [vehicleUid, trackData] of this.tracks.entries()) {

      // Remove and skip tracks with only one point
      if (trackData.length < 2) {
        this.tracks.delete(vehicleUid);
        continue;
      }

      // create Miltilines from track coords
      let points = [];
      for (let i = 0; i < trackData.length; i++) {
        const ct = trackData[i];
        const trackPoint = AgroEventsReport.getTrackPoint(ct);
        points.push(trackPoint);
      }
      const turfLines = turf.lineString(points);

      // intersect each miltiline with each polygon
      for (const [geozoneId, geozone] of this.geozonesPolygons.entries()) {

        // polygons with not null intersection with tracks
        if (!polyMap.get(geozoneId) && turf.lineIntersect(turfLines, geozone).features.length > 0) {
          polyMap.set(geozoneId, geozone);

          // Vehicles with track intersected with one or more geozones
          actualVehicleUids.set(vehicleUid, 1);
        }
      }
    }

    // Remove tracks without intersections with any geozones
    for (const vehicleUid of this.tracks.keys()) {
      if (!actualVehicleUids.get(vehicleUid)) {
        this.tracks.delete(vehicleUid);
      }
    }

    this.geozonesPolygons = polyMap;
  }

  static getTrackPoint(trackItem) {
    return [trackItem.longitude, trackItem.latitude];
  }

  /**
   * Format timestamp
   * @param {number} value
   * @param {string} format
   * @return {string}
   */
  formatTimestamp(value, format = 'DD.MM.YYYY HH:mm') {
    return moment.unix(value / 1000).format(format);
  }

};
