import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import ClickOutside from 'react-click-outside';
import styles from './style.module.scss';
import { OMNICOMM_HELP_URL } from 'constants/index';

class MainMenu extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  onClickLogo = () => e => {
    this.setState({ open: true });
  };

  onCloseList = () => e => {
    if (this.state.open === true) {
      this.setState({ open: false });
    }
  };

  onLogout = () => e => {
    this.props.onLogout && this.props.onLogout();
  };

  onClickHelp = () => {
    window.open(OMNICOMM_HELP_URL, '_blank');
    this.setState({ open: false });
  }

  // https://stackoverflow.com/a/25246044/1920758
  toggleFullScreen = () => e => {
    let elem = document.body;

    if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
      if (elem.requestFullScreen) {
        elem.requestFullScreen();
      }
      else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      }
      else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
      else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    }
    else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      }
      else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  logo = () => (
    <div
         className={styles.logo_container}
         onClick={this.onClickLogo()}
    >
      <span className={styles.dots}></span>
    </div>
  );

  menu = () => (
      <ul
          className={styles.list}
      >
        <li className={styles.user}>
          <span className={styles.icon_container}>
            <i className={CN([styles.icon_user, styles.icon, styles.icon_user])}></i>
          </span>
          <span className={styles.user_name} title={this.props.username}>{this.props.username}</span>
        </li>
        {/*
        <li className={styles.separator}></li>
        <li className={styles.hoverable}>
          <div href="/admin">
            <span className={styles.icon_container}><i className={CN([styles.icon, styles.icon_admin])}></i></span>
            <span>Администрирование</span>
          </div>
        </li>
        */}
        <li className={styles.separator}></li>
        <li className={styles.hoverable}>
          <div onClick={this.toggleFullScreen()}>
            <span className={styles.icon_container}><i className={CN([styles.icon, styles.icon_extend])}></i></span>
            <span>Во весь экран (F11)</span>
          </div>
        </li>
        <li className={styles.separator}></li>
        <li className={styles.hoverable}>
          <div onClick={this.onClickHelp}>
            <span className={styles.icon_container}><i className={CN([styles.icon, styles.icon_help])}></i></span>
            <span>Помощь</span>
          </div>
        </li>
        <li className={styles.separator}></li>
        <li className={styles.hoverable}>
          <div onClick={this.onLogout()}>
            <span className={styles.icon_container}><i className={CN([styles.icon, styles.icon_exit])}></i></span>
            <span>Выход</span>
          </div>
        </li>
      </ul>
  );

  render = () => (
    <ClickOutside onClickOutside={this.onCloseList()}>
    <div className={styles.menu}>
      {this.state.open ? this.menu() : this.logo()}
    </div>
    </ClickOutside>
  );
}

MainMenu.propTypes = {
  onLogout: PT.func,
  username: PT.string,
};

export default MainMenu;
