import React from 'react';
import CN from 'classnames';
import PT from 'prop-types';
import Input from 'components/ui/Input';
// import IconButton from 'components/ui/IconButton';
import HR from 'components/ui/HR';
import Tree from 'containers/filters/Tree';
import TreeNode from 'containers/filters/TreeNode';
import TreeRootCheckbox from 'containers/filters/TreeRootCheckbox';
import ActivityCheckboxFilter from 'components/filters/ActivityCheckboxFilter';
import Popup from 'components/ui/Popup';
import PopupContent from 'containers/filters/vehicle/PopupContent';
import styles from './style.module.scss';
import ReactResizeDetector from 'react-resize-detector';
import { resizeLeftPanelList } from 'appEvents';
import { ACTIVITY_FILTERS } from 'constants/index';

export class Panel extends React.PureComponent {
  handleActivityFilterChange = activityName => () => {
    this.props.onActivityFilterChange &&
      this.props.onActivityFilterChange(activityName);
  };

  render = () => {
    const { className } = this.props;

    return (
      <div className={CN([styles.container, className])}>
        <Input
          placeholder="Найти ТС..."
          icon="search"
          value={this.props.searchValue}
          onChange={this.props.onSearchChange}
          onSearch={this.props.onSearchStart}
        />
        <div className={styles.activityFilters}>
          {ACTIVITY_FILTERS.map(({ name, color }) => (
            <ActivityCheckboxFilter
              key={name}
              color={color}
              count={this.props.counters[name]}
              checked={this.props.activityValue === name}
              onClick={this.handleActivityFilterChange(name)}
            />
          ))}
        </div>
        <div className={styles.buttonsContainer}>
          <TreeRootCheckbox className={styles.checkbox} treeName="vehicle" />
        </div>
        <HR />
        <div className={'leftPanelList'}>
          <ReactResizeDetector
            handleHeight
            onResize={() => {
              resizeLeftPanelList();
            }}
          />
          <Tree
            treeName="vehicle"
            renderNode={(id, keyPath, title, isLeaf, parentId) => (
              <TreeNode
                treeName="vehicle"
                id={id}
                keyPath={keyPath}
                title={title}
                leaf={isLeaf}
                parentId={parentId}
                renderTitle={title => (
                  <Popup container={title} popup={<PopupContent id={id} />} />
                )}
              />
            )}
          />
        </div>
      </div>
    );
  };
}

Panel.propTypes = {
  counters: PT.object,
  searchValue: PT.string,
  activityValue: PT.string,
  onSearchChange: PT.func.isRequired,
  onActivityFilterChange: PT.func.isRequired,
  onSearchStart: PT.func.isRequired,
};

Panel.defaultProps = {
  counters: {},
};

export default Panel;
