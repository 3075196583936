import React from 'react';
import PT from 'prop-types';
import styles from './style.module.scss';

const Sidebar = (props) => (
  <div className={styles.sidebar}>
    {props.children}
  </div>
);

Sidebar.propTypes = {
  children: PT.node,
};

export default Sidebar;
