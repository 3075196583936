import React from 'react';
import Input from '../Input';

/**
 * Based on https://github.com/BosNaufal/react-file-base64
 */
export default class FileBase64 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }

  handleChange(e) {

    // get the files
    let files = e.target.files;

    // Process each file
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {

      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = (e) => {
        let base64 = reader.result;
        // Remove description
        base64 = base64.replace(/^data.*base64,/, '');

        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: file.size,
          base64: base64,
        };

        // Push it to the state
        allFiles.push(fileInfo);

        // If all files have been proceed
        if (allFiles.length === files.length){
          // Apply Callback function
          if (this.props.multiple) this.props.onDone(allFiles);
          else this.props.onDone(allFiles[0]);
        }

      } // reader.onload

    } // for

  }

  render () {
    return (
      <Input
        type="file"
        onChange={ this.handleChange.bind(this) }
        multiple={ this.props.multiple }
        accept={ this.props.accept }
        errorText={ this.props.errorText }
      />
    );
  }
}

FileBase64.defaultProps = {
  multiple: false,
};
