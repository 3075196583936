import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import auth from 'reducers/auth';
import example from 'reducers/example';
import dictionary from 'reducers/dictionary';
import filters from 'reducers/filters';
import modals from 'reducers/modals';
import reports from 'reducers/reports';
import serverData from 'reducers/serverData';
import calendarModal from 'reducers/calendarModal';
import trailerPopup from 'reducers/trailerPopup';
import territoryPopup from 'reducers/territoryPopup';
import trailerTreeFolderPopup from 'reducers/trailerTreeFolderPopup';
import territoryTreeFolderPopup from 'reducers/territoryTreeFolderPopup';
import trailerAttachTimelinePopup from 'reducers/trailerAttachTimelinePopup';
import vehicles from 'reducers/vehicles';
import workTypePopup from 'reducers/WorkType';
import planner from 'reducers/planner';

export const rootReducer = history =>
  combineReducers({
    auth,
    example,
    dictionary,
    filters,
    trailerPopup,
    trailerTreeFolderPopup,
    territoryPopup,
    territoryTreeFolderPopup,
    trailerAttachTimelinePopup,
    vehicles,
    workTypePopup,
    calendarModal,
    modals,
    reports,
    serverData,
    planner,
    router: connectRouter(history),
  });
