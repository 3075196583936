import Immutable from 'immutable';
import {
  TRAILER_WORK_TYPE_GET_LIST_SUCCESS,
  LIST_ITEM_SELECT,
  LIST_FILTER,
} from 'constants/actionTypes.js';
import { buildDictMap } from 'helpers/dictionary';
import { loadState, saveState } from './helpers.js';

const initialState = Immutable.fromJS(loadState());

export default (state = initialState, action) => {
  const stateNew = reducer(state, action);
  if (state !== stateNew) {
    saveState(stateNew.map(item => item.set('hidden', false)));
  }
  return stateNew;
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TRAILER_WORK_TYPE_GET_LIST_SUCCESS: {
      let items = buildDictMap(payload.data, 'id');
      for (var id in items) {
        items[id]['id'] = id.toString();
        items[id]['selected'] = state.getIn([id, 'selected']);
        items[id]['hidden'] = state.getIn([id, 'hidden']);
      }
      return Immutable.fromJS(items);
    }
    case LIST_ITEM_SELECT: {
      if (payload.listName === 'workType') {
        return state.map((item, itemId) => {
          let selected = false;
          if (payload.itemId === item.get('id') && !item.get('selected')) {
            selected = true;
          }
          return item.set('selected', selected);
        });
      }
      return state;
    }
    case LIST_FILTER: {
      const { searchValue } = payload;
      const regExp = new RegExp(searchValue, 'i');
      return state.map(item =>
        item.set(
          'hidden',
          searchValue !== '' && item.get('name').search(regExp) < 0
        )
      );
    }
    default:
      return state;
  }
};
