
export function territoryCoord2MapCoord(coord) {
  return [
    coord['longitude'], coord['latitude']
  ];
}

export function territoryCoords2MapCoords(coords) {
  return coords.map((item) => territoryCoord2MapCoord(item));
}

