import axios from 'axios'
import  {API_URL_JWT_ME, AUTH_REFRESH_URL, AUTH_TOKEN_NAME, AUTH_TOKEN_VALUE_TPL} from 'constants/index';


export function fetchJwtData(jwt) {
  return axios.get(
    API_URL_JWT_ME,
    {
      headers: {[AUTH_TOKEN_NAME]: AUTH_TOKEN_VALUE_TPL.replace('{token}', jwt)}
    }
  );
}

export function makeAuthTokenRefreshRequest(refreshToken) {
  return axios.post(
    AUTH_REFRESH_URL,
    null,
    {
      headers: {'Authorization': `JWT ${refreshToken}`}
    }
  );
}
