import { connect } from 'react-redux';
import Panel from 'components/filters/territory/Panel';
import { updateTreeFilter } from 'actions/filters';
import { filterTree } from 'actions/filters';
import { newTerritory } from 'actions/territory/index';
import { loadList } from 'actions/common';
import { editTerritoryThunk } from 'actions/filters';
import { getTreeSearchValue } from 'selectors/filters';
import { isTreeNodeSelected } from 'selectors/filters';
import toJS from 'helpers/tojs';

const mapStateToProps = (state, props) => ({
  searchValue: getTreeSearchValue(state, 'territory'),
  isFolderSelected: isTreeNodeSelected(state, 'territory', false),
  isNodeSelected: isTreeNodeSelected(state, 'territory', true),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSearchChange: (event) => {
    dispatch(updateTreeFilter('territory', event.target.value));
  },
  onSearchStart: (searchValue) => {
    dispatch(filterTree('territory', searchValue));
  },
  onNewItem: () => {
    // dispatch(loadList('territory', 'geozone_type'));
    dispatch(loadList('territory', 'cadaster_spot_type'));
    dispatch(loadList('territory', 'season'));
    dispatch(loadList('territory', 'culture'));
    dispatch(newTerritory());
  },
  onEditItem: () => dispatch(editTerritoryThunk()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Panel));
