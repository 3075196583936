import React from 'react';
import CN from 'classnames';
import { Button as ReactstrapButton } from 'reactstrap';
import styles from './style.module.scss';


const Button = ({ className, children, ...props }) => (
    <ReactstrapButton
      className={CN([
        styles.button,
        className,
      ])}
      {...props}
    >
        {children}
    </ReactstrapButton>
);

export default Button;
