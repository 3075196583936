import * as turf from '@turf/turf';
import { MS_IN_SECOND } from 'constants/index.js';

export const getDistance = (lon1, lat1, lon2, lat2) => {
  const from = turf.point([lon1, lat1]);
  const to = turf.point([lon2, lat2]);
  return turf.distance(from, to);
};

const METERS_IN_KM = 1000;
export const getDistanceM = (lon1, lat1, lon2, lat2) =>
  getDistance(lon1, lat1, lon2, lat2) * METERS_IN_KM;

export const getSpeed = (lon1, lat1, date1, lon2, lat2, date2) => {
  const distance = getDistance(lat1, lon1, lat2, lon2);
  const intervalTime = (date2 - date1) / MS_IN_SECOND;
  return intervalTime ? distance / (intervalTime / (60 * 60)) : 0; // km/hour
};
