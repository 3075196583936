import {connect} from 'react-redux';
import {getDictionary} from "selectors/common";
import toJS from 'helpers/tojs';
import {addSeason} from "actions/territory";
import {updateSeason, deleteSeason} from "actions/territory";
import SeasonListPage from "components/dicts/season/SeasonListPage";

const mapStateToProps = (state, props) => ({
  isLoading: getDictionary(state, 'isSeasonsLoading'),
  seasons: getDictionary(state, 'seasons'),
});

const mapDispatchToProps = dispatch => ({
  onAddSeason: async (data) => dispatch(async (dispatch) => await dispatch(addSeason(data))),
  onUpdateSeason: async (data) => dispatch(async (dispatch) => await dispatch(updateSeason(data))),
  onDeleteSeason: async (data) => dispatch(async (dispatch) => await dispatch(deleteSeason(data))),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(SeasonListPage));
