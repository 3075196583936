import { LOCAL_STORAGE_KEY_PREFIX } from 'constants/index.js';

const KEY = `${LOCAL_STORAGE_KEY_PREFIX}-workType`;

const initialState = [];

export const loadState = () => {
  const savedState = localStorage.getItem(KEY);
  return JSON.parse(savedState) || initialState;
};

export const saveState = state => {
  localStorage.setItem(KEY, JSON.stringify(state.toJS()));
};
