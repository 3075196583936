import { connect } from 'react-redux';
import VehiclePopup from 'components/report-vehicle-track/VehiclePopup';
import { getTreeInnerNodePropById } from 'selectors/filters';
import { getVehicleStateProp } from 'selectors/reports';
import { getVehicleTerritoryName } from 'selectors/reports';
import { getVehicleTrailerName } from 'selectors/reports';
import toJS from 'helpers/tojs';

const mapStateToProps = (state, { id }) => ({
  vehicleName: getTreeInnerNodePropById(state, 'vehicle', id, true, 'name'),
  driverName: null,
  territoryName: getVehicleTerritoryName(state, id),
  trailerName: getVehicleTrailerName(state, id),
  latitude: getVehicleStateProp(state, id, 'latitude'),
  longitude: getVehicleStateProp(state, id, 'longitude'),
  speed: getVehicleStateProp(state, id, 'currentSpeed'),
  fuel: getVehicleStateProp(state, id, 'currentFuel'),
});

export default connect(mapStateToProps)(toJS(VehiclePopup));
