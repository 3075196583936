import Immutable from 'immutable';
import { MODAL_CALENDAR_SHOW, MODAL_CALENDAR_CLOSE } from 'constants/actionTypes.js';
import {PERIOD_FILTER_UPDATE} from "constants/actionTypes";
const initialState = Immutable.fromJS({
  isDisplayed: false,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case PERIOD_FILTER_UPDATE:
    case MODAL_CALENDAR_CLOSE:
      return state.set('isDisplayed', false);

    case MODAL_CALENDAR_SHOW:
      return Immutable.fromJS({
          isDisplayed: payload.isDisplayed,
          from: payload.from,
          ro: payload.to,
        });

    default:
      return state;
  }
};
