import { connect } from 'react-redux';
import { closeTrailerPopup } from 'actions/trailer';
import { storeEntity } from 'actions/common';
import TrailerPopup from 'components/features/Trailer/Popup';
import { getTrailerPopupProp } from "selectors/trailer";
import { getDictionary } from "selectors/common";
import toJS from 'helpers/tojs';

const mapStateToProps = (state, props) => ({
  isDisplayed: getTrailerPopupProp(state, 'isDisplayed'),
  isCreate: getTrailerPopupProp(state, 'isCreate'),
  data: getTrailerPopupProp(state, 'data'),
});

const mapDispatchToProps = dispatch => ({
  onOk: async (isDisplayed, data) => {
    return await dispatch(storeEntity('trailer', 'trailer', data));
  },
  onClose: () => dispatch(closeTrailerPopup()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(TrailerPopup));
