import { connect } from 'react-redux';
import { hideModal } from 'actions/common';
import Modal from 'components/ui/Modal';
import { getModalProp } from 'selectors/common';

const mapStateToProps = (state, props) => ({
  header: getModalProp(state, props.type, 'header'),
  content: getModalProp(state, props.type, 'content'),
  isConfirm: getModalProp(state, props.type, 'isConfirm'),
});

const mapDispatchToProps = (dispatch, props) => ({
  onToggle: (isConfirmed) => dispatch(hideModal(props.type, isConfirmed)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
