import Immutable from 'immutable';
import { INCREMENT, DECREMENT, COUNTER_UPDATE } from 'constants/actionTypes.js';

const initialState = Immutable.fromJS({
  primary: { value: 0 },
  secondary: { value: 0 },
});
let keyPath, newValue;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INCREMENT:
      keyPath = [payload.id, 'value'];
      newValue = state.getIn(keyPath) + 1;
      return state.setIn(keyPath, newValue);
    case DECREMENT:
      keyPath = [payload.id, 'value'];
      newValue = state.getIn(keyPath) - 1;
      if (newValue < 0) {
        return state;
      }
      return state.setIn(keyPath, newValue);
    case COUNTER_UPDATE:
      keyPath = [payload.id, 'value'];
      newValue = parseInt(payload.value, 10);
      if (newValue < 0 || isNaN(newValue)) {
        return state;
      }
      return state.setIn(keyPath, newValue);
    default:
      return state;
  }
};
