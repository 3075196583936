import * as actionsTypes from 'constants/actionTypes';

export const fetchTrailerWorkTypes = (trailerIds, from, to) => {
  const params = new URLSearchParams();
  trailerIds.forEach(id => {
    params.append('trailer_ids', id);
  });
  params.append('from', from);
  params.append('to', to);

  return {
    type: actionsTypes.TRAILER_WORK_TYPES_FETCH,
    payload: {
      request: {
        method: 'GET',
        url: '/trailer/trailer_work_type',
        params,
      },
    },
  };
};

export const putTrailerWorkType = (id, params, fetchParams) => {
  return {
    type: actionsTypes.TRAILER_WORK_TYPES_PUT,
    payload: {
      fetchParams,
      request: {
        method: 'PUT',
        url: `/trailer/trailer_work_type/${id}`,
        data: params,
      },
    },
  };
};

export const attachWorkTypeToTrailer = (trailerId, workTypeId, fromTime, toTime, fetchParams) => ({
  type: actionsTypes.TRAILER_WORK_TYPES_ATTACH,
  payload: {
    fetchParams,
    request: {
      method: 'POST',
      url: '/trailer/trailer_work_type',
      data: {
        work_type_id: workTypeId,
        trailer_id: trailerId,
        attached_at: fromTime,
        unattached_at: toTime,
      },
    },
  },
});

export const plannerDeleteWorkTypeTrailer = (id, fetchParams) => ({type: actionsTypes.PLANNER_DELETE_WORK_TYPE_TRAILER, payload: {id, fetchParams}});

export const plannerAttachTrailerWorkType = (id, params) => {
  return {
    type: actionsTypes.PLANNER_ATTACH_WORK_TYPE_SHOW,
    payload: {
      id,
      formType: "work_type",
      params
    }
  }
};


export const fetchTrailerVehicles = (trailerIds, from, to) => {
  const params = new URLSearchParams();
  trailerIds.forEach(id => {
    params.append('trailer_ids', id);
  });
  params.append('from', from);
  params.append('to', to);

  return {
    type: actionsTypes.TRAILER_VEHICLES_FETCH,
    payload: {
      request: {
        method: 'GET',
        url: '/trailer/vehicles',
        params,
      },
    },
  };
};

export const putTrailerVehicle = (id, params, fetchParams) => {
  return {
    type: actionsTypes.TRAILER_VEHICLES_PUT,
    payload: {
      fetchParams,
      request: {
        method: 'PUT',
        url: `/trailer/vehicle_trailer/${id}`,
        params,
      },
    },
  };
};

export const plannerAttachTrailerVehicle = (id, params) => {
  return {
    type: actionsTypes.PLANNER_ATTACH_VEHICLE_SHOW,
    payload: {
      id,
      formType: "trailer",
      params
    }
  }
};

export const plannerAttachVehicleTrailer = (id, params) => {
  return {
    type: actionsTypes.PLANNER_ATTACH_TRAILER_SHOW,
    payload: {
      id,
      formType: "vehicle",
      params
    }
  }
};

export const plannerDetachTrailer = (id, fetchParams) => ({type: actionsTypes.PLANNER_DETACH_TRAILER, payload: {id, fetchParams}});

export const plannerDeleteVehicleTrailer = (id, fetchParams) => ({type: actionsTypes.PLANNER_DELETE_VEHICLE_TRAILER, payload: {id, fetchParams}});

export const plannerStart = (mode) => ({
  type: actionsTypes.PLANNER_START,
  payload: {mode: mode},
});


export const plannerAttachVehicleDriver = (id, params) => {
  return {
    type: actionsTypes.PLANNER_ATTACH_DRIVER_SHOW,
    payload: {
      id,
      formType: "driver",
      params
    }
  }
};


export const putVehicleDriver = (id, params, fetchParams) => {
  return {
    type: actionsTypes.VEHICLE_DRIVER_PUT,
    payload: {
      fetchParams,
      request: {
        method: 'PUT',
        url: `/driver/vehicle_driver/${id}`,
        data: params,
      },
    },
  };
};

export const attachDriverToVehicle = (driverId, vehicleId, fromTime, toTime, fetchParams) => ({
  type: actionsTypes.VEHICLE_DRIVER_ATTACH,
  payload: {
    fetchParams,
    request: {
      method: 'POST',
      url: '/driver/vehicle_driver',
      data: {
        driver_id: driverId,
        vehicle_uid: vehicleId,
        start_at: fromTime,
        finish_at: toTime,
      },
    },
  },
});

export const plannerDeleteVehicleDriver = (id, fetchParams) => ({type: actionsTypes.PLANNER_DELETE_VEHICLE_DRIVER, payload: {id, fetchParams}});

