import React from 'react';
import ReactDOMServer from 'react-dom/server';
import CN from 'classnames';
import PT from 'prop-types';
import L from 'leaflet';
import { getDistanceM } from 'helpers/geo';
import { Map as LeafletMap, TileLayer, WMSTileLayer, LayersControl } from 'react-leaflet';
import { Marker, Polygon, Polyline, Popup, Tooltip } from 'react-leaflet';
import { WEATHER_API_KEY } from 'constants/index';
// https://github.com/YUzhva/react-leaflet-markercluster/issues/71#issuecomment-454988534
// import MarkerClusterGroup from 'react-leaflet-markercluster';
import MarkerClusterGroup from 'react-leaflet-markercluster/dist/react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import styles from './style.module.scss';

// import { ReactComponent as CarIcon } from './icons/car.svg';
import { ReactComponent as CarCircleIcon } from './icons/car-circle.svg';
import { ReactComponent as MarkerIcon } from './icons/marker.svg';

const LAYERS = [
  {
    name: 'osm',
    caption: 'OSM карта',
    url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  },
  {
    name: 'omnicomm',
    caption: 'Омникомм карта',
    url: 'http://api.1traffic.ru/tiles/map/{z}/{x}/{y}.png',
    attribution: null,
  },
  {
    name: 'googleSatellite',
    caption: 'Google спутник',
    url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    attribution: null,
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  },
];

const WEATHER_LAYERS = [
  {
    name: 'weatherTemperature',
    caption: 'Температура',
    url: 'https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?opacity=0.5&appid={apikey}',
    legendImagePath: 'https://openweathermap.org/img/a/PR.png',
  },
  {
    name: 'weatherPrecipitation',
    caption: 'Осадки',
    url: 'https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?opacity=0.5&appid={apikey}',
    legendImagePath: 'https://openweathermap.org/img/a/PR.png',
  },
  {
    name: 'weatherWind',
    caption: 'Скорость ветра',
    url: 'https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?opacity=0.5&appid={apikey}',
    legendImagePath: 'https://openweathermap.org/img/a/PR.png',
  },
  {
    name: 'weatherPressure',
    caption: 'Давление',
    url: 'https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?opacity=0.5&appid={apikey}',
    legendImagePath: 'https://openweathermap.org/img/a/PR.png',
  },
];

const WMS_LAYERS = [
  {
    name: "pkk",
    caption: "Росреестр",
    url: "https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/Cadastre/MapServer/export",
    layers: 'show:30,27,24,23,22,17,8,0',
    dpi: 96,
    bboxSR: 102100,
    imageSR: 102100,
    size: "1024,1024",
    format: 'PNG32',
    f: "image",
    transparent: true,
  }
];

const ICONS = {
  car: CarCircleIcon,
  marker: MarkerIcon,
};
const BOUNDS_SIZE_METERS = 500;
const BOUNDS_PADDING_METERS = 50;
const DEFAULT_FILL_OPACITY = 0.25;

const reduceArrows = (markers, zoom) => {
  // prettier-ignore
  const metersPerPixel = [156412,78206,39103,19551,9776,4888,2444,1222,610.984,305.492,152.746,76.373,38.187,19.093,9.547,4.773,2.387,1.193,0.596,0.298, 0,149];
  if (!zoom) {
    return [];
  }
  const meterLimit = metersPerPixel[zoom] * 50;
  const result = markers.reduce((acc, marker) => {
    if (!acc.length) {
      return acc.concat(marker);
    }
    const prevMarker = acc[acc.length - 1];
    const { lng: lon1, lat: lat1 } = prevMarker.position;
    const { lng: lon2, lat: lat2 } = marker.position;
    const distance = getDistanceM(lon1, lat1, lon2, lat2) || 100;
    if (distance < meterLimit) {
      return acc;
    }
    return acc.concat(marker);
  }, []);
  return result;
};

class Map extends React.Component {
  state = {
    viewPort: {},
    isUserInteraction: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isUserInteraction) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if(this.props.polygons.length !== prevProps.polygons.length) {
      this.setState({ viewPort: {} });
    }
  }

  onViewportChanged = viewPort => {
    if (this.state.isUserInteraction) {
      this.setState({ viewPort, isUserInteraction: false });
    }
  };

  onUserInteraction = () => {
    this.setState({isUserInteraction: true});
  };

  onClickMarker = id => () => {
    this.props.onClickMarker && this.props.onClickMarker(id);
  };

  onBaseLayerChange = ({ name: layerCaption }) => {
    const layer = LAYERS.filter(({ caption }) => caption === layerCaption)[0];
    this.props.onBaseLayerChange && this.props.onBaseLayerChange(layer.name);
  };

  onClickPolygon = id => () => {
    this.props.onClickPolygon && this.props.onClickPolygon(id);
  };

  onClickPolyline = id => () => {
    this.props.onClickPolyline && this.props.onClickPolyline(id);
  };

  isFitToBounds = () =>
    !this.state.viewPort.center &&
    this.props.fitToBounds &&
    (this.props.markers.length ||
      this.props.polygons.length ||
      this.props.polylines.length);

  getBounds = () => {
    const positions = this.props.markers
      .map(item => item.position)
      .concat(...this.props.polygons.map(item => item.positions))
      .concat(...this.props.polylines.map(item => item.positions));
    if (positions.length === 1 || this.isAllPositionsEqual(positions)) {
      let center = this.getCenter(positions[0]);
      return center.toBounds(BOUNDS_SIZE_METERS);
    }
    return positions;
  };

  isAllPositionsEqual = positions => {
    const { lat: latFirst, lng: lngFirst } = positions[0];
    return positions.every(
      ({ lat, lng }) => lat === latFirst && lng === lngFirst
    );
  };

  getCenter = position => {
    const lat = typeof position.lat === "undefined" ? position[0] : position.lat;
    const lng = typeof position.lng === "undefined" ?  position[1] : position.lng;
    return L.latLng(lat, lng);
  };

  getMarkerIcon = marker =>
    !this.props.markerNode || this.props.useDefaultMarkerIcon(marker)
      ? this.getHtmlIcon(marker)
      : this.getCustomHtmlIcon(marker);

  getHtmlIcon = marker =>
    new L.DivIcon({
      className: styles.customIcon,
      html: ReactDOMServer.renderToString(this.getHtmlMarker(marker)),
      iconSize: [this.props.markerSize, this.props.markerSize],
      iconAnchor: [this.props.markerSize / 2, this.props.markerSize / 2],
    });

  getHtmlMarker = marker => (
    <div style={{ width: '30px', height: '30px' }}>
      {React.createElement(ICONS[marker.icon] || MarkerIcon, {
        width: this.props.markerSize,
        height: this.props.markerSize,
      })}
      {marker.title ? (
        <div className={styles.titleContainer}>
          <div>{marker.title}</div>
        </div>
      ) : null}
    </div>
  );

  getCustomHtmlIcon = marker =>
    new L.DivIcon({
      className: styles.customIcon,
      html: ReactDOMServer.renderToString(this.props.markerNode(marker)),
      iconSize: [this.props.markerSize, this.props.markerSize],
      iconAnchor: [this.props.markerSize / 2, this.props.markerSize / 2],
    });

  render = () => (
    <div className={CN(styles.mapContainer, this.props.className)}>
      <LeafletMap
        center={this.props.center || this.state.viewPort.center}
        zoom={this.props.zoom || this.state.viewPort.zoom}
        bounds={this.isFitToBounds() ? this.getBounds() : undefined}
        boundsOptions={
          this.isFitToBounds()
            ? { padding: [BOUNDS_PADDING_METERS, BOUNDS_PADDING_METERS] }
            : undefined
        }
        maxZoom={this.props.maxZoom}
        minZoom={2}
        onDragEnd={this.onUserInteraction}
        onViewportChanged={this.onViewportChanged}
        onBaseLayerChange={this.onBaseLayerChange}
      >
        <LayersControl position="topright">
          {LAYERS.map(({ name, caption, ...layer }) => (
            <LayersControl.BaseLayer
              key={name}
              checked={name === this.props.tiles}
              name={caption}
            >
              <TileLayer {...layer} />
            </LayersControl.BaseLayer>
          ))}

          {WEATHER_LAYERS
              .map(({url, ...layer}) => ({...layer, url: url.replace('{apikey}', this.props.weatherApiKey)}))
              .map(({ name, caption, ...layer }) => (
            <LayersControl.Overlay
              key={name}
              name={caption}
            >
              <TileLayer {...layer} />
            </LayersControl.Overlay>
          ))}

          {WMS_LAYERS
              .map(({ name, caption, ...wmsLayer }) => (
            <LayersControl.Overlay
              key={name}
              name={caption}
            >
              <WMSTileLayer {...wmsLayer} />
            </LayersControl.Overlay>
          ))}

          {this.props.polylines.map((polyline, index) => (
            <Polyline
              key={polyline.id || index}
              positions={polyline.positions}
              color={polyline.color}
              onClick={this.onClickPolyline(polyline.id)}
            />
          ))}

          {this.props.arrows.map(({ id, points = [] }) =>
            reduceArrows(points, this.state.viewPort.zoom).map(
              (arrow, index) => (
                <Marker
                  key={arrow.date || index}
                  position={arrow.position}
                  icon={this.getMarkerIcon(arrow)}
                >
                  {!arrow.isPopupDisabled && this.props.arrowPopup && (
                    <Popup autoPan={false}>
                      {this.props.arrowPopup(id, arrow)}
                    </Popup>
                  )}
                </Marker>
              )
            )
          )}

          <MarkerClusterGroup
            spiderfyOnMaxZoom={false}
            showCoverageOnHover={false}
            zoomToBoundsOnClick={false}
            maxClusterRadius={this.props.markerClusterRadius}
            iconCreateFunction={this.props.markerClusterIconFunction}
          >
            {this.props.markers.map((marker, index) => (
              <Marker
                key={marker.id || index}
                position={marker.position}
                icon={this.getMarkerIcon(marker)}
                onClick={this.onClickMarker(marker.id)}
              >
                {!marker.isPopupDisabled && this.props.markerPopup && (
                  <Popup autoPan={false}>
                    {this.props.markerPopup(marker.id)}
                  </Popup>
                )}
              </Marker>
            ))}
          </MarkerClusterGroup>

          {this.props.polygons.map((polygon, index) => (
            <Polygon
              key={polygon.id || index}
              positions={polygon.positions}
              color={polygon.color}
              opacity={polygon.opacity}
              strokeColor={polygon.strokeColor}
              fillColor={polygon.fillColor}
              fillOpacity={polygon.fillOpacity || DEFAULT_FILL_OPACITY}
              onClick={this.onClickPolygon(polygon.id)}
            >
              {polygon.description && (
                <Tooltip
                  key={polygon.id || index}
                  className={styles.polygonTooltip}
                  direction="center"
                  // permanent
                >
                  {polygon.description}
                </Tooltip>
              )}
            </Polygon>
          ))}
        </LayersControl>
      </LeafletMap>
    </div>
  );
}

const point = PT.shape({ lat: PT.number, lng: PT.number });
Map.propTypes = {
  center: PT.shape({ lat: PT.number, lng: PT.number }),
  zoom: PT.number,
  maxZoom: PT.number,
  fitToBounds: PT.bool,
  tiles: PT.oneOf(LAYERS.map(({ name }) => name)),

  markerSize: PT.number,
  markers: PT.arrayOf(
    PT.shape({
      id: PT.oneOfType([PT.number, PT.string]),
      position: PT.shape({ lat: PT.number, lng: PT.number }),
      icon: PT.oneOf(['car', 'marker']),
      color: PT.string,
      title: PT.string,
    })
  ),
  onClickMarker: PT.func,
  useDefaultMarkerIcon: PT.func,
  markerNode: PT.func,
  markerPopup: PT.func,
  markerClusterIconFunction: PT.func,

  polygons: PT.arrayOf(
    PT.shape({
      id: PT.oneOfType([PT.number, PT.string]),
      positions: PT.oneOfType([
        PT.arrayOf(point),
        PT.arrayOf(PT.arrayOf(point)),
        PT.arrayOf(PT.arrayOf(PT.arrayOf(point))),
      ]),
      color: PT.string,
    })
  ),
  onClickPolygon: PT.func,

  polylines: PT.arrayOf(
    PT.shape({
      id: PT.oneOfType([PT.number, PT.string]),
      positions: PT.arrayOf(PT.shape({ lat: PT.number, lng: PT.number })),
      color: PT.string,
    })
  ),
  onClickPolyline: PT.func,
  arrows: PT.arrayOf(
    PT.shape({
      id: PT.oneOfType([PT.number, PT.string]),
      points: PT.arrayOf(
        PT.shape({
          date: PT.number,
          position: PT.shape({ lat: PT.number, lng: PT.number }),
          direction: PT.number,
        })
      ),
    })
  ),
  arrowPopup: PT.func,
  onBaseLayerChange: PT.func,
  weatherApiKey: PT.string,
};

Map.defaultProps = {
  center: { lat: 0, lng: 0 },
  zoom: 1,
  maxZoom: 20,
  tiles: 'googleSatellite',
  markerSize: 30,
  markers: [],
  markerClusterRadius: 80,
  useDefaultMarkerIcon: () => false,
  polygons: [],
  polylines: [],
  arrows: [],
  weatherApiKey: WEATHER_API_KEY,
};

export default Map;
