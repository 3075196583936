// import { delay } from 'redux-saga';
import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import {updateAuthData, logoutThunk, initApp, userLogin} from 'actions/common';
import { push } from 'connected-react-router';
import  { fetchJwtData} from 'services/api/jwt.js';
import { getCurrentUser } from 'selectors/common';
import { isServerError, isServerErrorForbidden, isServerErrorNotFound, isServerErrorNotAuth } from 'helpers/api';

let isAppInitialized = false;

function* setTokensFromUrl(action) {
  const urlParams = new URLSearchParams(action.payload.location.search),
    jwt =  urlParams.get('access_token'),
    refresh =  urlParams.get('refresh');

  if (!jwt || !refresh) {
    const user = yield select(getCurrentUser);
    if (!user) {
      yield put(logoutThunk());
    }

    if (!isAppInitialized) {
      isAppInitialized = true;
      yield put(initApp());
    }
    return;
  }

  let jwtDataResp;
  try {
    jwtDataResp = yield call(fetchJwtData, jwt);
  }
  catch (error) {
    const errorCode = error.response.status;
    if (
      isServerError(errorCode) ||
      isServerErrorNotFound(errorCode) ||
      isServerErrorForbidden(errorCode) ||
      isServerErrorNotAuth(errorCode)
    ) {
      yield put(logoutThunk());
      return;
    }
  }

  if (jwtDataResp && jwtDataResp.data) {
    yield put(updateAuthData(jwt, jwtDataResp.data, refresh));
    isAppInitialized = true;
    yield put(initApp({forceImportDataOO: true}));
    yield put(push('/'));
    yield put(userLogin());
  }

}

export default function*() {
  yield all([takeEvery(LOCATION_CHANGE, setTokensFromUrl)]);
}
