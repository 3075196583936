import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import { Input as ReactstrapInput } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './style.module.scss';

class Input extends React.PureComponent {
  onSearch = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.onSearch && this.props.onSearch(this.props.value);
    return false;
  };

  onKeyPress = (event) => {
    if (this.props.icon === 'search' && event.key === 'Enter') {
      return this.onSearch(event);
    }
  };

  handleRef = el => this.inputRef = el;

  render = () => this.renderWithProps(this.props);

  renderWithProps = ({
    className,
    children,
    inline,
    centerText,
    icon,
    onSearch,
    errorClassName,
    errorText,
    ...props
  }) => (
    <div
      className={CN([
        props.className,
        styles.container,
        { [styles.inline]: inline },
      ])}
    >
      <ReactstrapInput
        className={CN([
          className,
          styles.input,
          { [styles.inline]: inline },
          { [styles.centerText]: centerText },
        ])}
        onChange={this.props.onChange}
        onKeyPress={this.onKeyPress}
        ref={this.handleRef}
        {...props}
      >
        {children}
      </ReactstrapInput>
      {icon === 'search' && (
        <button
          className={styles.button}
          rel="search"
          onClick={this.onSearch}
        />
      )}
      {errorText !== null && (
        <span className={CN(styles.error, errorClassName)}>{ errorText }</span>
      )}
    </div>
  );
}

Input.propTypes = {
  icon: PT.oneOf(['search']),
  onChange: PT.func,
  onSearch: PT.func,
  inline: PT.bool,
  centerText: PT.bool,
  errorText: PT.string,
};

export default Input;
