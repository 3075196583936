export const MS_IN_SECOND = 1000;
export const MS_IN_MINUTE = MS_IN_SECOND * 60;
export const MS_IN_HOUR = MS_IN_SECOND * 60 * 60;
export const MS_IN_DAY = MS_IN_SECOND * 60 * 60 * 24;
export const FILTER_APPLY_DELAY_MS = MS_IN_SECOND / 2;
export const POPUP_TRESHOLD_MS = MS_IN_SECOND / 2;
export const VEHILCE_ACTIVITY_REFRESH_INTERVAL_MS = MS_IN_MINUTE;
export const VEHICLE_POPUP_CACHE_PERIOD = MS_IN_MINUTE;
export const IMPORT_DATA_OO_CHECK_INTERVAL_MS = 5 * MS_IN_SECOND;
export const SERVER_ERRORS_FIRST_CODE = 500;
export const SERVER_ERRORS_NOT_AUTH = 401;
export const SERVER_ERRORS_FORBIDDEN = 403;
export const SERVER_ERRORS_NOT_FOUND = 404;
export const SQUARE_METERS_IN_HECTARES = 10000;
export const LOCAL_STORAGE_KEY_PREFIX = 'agro-';

export const UPDATE_RFID_DATA_INTERVAL_MS = 5 * MS_IN_MINUTE;

export const OMNICOMM_HELP_URL = '//doc.omnicomm.ru/ru/omnicomm_agro-manual';

export const AUTH_TOKEN_NAME = 'Authorization';
export const AUTH_TOKEN_VALUE_TPL = 'Bearer {token}';
export const AUTH_TOKEN_REFRESH_SECONDS_LEFT = 5 * 60;
export const AUTH_TOKEN_REFRESH_CHECK_INTERVAL = 5;

export const API_URL_BASE_PATH = '/api/internal/v1';
export const API_URL_JWT_ME = `${API_URL_BASE_PATH}/jwt/me`;

export const OMNICOMM_BASE_URL = 'https://online.omnicomm.ru';
export const OMNICOMM_LOGOUT_URL = `${OMNICOMM_BASE_URL}/auth/logout`;
export const AUTH_REFRESH_URL = `${OMNICOMM_BASE_URL}/auth/refresh`;
export const HIDE_ERROR_POPUP_FOR_URLS = ['vehicle/activity'];

export const STAY_IF_MIN_SEC = 60;
export const MAX_SIMULTANEOUS_DOWNLOADS = 10;

export const DRIVEN_TRAILER_COLOR = 'green';
export const TRACK_COLOR = '#0088ff';
export const TRACK_FREQ_DIVIDER = 8;
export const TRACK_FREQ_DIRECTION_TRASHHOLD = 8;

// const EVENT_TYPE_REGISTER_DRIVER = 9;  // Событие Смена водителя - регистрация
// const EVENT_TYPE_UNREGISTER_DRIVER = 53;  // Событие Смена водителя - завершение регистрации
// const EVENT_TYPE_REGISTER_HIRED_DRIVER = 55;  // Событие Смена водителя - регистрация уволенного

export const AGRO_EVENTS = {
    9: 'Смена водителя - регистрация',
    53: 'Смена водителя - завершение регистрации',
    55: 'Смена водителя - регистрация уволенного',
};


export const REPORTS_MAX_VALID_SPEED = 200;
export const REPORTS_AFTER_CHECK_RESULT_DELAY = 2000;
export const REPORT_FACT_WORKS_SELECTED_COLOR = '#00afec';

export const CSV_EXPORT_MIME = 'text/csv;charset=utf-8';
export const CSV_EXPORT_BOM = '\ufeff';
export const CSV_EXPORT_SEPARATOR = ';';
export const REPORTS_CSV_EXPORT_FILENAME = 'report.csv';

export const FILTERS_ENTITIES_TYPES_WITH_LIST = ['work_type'];

const CB_GREEN = '#72a29f';
const CB_YELLOW = '#f5812f';
const CB_RED = '#ec5d63';
const CB_GRAY = '#c1cbd3';
const CB_DARKBLUE = '#002854';
export const VEHICLE_ACTIVITY_GRADUATIONS = [
  { maxTimeMs: MS_IN_MINUTE * 30, color: CB_GREEN },
  { maxTimeMs: MS_IN_DAY, color: CB_YELLOW },
  { maxTimeMs: Infinity, color: CB_RED },
]
export const NULL_ACTIVITY_COLOR = CB_GRAY;

export const ACTIVITY_FILTERS = [
  { name: 'actual', color: CB_GREEN },
  { name: 'outdated', color: CB_YELLOW },
  { name: 'stale', color: CB_RED },
  { name: 'unknown', color: CB_GRAY },
  { name: 'all', color: CB_DARKBLUE },
];

export const ALL_ACTIVITY_FILTER =
  ACTIVITY_FILTERS[ACTIVITY_FILTERS.length - 1].name;

export const DEFAULT_TILES = 'googleSatellite';

export const WEATHER_API_KEY = '57301e220fac830efb70b086102245d5';
