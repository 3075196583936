import { LOCAL_STORAGE_KEY_PREFIX, MS_IN_DAY } from 'constants/index.js';

const KEY = `${LOCAL_STORAGE_KEY_PREFIX}-period`;

const initialState = {
  from: new Date().setHours(0, 0, 0, 0),
  to: new Date().setHours(0, 0, 0, 0) + MS_IN_DAY - 1,
};

export const loadState = () => {
  const savedState = localStorage.getItem(KEY);
  return JSON.parse(savedState) || initialState;
};

export const saveState = state => {
  localStorage.setItem(KEY, JSON.stringify(state.toJS()));
};
