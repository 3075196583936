import React from 'react';
import { connect } from 'react-redux';
import List from 'components/ui/List';
import { getListItemsIdsSelector } from 'selectors/filters';

class ListContainer extends React.Component {
  // prevent refresh list
  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps.items) !== JSON.stringify(this.props.items);
  }

  render() {
    return (
      <List {...this.props} />
    )
  }
}

const mapStateToProps = (state, {listName}) => ({
  items: getListItemsIdsSelector(state, listName),
});

export default connect(mapStateToProps)(ListContainer);
