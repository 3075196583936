import { connect } from 'react-redux';
import { logoutThunk, userLogout } from 'actions/common';
import MainMenu from 'components/ui/MainMenu';
import { getCurrentUserName } from 'selectors/common';

const mapStateToProps = (state, props) => ({
  username: getCurrentUserName(state),
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch(userLogout());
    dispatch(logoutThunk());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);
