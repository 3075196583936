import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import styles from './style.module.scss';

class SidebarTab extends React.PureComponent {
  // onClick = () => e => {
  //   this.props.onClick && this.props.onClick(this.props.index);
  // };

  render = () => (
    <div
      className={styles.sidebarItem}
      // onClick={this.onClick()}
    >
      <div title={this.props.title}>
        <div className={styles.iconContainer}>
          <div className={CN(styles.icon, styles[this.props.icon])} />
        </div>
        <span className={styles.label}>{this.props.label}</span>
      </div>
    </div>
  );
}

SidebarTab.propTypes = {
  title: PT.string,
  icon: PT.oneOf(['vehicles', 'trailer', 'territory', 'work_type']),
  label: PT.string,

  // index: PT.number.isRequired,
  // selected: PT.bool,
  // onClick: PT.func,
};

export default SidebarTab;
