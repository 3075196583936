import Immutable from 'immutable';
import * as actionsTypes from 'constants/actionTypes.js';
import { MS_IN_SECOND } from 'constants/index';

const initialState = Immutable.fromJS({
  activity: {},
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionsTypes.VEHICLE_ACTIVITY_FETCH_SUCCESS:
      const activity = Immutable.Map(
        payload.data.map(v => [
          v.uuid,
          { timestamp: v.time * MS_IN_SECOND },
        ])
      );
      return state.set('activity', activity);

    default:
      return state;
  }
};
