import { connect } from 'react-redux';
import Panel from 'components/filters/trailer/Panel';
import { updateTreeFilter } from 'actions/filters';
import { filterTree, dropTreeNode, dropTreeFolderNode } from 'actions/filters';
import { newFolder, editFolder, newTrailer, editTrailer } from 'actions/trailer/index';
import { showModal, loadEntity, loadList } from 'actions/common';
import toJS from 'helpers/tojs';
// import { getTrailerPopupProp } from 'selectors/trailer';
import {getTreeItemsMap, getTreeSearchValue} from 'selectors/filters';
import { getSelectedTreeNode } from 'selectors/filters';
import { isTreeNodeSelected } from 'selectors/filters';

const mapStateToProps = (state, props) => ({
  searchValue: getTreeSearchValue(state, 'trailer'),
  isFolderSelected: isTreeNodeSelected(state, 'trailer', false),
  isNodeSelected: isTreeNodeSelected(state, 'trailer', true),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSearchChange: (event) => {
      dispatch(updateTreeFilter('trailer', event.target.value));
    },
    onSearchStart: (searchValue) => {
      dispatch(filterTree('trailer', searchValue));
    },
    onNewFolder: () => {
      dispatch(newFolder());
    },
    onEditFolder: () =>
      dispatch((dispatch, getState) => {
        const item = getSelectedTreeNode(getState(), 'trailer').toJS();
        dispatch(loadEntity('trailer', 'folder', item.id));
        dispatch(editFolder(item));
      }),
    onDeleteFolder: () => {
      dispatch(
        showModal(
          'trailer_folder_delete',
          'Удалить папку?',
          'Подтвердите действие',
          true
        )
      );
    },
    onNewItem: () => {
      dispatch(loadList('trailer', 'work_type'));
      dispatch(newTrailer());
    },
    onEditItem: () =>
      dispatch((dispatch, getState) => {
        const item = getSelectedTreeNode(getState(), 'trailer').toJS();
        dispatch(loadList('trailer', 'work_type'));
        dispatch(loadEntity('trailer', 'trailer', item.id));
        dispatch(editTrailer(item));
      }),
      onDrag: (fromId, toId, fromIsFolder, toIsFolder) => {
        dispatch((dispatch, getState) => {
          const tree = getTreeItemsMap(getState(), 'trailer');
          const fromItem = tree.find(({id, isFolder}) => id === Number(fromId) && !!isFolder === !!fromIsFolder);
          const toItem = tree.find(({id, isFolder}) => id === Number(toId) && !!isFolder === !!toIsFolder);
          let entityId = fromItem.id;
          let oldFolderId = fromItem.parent;
          let newFolderId = toItem.id;
          if (!toItem.isFolder) {
            newFolderId = toItem.parent;
          }
          const parents = [];
          let curFolderId = newFolderId;
          while(curFolderId) {
            const item = tree.find(({id, isFolder}) => id === Number(curFolderId) && isFolder);
            if (!item) {
              break;
            }
            parents.push(item.id);
            curFolderId = item.parent;
          }
          if (parents.find(id => entityId === id)) {
            return;
          }

          if (fromItem.isFolder) {
            dispatch(dropTreeFolderNode('trailer', entityId, newFolderId));
          } else {
            dispatch(dropTreeNode('trailer', entityId, oldFolderId, newFolderId));
          }
        });
      }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Panel));
