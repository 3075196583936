import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import {Then, Else} from 'react-if';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import styles from './style.module.scss';
import IfReportLoading from 'containers/features/reports/IfReportLoading';
import IfReportImportRunningProgress from "containers/features/reports/IfReportImportRunningProgress";
import FactReportMap from 'containers/features/reports/FactReportsMap';
import Pagination from 'containers/features/reports/ReactTable/Pagination';
import {displayIfEquals, sec2HumanFormat, sec2ExcelFormat, formatDate} from 'helpers/reports';
import {saveJSON, loadJSON} from "helpers/utils";
import {REPORT_FACT_WORKS_SELECTED_COLOR} from 'constants/index.js';
import Popup from "components/ui/Popup";


const reportName = 'factWorksReport';

const prepareExportRow = (item) => {
  return [
    formatDate(item['start_date']),
    formatDate(item['finish_date']),
    item['vehicle_name'],
    item['geozone_name'],
    item['gezone_area'],
    item['work_type_name'],
    item['trailer_name'],
    item['driver_name'],
    item['processed_area'],
    item['overlap_percent'],
    item['working_speed_avg'],
    sec2ExcelFormat(item['working_time'] * 3600),
    sec2ExcelFormat(item['stay_time'] * 60),
    item['work_fuel'],
    item['stay_fuel'],
    item['fuel_data_status_title'],
  ]
};

const exportTableHeaders = [
  'Дата и время начала работы',
  'Дата и время завершения работы',
  'Наименование ТС',
  'Номер или наименование поля/кадастрового участка',
  'Площадь поля/кадастрового участка (га)',
  'Вид работы',
  'Навесное оборудование',
  'Водитель',
  'Обработанная фактическая площадь (га)',
  'Процент перекрытия при обработке',
  'Средняя скорость обработки фактической площади без учета стоянок (км/час)',
  'Время, затраченное на обработку фактической площади, без учета стоянок (час)',
  'Суммарное время стоянок (мин)',
  'Количество топлива, затраченное на обработку фактической площади (л)',
  'Количество топлива, затраченное во время стоянок (л)',
  'Статус ДУТ',
];

const nullValue = <Popup container={<div className={styles.nullValuePopup}>-</div>}
                         popup={<div>Проверьте, привязано ли навесное оборудование к ТС и<br />указан ли вид работ у
                           навесного оборудования за отчетный период</div>} />;

const LOCALSTORGE_KEY = `${reportName}:hidden:columns`;
const saveHiddenColumns = saveJSON(LOCALSTORGE_KEY);
const loadHiddenColumns = loadJSON(LOCALSTORGE_KEY, []);

const removeHiddenColumns = (columns, hiddenColumns) =>
  columns
    .map(({ columns, ...rest }) => {
      if (columns) {
        return {
          ...rest,
          columns: removeHiddenColumns(columns, hiddenColumns),
        };
      }
      return rest;
    })
    .filter(({ columns, accessor }) => 
      (
        (!columns && hiddenColumns.indexOf(accessor) < 0) ||
        (columns && columns.length > 0)
      )
    );

const getPaginationColumns = (columns, hiddenColumns) => {
  return columns
    .filter(({show}) => show !== false)
    .map(({ Header, accessor, columns }) => {
      if (!columns) {
        return {
          Header,
          accessor,
          visible: hiddenColumns.findIndex(a => a === accessor) < 0,
        };
      }
      return getPaginationColumns(columns, hiddenColumns);
    })
    .flat();
};

class Page extends React.PureComponent {

  state = {
    hiddenColumns: loadHiddenColumns(),
    defaultPopupOpen: false,
  };

  handleToggleColumn = (accessor, visible) => {
    const hiddenColumns = visible
      ? this.state.hiddenColumns.filter(a => a !== accessor)
      : [...this.state.hiddenColumns, accessor];
    this.setState({ hiddenColumns });
    saveHiddenColumns(hiddenColumns);
  };

  handleToggleOpened = (opened) => {
    console.log("handleToggleOpened", opened);
    this.setState({ defaultPopupOpen: opened });
  };

  render = () => {
    let data = this.props.data;

    let columns = [
      {
        accessor: 'geozone_id',
        show: false,
      },
      {
        Header: "Дата и время",
        columns: [
          {
            Header: 'Начало',
            // Header: (props) => <div>{'Началоъ'}{JSON.stringify(props)}</div>,
            accessor: 'start_date',
            Cell: props => formatDate(props.value),
          },
          {
            Header: 'Окончание',
            accessor: 'finish_date',
            Cell: props => formatDate(props.value),
          },
        ]
      },
      {
        Header: 'Наименование ТС',
        accessor: 'vehicle_name',
      },
      {
        Header: "Участок/поле",
        columns: [
          {
            Header: 'Номер/название',
            accessor: 'geozone_name',
          },
          {
            Header: 'Площадь, Га',
            accessor: 'gezone_area',
            className: styles.cell_number,
          },
        ]
      },
      {
        Header: 'Виды работ',
        accessor: 'work_type_name',
        style: {'whiteSpace': 'unset'},
        Cell: props => displayIfEquals(props.value, null, nullValue),
      },
      {
        Header: 'Оборудование',
        accessor: 'trailer_name',
        style: {'whiteSpace': 'unset'},
        Cell: props => displayIfEquals(props.value, null, nullValue),
      },
      {
        Header: 'Водитель',
        accessor: 'driver_name',
        Cell: props => displayIfEquals(props.value, null, nullValue),
      },
      {
        Header: 'Фактически обработанно, Га',
        accessor: 'processed_area',
        className: styles.cell_number,
        Cell: props => displayIfEquals(props.value, null, nullValue),
      },
      {
        Header: 'Процент перекрытия',
        accessor: 'overlap_percent',
        className: styles.cell_number,
        Cell: props => displayIfEquals(props.value, null, nullValue),
      },
      {
        Header: "Движение",
        columns: [
          {
            Header: 'Скорость средняя, км/ч',
            accessor: 'working_speed_avg',
            className: styles.cell_number,
            Cell: props => displayIfEquals(props.value, 0, '-'),
          },
          {
            Header: 'Время',
            accessor: 'working_time',
            className: styles.cell_number,
            Cell: props => sec2HumanFormat(props.value * 3600),
          },
          {
            Header: 'Топливо, л',
            accessor: 'work_fuel',
            className: styles.cell_number,
            Cell: props => (props.original.fuel_data_status === 'empty') ? displayIfEquals(props.value, 0, '-') : props.value,
          },]
      },
      {
        Header: "Стоянка",
        columns: [
          {
            Header: 'Время',
            accessor: 'stay_time',
            className: styles.cell_number,
            Cell: props => sec2HumanFormat(props.value * 60),
          },
          {
            Header: 'Топливо, л',
            accessor: 'stay_fuel',
            className: styles.cell_number,
            Cell: props => (props.original.fuel_data_status === 'empty') ? displayIfEquals(props.value, 0, '-') : props.value,
          },
        ]
      },
      {
        Header: "Статус ДУТ",
        accessor: 'fuel_data_status_title',
        className: styles.cell_number,
      },
    ];

    columns.forEach(function (item) {
      item.headerStyle = {
        "whiteSpace": "normal",
      };
    });

    const visibleColumns = 
      removeHiddenColumns(columns, this.state.hiddenColumns)
      .map(props => ({headerStyle: {whiteSpace: "normal"}, ...props}))

    return (
      <div className={styles.wrapper}>
        <ReactTable
          className={CN([styles.table, "-striped", "-highlight"])}
          data={data}
          loading={this.props.loading || this.props.runningImport}
          columns={visibleColumns}
          defaultSorted={[
            {
              id: "start_at",
              desc: false,
            }
          ]}
          pageSizeOptions={[10, 20, 50, 100]}
          minRows={0}
          previousText="Предыдущая"
          nextText="Следующая"
          loadingText={this.props.runningImport && this.props.progress !== null ? `Подождите, синхронизируются данные. ${this.props.progress}%` : "Загрузка..."}
          noDataText="Данных не найдено"
          pageText="Страница"
          ofText="из"
          rowsText="строк"
          PaginationComponent={props => (
            <Pagination
              {...props}
              reportName={reportName}
              prepareRow={prepareExportRow}
              columns={getPaginationColumns(columns, this.state.hiddenColumns)}
              defaultPopupOpen={this.state.defaultPopupOpen}
              onToggleColumn={this.handleToggleColumn}
              exportHeaders={exportTableHeaders}
              onTogglePopup={this.handleToggleOpened}
            />
          )}
          getTrProps={(state, rowInfo, column) => ({
            onClick: () => {
              if (this.props.onRowClick) {
                this.props.onRowClick(rowInfo.index, parseInt(rowInfo.row.geozone_id, 10));
              }
            },
            style: {
              background: rowInfo.index === this.props.selectedIndex ? REPORT_FACT_WORKS_SELECTED_COLOR : 'white',
            }
          })} />
        <IfReportImportRunningProgress report={'factWorksReport'}>
            <IfReportLoading report={'factWorksReport'}>
              <Then>{() => <div className={styles.mapLoading}>Загрузка</div>}</Then>
              <Else>
                {() => (
                  <FactReportMap className={styles.map} fitToBounds />
                )}
              </Else>
            </IfReportLoading>
        </IfReportImportRunningProgress>
      </div>
    )
  };
}

Page.propTypes = {
  data: PT.array,
  loading: PT.bool,
  selectedIndex: PT.number,
  onRowClick: PT.func,
};

Page.defaultProps = {
  selectedIndex: -1,
};

export default Page;
