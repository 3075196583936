import { connect } from 'react-redux';
import { closeWorkTypePopup } from 'actions/WorkType';
import { storeEntity } from 'actions/common';
import WorkTypePopup from 'components/features/WorkType/Popup';
import { getWorkTypePopupProp } from "selectors/WorkType";
import toJS from 'helpers/tojs';

const mapStateToProps = (state, props) => ({
  isDisplayed: getWorkTypePopupProp(state, 'isDisplayed'),
  isCreate: getWorkTypePopupProp(state, 'isCreate'),
  data: getWorkTypePopupProp(state, 'data'),
});

const mapDispatchToProps = dispatch => ({
  onOk: async (isDisplayed, data) => {
    return await dispatch(storeEntity('trailer', 'work_type', data));
  },
  onClose: () => dispatch(closeWorkTypePopup()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(WorkTypePopup));
