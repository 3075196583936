import { connect } from 'react-redux';
import Tree from 'components/ui/Tree';
import { getVisibleConvertedTree } from 'selectors/filters';
import toJS from 'helpers/tojs';

const mapStateToProps = (state, {treeName}) => ({
  tree: getVisibleConvertedTree(state, treeName, true),
});

export default connect(mapStateToProps)(toJS(Tree));
