import React from 'react';
import PT from 'prop-types';
import Input from 'components/ui/Input';
import IconButton from 'components/ui/IconButton';
import HR from 'components/ui/HR';
import VR from 'components/ui/VR';
import Tree from 'containers/filters/Tree';
import TreeNode from 'containers/filters/TreeNode';
import TreeRootCheckbox from 'containers/filters/TreeRootCheckbox';
import styles from './style.module.scss';
import ReactResizeDetector from 'react-resize-detector';
import {resizeLeftPanelList} from 'appEvents';

export class Panel extends React.Component {

  render = () => {
    const props = this.props;
    return (
      <div className={styles.container}>
        <Input
          placeholder="Найти участок..."
          icon="search"
          value={this.props.searchValue}
          onChange={this.props.onSearchChange}
          onSearch={this.props.onSearchStart}
        />
        <div className={styles.buttonsContainer}>
          <TreeRootCheckbox className={styles.checkbox} treeName="territory" />
          <VR/>
          <IconButton icon="item_add" title="Добавить геозону" onClick={props.onNewItem} />
          <IconButton icon="item_edit" disabled={!props.isNodeSelected} title="Редактировать геозону" onClick={props.onEditItem} />
        </div>
        <HR />
        <div className={"leftPanelList"}>
          <ReactResizeDetector handleHeight onResize={ () => {resizeLeftPanelList()} } />
          <Tree
            treeName="territory"
            renderNode={(id, keyPath, title, isLeaf, parentId) => (
              <TreeNode
                treeName="territory"
                id={id}
                keyPath={keyPath}
                title={title}
                leaf={isLeaf}
                parentId={parentId}
              />
            )}
          />
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  isFolderSelected: PT.bool,
  isNodeSelected: PT.bool,
  searchValue: PT.string,
  onSearchChange: PT.func.isRequired,
  onSearchStart: PT.func.isRequired,
  onNewItem: PT.func,
  onEditItem: PT.func,
};

export default Panel;
