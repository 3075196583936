import * as actionsTypes from 'constants/actionTypes.js';

export const closeTerritoryPopup = () => ({
  type: actionsTypes.TERRITORY_POPUP_CLOSE,
  payload: {},
});


export const newTerritory = () => ({
  type: actionsTypes.TERRITORY_NEW,
  payload: { },
});

export const editTerritory = () => ({
  type: actionsTypes.TERRITORY_EDIT,
  payload: {},
});


export const closeTerritoryTreeFolderPopup = () => ({
  type: actionsTypes.TERRITORY_TREE_FOLDER_POPUP_CLOSE,
  payload: {},
});

export const newFolder = () => ({
  type: actionsTypes.TERRITORY_FOLDER_NEW,
  payload: { },
});

export const editFolder = (data) => ({
  type: actionsTypes.TERRITORY_FOLDER_EDIT,
  payload: { data },
});


export const addSeason = (data) => ({
  type: actionsTypes.SEASON_CREATE,
  payload: {
    request: {
      method: 'POST',
      url: `/territory/season`,
      data: data,
    }
  },
});


export const updateSeason = (data) => ({
  type: actionsTypes.SEASON_UPDATE,
  payload: {
    request: {
      method: 'PUT',
      url: `/territory/season`,
      data: data,
    }
  },
});

export const deleteSeason = (id) => ({
  type: actionsTypes.SEASON_DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: `/territory/season/${id}`,
    },
    id
  },
});

export const updateTerritoryData = (id) => ({
  type: actionsTypes.TERRITORY_UPDATE_DATA,
  payload: {
    request: {
      url: `/territory/territory/${id}`,
    },
  },
});
