import Immutable from 'immutable';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

const getAccessToken = state => state.getIn(['auth', 'accessToken']);
export const getCurrentUser = state => state.getIn(['auth', 'currentUser']);
export const getAppConfig = state => state.getIn(['auth', 'appConfig']);
export const getAppConfigKey = (state, key) => state.getIn(['auth', 'appConfig', key]);

export const getCurrentUserName = createSelector(
  [getCurrentUser],
  (user = Immutable.Map()) => user.get('name')
);

export const getDictionary = (state, prop) => state.getIn(['dictionary', prop]);

export const getDisplayedModals = state => state.get('modals');

export const getModalProp = createCachedSelector(
  [getDisplayedModals, (state, type) => type, (state, type, prop) => prop],
  (modals, type, prop) =>
    modals.find(modal => modal.get('type') === type).get(prop)
)((state, type, prop) => `${type}:${prop}`);

export const getAccessTokenValue = createSelector(
  [getAccessToken],
  token => token
);

export const getDictionaryPropByProp = createCachedSelector(
  [
    (state, dictionary, searchProp, searchValue, prop) => getDictionary(state, dictionary),
    (state, dictionary, searchProp, searchValue, prop) => searchProp,
    (state, dictionary, searchProp, searchValue, prop) => searchValue,
    (state, dictionary, searchProp, searchValue, prop) => prop,
  ],
  (dictionary, searchProp, searchValue, prop) => {
    const index = dictionary.findIndex(item => item.get(searchProp) === searchValue)
    if (index < 0) {
      return undefined;
    }
    return dictionary.getIn([index, prop]);
  }
)((state, dictionary, searchProp, searchValue, prop) => `${dictionary}:${searchProp}:${searchValue}:${prop}`);
