import Immutable from 'immutable';
import { EXAMPLE_DATA_REQUEST } from 'constants/actionTypes.js';
import { EXAMPLE_DATA_REQUEST_SUCCESS } from 'constants/actionTypes.js';
import { EXAMPLE_DATA_REQUEST_FAIL } from 'constants/actionTypes.js';

const initialState = Immutable.fromJS({
  isLoading: false,
  code: null,
});

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case EXAMPLE_DATA_REQUEST:
      return state.set('isLoading', true);
    case EXAMPLE_DATA_REQUEST_SUCCESS:
      const data = payload.data.map(item => item.id).join(', ');
      return state.set('isLoading', false).set('code', data);
    case EXAMPLE_DATA_REQUEST_FAIL:
      return state.set('isLoading', false).set('code', null);
    default:
      return state;
  }
};
