import React from 'react';
import { connect } from 'react-redux';
import If, {Then, Else} from 'react-if';
import { getReportProp } from 'selectors/reports';

const IfIsRunning = (props) => (
  <If condition={props.condition}>
      <Then>{() => <div className={props.className}>{props.progress !== null ? `Подождите, синхронизируются данные. ${props.progress}%` : "Загрузка..."}</div>}</Then>
      <Else>{props.children}</Else>
  </If>
);


const mapStateToProps = (state, props) => ({
  condition: getReportProp(state, props.report, 'runningImport'),
  progress: getReportProp(state, props.report, 'progress'),
});

export default connect(mapStateToProps)(IfIsRunning);
