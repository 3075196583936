import Immutable from 'immutable';
import {LOCAL_STORAGE_KEY_PREFIX} from 'constants/index.js';

const APP_CONFIG = `${LOCAL_STORAGE_KEY_PREFIX}app-config`;
const ACCESS_TOKEN = `${LOCAL_STORAGE_KEY_PREFIX}token-access`;
const REFRESH_TOKEN = `${LOCAL_STORAGE_KEY_PREFIX}token-refresh`;
const TOKEN_EXP = `${LOCAL_STORAGE_KEY_PREFIX}token-exp`;
const TOKEN_REFRESH_RUNNING = `${LOCAL_STORAGE_KEY_PREFIX}token-refresh-running`;
const CURRENT_USER = `${LOCAL_STORAGE_KEY_PREFIX}current_user`;

export const getInitialState = () =>
  Immutable.fromJS({
    jwt: null,
    refresh: null,
  });

export const loadAppConfig = () => {
  try {
    return JSON.parse(loadValue(APP_CONFIG));
  }
  catch(e) {
    return {};
  }
};
export const loadAccessToken = () => loadValue(ACCESS_TOKEN);
export const loadRefreshToken = () => loadValue(REFRESH_TOKEN);
export const loadTokenExp = () => parseInt(loadValue(TOKEN_EXP));
export const loadTokenRefreshRunning = () => parseInt(loadValue(TOKEN_REFRESH_RUNNING));
export const loadCurrentUser = () => JSON.parse(loadValue(CURRENT_USER));

const loadValue = key => localStorage.getItem(key);

export const saveAppConfig = value => saveValue(APP_CONFIG, JSON.stringify(value));
export const saveAccessToken = value => saveValue(ACCESS_TOKEN, value);
export const saveRefreshToken = value => saveValue(REFRESH_TOKEN, value);
export const saveTokenExp = value => saveValue(TOKEN_EXP, value);
export const saveTokenRefreshRunning = value => saveValue(TOKEN_REFRESH_RUNNING, value);
export const saveCurrentUser = value => saveValue(CURRENT_USER, JSON.stringify(value));

export const clearAuthData = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(TOKEN_EXP);
  localStorage.removeItem(CURRENT_USER);
  localStorage.removeItem(APP_CONFIG);
};

const saveValue = (key, value) => localStorage.setItem(key, value);


export function isNeedRefreshToken(seconds_left_for_refresh) {
  const expiredAt = loadTokenExp();
  // expired data not found
  if (!expiredAt) {
    return false;
  }

  // refresh already running
  if (loadTokenRefreshRunning()) {
    return false;
  }

  // check need refresh
  const curTime = Math.round((new Date()).getTime() / 1000);
  const timeToTokenEnd = expiredAt - curTime;
  return timeToTokenEnd < seconds_left_for_refresh;
}
