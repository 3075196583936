import { TaskQueue } from 'cwait';
import * as actionsTypes from 'constants/actionTypes.js';
import { MAX_SIMULTANEOUS_DOWNLOADS, MS_IN_SECOND } from 'constants/index.js';
// import { stripMs } from 'helpers/utils';

export const fetchTerritoryList = () => {
    return {
    type: actionsTypes.TERRITORY_LIST_FETCH,
    payload: {
      request: {
        method: 'GET',
        url: '/territory/all',
      },
    },
  };
};

export const fetchTrailerHistory = (vehicleIds, trailerIds, from, to) => {
  const params = new URLSearchParams();
  vehicleIds.forEach(id => {
    params.append('vehicle_ids', id);
  });
  trailerIds.forEach(id => {
    params.append('trailer_ids', id);
  });
  params.append('from', from);
  params.append('to', to);

  return {
    type: actionsTypes.TRAILER_HISTORY_FETCH,
    payload: {
      request: {
        method: 'GET',
        url: '/trailer/history',
        params,
      },
    },
  };
};

export const updateFactWorksReportData = (prop, data) => {
  return {
    type: actionsTypes.FACT_WORKS_REPORT_DATA_COMPLETED,
    payload: { prop, data },
  };
};

export const updateAgroEventsReportData = (data) => {
  return {
    type: actionsTypes.AGRO_EVENTS_REPORT_DATA_COMPLETED,
    payload: {
      data: data,
    },
  };
};

export const fetchVehicleTrailers = (vehicleUids, from, to) => {
  const data = {
    vehicle_uids: vehicleUids,
    from: from,
    to: to,
  };

  return {
    type: actionsTypes.VEHICLE_TRAILERS_FETCH,
    payload: {
      request: {
        method: 'POST',
        url: '/vehicle/trailers',
        data: data,
      },
    },
  };
};

export const fetchVehicleCurrentTrailer = (id) => {
  const timestamp = new Date().getTime();
  const data = {
    vehicle_uids: [id],
    from: timestamp,
    to: timestamp,
  };

  return {
    type: actionsTypes.VEHICLE_CURRENT_TRAILER_FETCH,
    payload: {
      request: {
        method: 'POST',
        url: '/vehicle/trailers',
        data: data,
      },
    },
  };
};

export const fetchVehicleEvents = (vehicleId, from, to) => {
  const params = new URLSearchParams();
  params.append('from', from);
  params.append('to', to);

  return {
    type: actionsTypes.VEHICLE_EVENTS_FETCH,
    payload: {
      request: {
        method: 'GET',
        url: `/vehicle/${vehicleId}/events`,
        params,
      },
    },
  };
};


export const fetchVehicleActivity = () => {
  return {
    type: actionsTypes.VEHICLE_ACTIVITY_FETCH,
    payload: {
      request: {
        method: 'GET',
        url: `/vehicle/activity`,
      },
    },
  };
};
/*
export const fetchVehicleActivity = () => async (dispatch) => {
  const dummyResponse = [
    {
      "time": (new Date().getTime() - 45 * 1000) / 1000,
      "uuid": "377573ca-df13-38fc-b9db-5eab604c17c3"
    },
    {
      "time": null,
      "uuid": "995df284-b288-371d-9c5d-7fb4cc85c096"
    },
    {
      "time": (new Date().getTime() - 120 * 1000) / 1000,
      "uuid": "1bd5432d-26f2-360a-bec3-6e4b437e39d6"
    },
    {
      "time": (new Date().getTime() - 3600 * 1000) / 1000,
      "uuid": "41cac64b-6a63-3770-8fbb-5f4d8ecc46f2"
    },
  ];

  setTimeout(() => {
    dispatch({
      type: actionsTypes.VEHICLE_ACTIVITY_FETCH_SUCCESS,
      payload: { data: dummyResponse }
    });
  }, 1000);
};
*/

export const fetchVehiclesDetailsThunk = (ids) => async (dispatch) => {
  const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
  const responces = await Promise.all(
    ids.map(queue.wrap(async id => {
      let stateResponse = await dispatch(fetchVehicleState(id));
      let trailerResponse = await dispatch(fetchVehicleTrailer(id));
      const error = stateResponse.error || trailerResponse.error;
      if (error) {
        throw error;
      }
      let location = stateResponse.payload.data || {};
      let trailer = trailerResponse.payload.data || {};
      location.id = id; // add vehicle id to response data
      if (trailer.trailer && trailer.trailer.id) {
        location.trailerId = trailer.trailer.id;
      }
      return location;
    }))
  );
  return responces;
};

export const removeVehiclesDetails = (ids) => ({
  type: actionsTypes.VEHICLES_DETAILS_REMOVE,
  payload: {
    ids,
  },
})

export const removeVehiclesTracks = (ids) => ({
  type: actionsTypes.VEHICLES_TRACKS_REMOVE,
  payload: {
    ids,
  },
})

export const fetchVehicleState = id => ({
  type: actionsTypes.VEHICLE_STATE_FETCH,
  payload: {
    request: {
      method: 'GET',
      url: `/vehicle/${id}/state`,
    },
  },
});

export const fetchVehicleTrailer = id => ({
  type: actionsTypes.VEHICLE_TRAILER_FETCH,
  payload: {
    request: {
      method: 'GET',
      url: `/vehicle/${id}`,
    },
  },
});

export const receiveVehiclesDetails = vehicles => ({
  type: actionsTypes.VEHICLES_DETAILS_RECEIVE,
  payload: {
    vehicles,
  },
});

export const fetchVehicleTrack = (id, from, to, extended = false) => {

  const params = new URLSearchParams();
  params.append('from', from);
  params.append('to', to);

  const url_method = (extended) ? 'track_extended' : 'track';

  if (extended) {
    params.append('with_offset', '1');
    params.append('compensation', '1');
  }

  return {
    type: actionsTypes.TRACK_VEHICLE_FETCH,
    payload: {
      request: {
        method: 'GET',
        url: `/vehicle/${id}/${url_method}`,
        params: params
      },
    },
  };
};

export const fetchVehiclesTracks = (ids, from, to, extended = false) => async (dispatch) => {
  const tracks = {};
  const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
  const responces = await Promise.all(
    ids.map(
      queue.wrap(async id => {
        let response = await dispatch(
          fetchVehicleTrack(id, from, to, extended)
        );
        if (response.error) {
          throw response.error;
        }
        let track = (response.payload || {}).data || [];

        // transform extended track data
        if (extended) {
          track = track.map((item) => ({
            'date': item[0],
            'latitude': item[1],
            'longitude': item[2],
            'fuel': item[3],
          }));
        }

        // @todo: move to backend
        return track.map(item =>
          item.date ? { ...item, date: item.date * MS_IN_SECOND } : item
        );
      })
    )
  );
  // convert array to object
  responces.map((item, index) => tracks[ids[index]] = item);
  return tracks;
};

export const receiveVehiclesTracks = tracks => ({
  type: actionsTypes.VEHICLES_TRACKS_RECEIVE,
  payload: {
    tracks,
  },
});

export const receiveVehicleFuel = tracks => ({
  type: actionsTypes.VEHICLE_FUEL_RECEIVE,
  payload: {
    tracks,
  },
});

export const toggleReportLoading = (report, loading) => ({
  type: actionsTypes.REPORT_LOADING_TOGGLE,
  payload: {
    report, loading
  },
});

export const toggleReportId = (report, reportTaskId) => ({
  type: actionsTypes.REPORT_ID_TOGGLE,
  payload: {
    report, reportTaskId
  },
});

export const toggleReportGeozonesImportRunning = (report, running, progress) => ({
  type: actionsTypes.REPORT_IMPORT_RUNNING_TOGGLE,
  payload: {
    report, running, progress
  },
});

export const fetchVehicleFilter = (from, to, vehicleUids, geozonesIds) => {
  const params = new URLSearchParams();
  params.append('from', from);
  params.append('to', to);
  vehicleUids.forEach(id => {
    params.append('vehicle_uids', id);
  });
   geozonesIds.forEach(id => {
    params.append('territory_ids', id);
  });

  return {
    type: actionsTypes.VEHICLE_FILTER_FETCH,
    payload: {
      request: {
        method: 'GET',
        url: '/vehicle/filter',
        params,
      },
    },
  };
};

export const mapTilesChanged = (report, tiles) => ({
  type: actionsTypes.MAP_TILES_CHANGED,
  payload: {
    report, tiles
  },
});

export const createReportFactWorkRequest = (from, to, vehicleIds, geozonesIds) => {
  const data = {
    vehicle_ids: vehicleIds,
    from: from,
    to: to,
    territory_ids: geozonesIds,
  };

  return {
    type: actionsTypes.REPORT_FACT_WORK_REQUEST,
    payload: {
      request: {
        method: 'POST',
        url: '/reports/request/fact_work',
        data: data,
      },
    },
  };
};

export const revokeFactWorksReport = (taskId) => {
  return {
    type: actionsTypes.REVOKE_REPORT_FACT,
    payload: {
      request: {
        method: 'GET',
        url: `/reports/revoke_request/${taskId}`,
      },
    },
  };
};


export const checkReportFactWorkResult = (requestId) => {
  return {
    type: actionsTypes.REPORT_FACT_WORK_RESULT_CHECK,
    payload: {
      request: {
        method: 'GET',
        url: `/reports/request/${requestId}`
      },
    },
  };
};

export const fetchVehicleDrivers = (vehicleUids, from, to) => {
  const data = {
    vehicle_uids: vehicleUids,
    from: from,
    to: to,
  };

  return {
    type: actionsTypes.VEHICLE_DRIVERS_FETCH,
    payload: {
      request: {
        method: 'POST',
        url: '/driver/vehicle_driver/list',
        data: data,
      },
    },
  };
};
