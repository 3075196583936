import React from 'react';
import PT from 'prop-types';
import { When } from 'react-if';
import styles from './style.module.scss';

const NULL_VALUE = '- - -';

class VehiclePopup extends React.PureComponent {
  render = () => (
    <div className={styles.container}>
      <div className={styles.header}>{this.props.vehicleName}</div>
      <div className={styles.coordinates}>
        {this.props.latitude}, {this.props.longitude}
      </div>

      <When condition={Boolean(this.props.driverName)}>
        <div>
          <div className={styles.label}>Водитель:</div>
          <div className={styles.value}>{this.props.driverName}</div>
        </div>
      </When>

      <div className={styles.twoColumns}>
        <div>
          <div className={styles.label}>Скорость:</div>
          <div className={styles.value}>{this.props.speed} км/ч</div>
        </div>
        <div>
          <div className={styles.label}>Топливо:</div>
          <div className={styles.value}>{this.props.fuel} л.</div>
        </div>
      </div>
      <When condition={Boolean(this.props.territoryName)}>
        <div>
          <div className={styles.label}>Поле:</div>
          <div className={styles.value}>{this.props.territoryName}</div>
        </div>
      </When>
      <When condition={Boolean(this.props.trailerName)}>
        <div>
          <div className={styles.label}>Навесное оборудование:</div>
          <div className={styles.value}>{this.props.trailerName}</div>
        </div>
      </When>
    </div>
  );
}

VehiclePopup.propTypes = {
  vehicleName: PT.string,
  driverName: PT.string,
  territoryName: PT.string,
  trailerName: PT.string,
  latitude: PT.number,
  longitude: PT.number,
  speed: PT.number,
  fuel: PT.number,
};

VehiclePopup.defaultProps = {
  vehicleName: NULL_VALUE,
  driverName: NULL_VALUE,
  latitude: NULL_VALUE,
  longitude: NULL_VALUE,
  speed: NULL_VALUE,
  fuel: NULL_VALUE,
};

export default VehiclePopup;
