import Immutable from 'immutable';
import { FETCH_GEOZONES_IMPORT_STATUS_SUCCESS } from 'constants/actionTypes.js';

const initialState = Immutable.fromJS({
  isRunning: true,
  processed: null,
  total: null,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case FETCH_GEOZONES_IMPORT_STATUS_SUCCESS:
      return state
        .set('isRunning', payload.data.is_running)
        .set('processed', payload.data.processed)
        .set('total', payload.data.total);

    default:
      return state;
  }
};
