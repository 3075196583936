import React from 'react';
import PT from 'prop-types';
import styles from './style.module.scss';

const renderNode = (id, keyPath, title, isLeaf, parentId) => (
  <span path={keyPath.join('.')}>{title}</span>
);

const getKeyPath = (keyPath, index) =>
  keyPath.concat(['children', index]);

class Tree extends React.PureComponent {
  render = () => (
    <div className={styles.treeContainer}>
      <ul
        className={styles.list}
        style={{ marginLeft: `${this.props.offsetLeft}px` }}
      >
        {this.props.tree.children.map((item, index) => (
          <li className={styles.listItem} key={index}>
            {this.props.renderNode(
              item.id,
              getKeyPath(this.props.keyPath, index),
              item.title,
              !item.children,
              this.props.tree.id,
            )}
            {item.children && !!item.children.length && (
              <Tree
                keyPath={getKeyPath(this.props.keyPath, index)}
                tree={item}
                renderNode={this.props.renderNode}
                offsetLeft={this.props.offsetLeft}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

const IdType = PT.oneOfType([PT.number, PT.string]);
Tree.propTypes = {
  // public props
  tree: PT.shape({ id: IdType, title: PT.string, children: PT.array }),
  renderNode: PT.func.isRequired,
  offsetLeft: PT.number,

  // private props
  keyPath: PT.array,
};

Tree.defaultProps = {
  tree: [],
  keyPath: [],
  renderNode: renderNode,
  offsetLeft: 10,
};

export default Tree;
