import Immutable from 'immutable';
import { MODAL_SHOW, MODAL_HIDE } from 'constants/actionTypes.js';
const initialState = Immutable.fromJS([]);

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case MODAL_SHOW:
      return state.push(
        Immutable.fromJS({
          type: payload.type,
          header: payload.header,
          content: payload.content,
          isConfirm: payload.isConfirm
        })
      );

    case MODAL_HIDE:
      return state.filter(item => item.get('type') !== payload.type);

    default:
      return state;
  }
};
