import { connect } from 'react-redux';
import AgroEventsWorks from 'components/report-agro-events/Page';
import { getReportData } from 'selectors/reports';
import { getReportProp } from 'selectors/reports';
import toJS from 'helpers/tojs';

const mapStateToProps = (state, props) => ({
  data: getReportData(state, 'agroEventsReport'),
  loading: getReportProp(state, 'agroEventsReport', 'loading'),
  runningImport: getReportProp(state, 'agroEventsReport', 'runningImport'),
  progress: getReportProp(state, 'agroEventsReport', 'progress'),
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(AgroEventsWorks));
