import Immutable from 'immutable';
import { loadState, saveState } from './helpers.js';
import { PERIOD_FILTER_UPDATE } from 'constants/actionTypes.js';

const initialState = Immutable.fromJS(loadState());

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PERIOD_FILTER_UPDATE: {
      const stateNew = state.set('from', payload.from).set('to', payload.to);
      if (state !== stateNew) {
        saveState(stateNew);
      }
      return stateNew;
    }

    default:
      return state;
  }
};
