import React from 'react';
import PT from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import FormFooter from 'components/ui/FormFooter';
import Label from 'components/ui/Label';
import Button from 'components/ui/Button';
import { InputAdapter } from 'components/ui/adapter';
import { Form, Field } from 'react-final-form'
import { defaultSubmitForm} from 'helpers/form';


class WorkTypeForm extends React.PureComponent {
  render = () => {
    const props = this.props;
    return (
      <Form
        onSubmit={ values => defaultSubmitForm(values, this.props) }
        initialValues={ props.data }
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={'row'}>
                <div className={'col-6'}>
                  <Label>{'Вид работ:'}</Label>
                  <Field
                      name={'name'}
                      placeholder={'Вид работ'}
                      component={InputAdapter}
                  />
                </div>
                <div className={'col-6'}>
                  <Label>{'Максимальная скорость обработки (км/ч):'}</Label>
                  <Field
                      name={'max_speed'}
                      placeholder={'Макс. скорость обработки'}
                      component={InputAdapter}
                      type={'number'}
                      step={'0.01'}
                      parse={(value) => parseFloat(value) || null}
                  />
                </div>
                <div className={'col-12'}>
                  <Label>{'Описание:'}</Label>
                  <Field
                      name={'description'}
                      placeholder={'Описание'}
                      component={InputAdapter}
                  />
                </div>
            </div>
            <FormFooter right>
              <Button type="submit">Сохранить</Button>
              <Button onClick={props.onClose}>Отмена</Button>
            </FormFooter>
          </form>
        )}
      />
    )
  }
}

WorkTypeForm.propTypes = {
  data: PT.object,
  onSubmit: PT.func,
  onClose: PT.func,
};

export default WorkTypeForm;
