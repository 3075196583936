import * as actionsTypes from 'constants/actionTypes.js';
// import axios from 'axios';
// import { MS_IN_SECOND } from 'constants/index';

export const increment = id => ({
  type: actionsTypes.INCREMENT,
  payload: { id },
});

export const decrement = id => ({
  type: actionsTypes.DECREMENT,
  payload: { id },
});

export const updateCounter = (id, value) => ({
  type: actionsTypes.COUNTER_UPDATE,
  payload: { id, value },
});

/*
export const requestExampleData = () => ({
  type: actionsTypes.EXAMPLE_DATA_REQUEST,
  payload: {},
});

export const receiveExampleData = code => ({
  type: actionsTypes.EXAMPLE_DATA_REQUEST_RECEIVE,
  payload: { code },
});

export const failExampleData = error => ({
  type: actionsTypes.EXAMPLE_DATA_REQUEST_FAIL,
  payload: { error },
});

export const requestServerDataThunk = () => async dispatch => {
  const url = '/api/v1/example/example';

  dispatch(requestExampleData());
  await delay(MS_IN_SECOND);

  try {
    const response = await axios.get(url);
    dispatch(receiveExampleData(response.data.status.code));
  } catch (error) {
    dispatch(failExampleData(error.message));
  }
};

const delay = ms => new Promise(res => setTimeout(res, ms));
*/

export const requestServerData = () => ({
  type: actionsTypes.EXAMPLE_DATA_REQUEST,
  payload: {
    request: {
      // url: '/territory/test_500',
      // url: '/territory/test_401',
      url: '/territory/territory',
    },
  },
});
