import React from 'react';
import CN from 'classnames';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import SidebarTab from 'components/ui/SidebarTab';
// import 'react-tabs/style/react-tabs.css';
import styles from './style.module.scss';

const CustomTabList = ({ className, children, ...props }) => (
  <TabList
      className={CN([
        styles.tabsList,
        className,
      ])}
      {...props}
  >
      {children}
  </TabList>
);
CustomTabList.tabsRole = 'TabList';

// const CustomTab = props => (
//   <Tab className={styles.tab} selectedClassName={'styles.tabSelected'}>
//     <SidebarTab {...props} />
//   </Tab>
// );
// CustomTab.tabsRole = 'Tab';

function tabWithStyle(WrappedComponent) {
  return class HOC extends React.Component {
    render() {
      const {dispatch, ...rest} = this.props;
      return (
        <WrappedComponent
          {...rest}
          className={styles.tab}
          selectedClassName={styles.tabSelected}
          children={
            <SidebarTab
              label={this.props.label}
              title={this.props.title}
              icon={this.props.icon}
            />
          }
        />
      );
    }
  };
}
const CustomTab = tabWithStyle(Tab);
CustomTab.tabsRole = 'Tab';

export {
  Tabs as SidebarTabs,
  CustomTabList as SidebarTabsList,
  CustomTab as SidebarTab,
  TabPanel as SidebarTabPanel,
};
