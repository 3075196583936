import React from 'react';
import CN from 'classnames';
import PT from 'prop-types';
import moment from 'moment';
import 'moment/locale/ru';
import styles from './style.module.scss';
import icons from 'styles/scss/icons.module.scss';

const formatDate = timestamp => moment(timestamp).format('DD.MM.YYYY');
const formatTime = timestamp => moment(timestamp).format('dd HH:mm');

export class Calendar extends React.Component {

  render = () => {
    const props = this.props;
    return (
      <div className={styles.calendar}>
        <div className={styles.calendar_dates} onClick={props.onClick}>
          <div className={styles.datetime}>
            <div className={styles.date}>{formatDate(props.from)}</div>
            <div className={styles.time}>{formatTime(props.from)}</div>
          </div>
          <div className={styles.separator}>
            <span className={icons.icon_container}>
              <i className={CN([icons.icon, icons.icon_calendar])} />
            </span>
          </div>
          <div className={styles.datetime}>
            <div className={styles.date}>{formatDate(props.to)}</div>
            <div className={styles.time}>{formatTime(props.to)}</div>
          </div>
        </div>
      </div>
    )
  }
}

Calendar.propTypes = {
  from: PT.number,
  to: PT.number,
  onClick: PT.func,
};

export default Calendar;
