import escape from 'regexp.escape';
import { matchPath } from 'react-router-dom';
import { MS_IN_SECOND } from 'constants/index';

export const stripMs = timestampWithMs =>
  Math.floor(timestampWithMs / MS_IN_SECOND);

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}

export const matchCurrentPath = (path, options = {}) => {
  return matchPath(window.location.pathname, {
    path,
    ...options,
  });
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const resizeElement = function(element) {
  let height = 0;
  const body = window.document.body;
  if (window.innerHeight) {
      height = window.innerHeight;
  } else if (body.parentElement.clientHeight) {
      height = body.parentElement.clientHeight;
  } else if (body && body.clientHeight) {
      height = body.clientHeight;
  }
  element.style.height = ((height - element.offsetTop - 10) + 'px');
};

export const removeExtraSpaces = (str) => {
  return str.replace(/\s+/g, "");
};

export const replaceSimilarRuSymbols = (str) => {
    let EN_RU = {
      'A': 'А',
      'B': 'В',
      'C': 'С',
      'E': 'Е',
      'H': 'Н',
      'K': 'К',
      'M': 'М',
      'O': 'О',
      'P': 'Р',
      'T': 'Т',
      'X': 'Х',
      'Y': 'У',
  };

  let en_RU = {};
  for (let k in EN_RU) {
    en_RU[k.toLowerCase()] = EN_RU[k];
  }

  let replaces = Object.assign(EN_RU, en_RU);

  for (let k in replaces) {
    const re = new RegExp(escape(k),"g");
    str = str.replace(re, replaces[k]);
  }

  return str;
};

export const saveJSON = key => data =>
  localStorage.setItem(key, JSON.stringify(data));

export const loadJSON = (key, defaultValue) => () => {
  const value = localStorage.getItem(key);
  if (!value) {
    return defaultValue;
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultValue;
  }
};
