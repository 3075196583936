import Immutable from 'immutable';
import { TREE_FILTER_UPDATE } from 'constants/actionTypes.js';

const initialState = Immutable.fromJS({
  value: '',
});

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case TREE_FILTER_UPDATE:
      if (payload.treeName !== 'trailer') {
        return state;
      }

      return state.set('value', payload.searchValue || '');

    default:
      return state;
  }
};
