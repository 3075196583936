import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import styles from './style.module.scss';

class IconButton extends React.PureComponent {
  onClick = () => {
    this.props.onClick && this.props.onClick();
  };

  getSize = () => `${this.props.size}px`;

  render = () => (
    <button
      className={styles.button}
      style={{ width: this.getSize(), height: this.getSize() }}
      disabled={this.props.disabled}
      onClick={this.onClick}
      title={this.props.title}
    >
      <div className={CN(styles.icon, styles[this.props.icon])} />
    </button>
  );
}

IconButton.propTypes = {
  icon: PT.oneOf([
    'item_add',
    'item_edit',
    'folder_add',
    'folder_edit',
    'folder_delete',
    'vehicle_attach',
    'vehicle_detach',
    'chevron_left',
    'chevron_double_left',
    'chevron_right',
    'chevron_double_right',
  ]),
  title: PT.string,
  size: PT.number,
  disabled: PT.bool,
  onClick: PT.func,
};

IconButton.defaultProps = {
  size: 22,
};

export default IconButton;
