import React from 'react';
import PT from 'prop-types';
import CN from 'classnames';
import styles from './style.module.scss';

const getStyle = (props) => {
  if (!props.color) {
    return null;
  }
  return { backgroundColor: props.color };
}

const Checkbox = props => {
  const handleClick = (e) => {
    if (props.disabled) {
      return;
    }

    props.onClick && props.onClick(e)
  }
  return (<div
    className={CN([
      styles.container,
      { [styles.clear]: !props.color && !props.checked && !props.indeterminate },
      { [styles.disabled]: props.disabled },
      { [styles.checked]: !props.color && props.checked && !props.indeterminate },
      { [styles.checkedWhite]: props.color && props.checked && !props.indeterminate },
      { [styles.indeterminate]: !props.color && props.indeterminate },
      { [styles.indeterminateWhite]: props.color && props.indeterminate },
      props.className,
    ])}
    style={getStyle(props)}
    title={props.title}
    onClick={handleClick}
  />);
};

Checkbox.propTypes = {
  className: PT.string,
  color: PT.string,
  checked: PT.bool,
  disabled: PT.bool,
  indeterminate: PT.bool,
  title: PT.string,
  onClick: PT.func,
};

export default Checkbox;
