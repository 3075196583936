import { connect } from 'react-redux';
import { SidebarTab } from 'components/ui/SidebarTabs';
import {
  getCheckedTreeNodesCount,
  getListItemsSelectedCount,
} from 'selectors/filters';

const mapStateToProps = (state, { type, name }) => ({
  label: `${type === 'tree' ? getCheckedTreeNodesCount(state, name, true) : getListItemsSelectedCount(state, name)}`,
});

export default connect(
  mapStateToProps,
  null
)(SidebarTab);
