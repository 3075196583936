import { connect } from 'react-redux';
import { clickTreeNodeCheckbox } from 'actions/filters';
import Checkbox from 'components/ui/Checkbox';
import { getTreeItemProp } from 'selectors/filters';

const mapStateToProps = (state, {treeName, keyPath}) => ({
  checked: getTreeItemProp(state, treeName, [], 'checked'),
  indeterminate: getTreeItemProp(state, treeName, [], 'indeterminate'),
});

const mapDispatchToProps = (dispatch, {treeName}) => ({
  onClick: event => dispatch(clickTreeNodeCheckbox(treeName, [])),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkbox);
