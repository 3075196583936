import React from 'react';
import Counter from 'containers/example/Counter';
import ApiExample from 'containers/example/ApiExample';
import styles from './style.module.scss';

const Page = () => (
  <div className={styles.wrapper}>
    <Counter id={'primary'}/>
    <Counter id={'secondary'}/>
    <ApiExample/>
  </div>
);

export default Page;
