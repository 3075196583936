import Immutable from 'immutable';
import {
  TRAILER_VEHICLES_FETCH,
  VEHICLE_TRAILERS_FETCH,
  TRAILER_VEHICLES_FETCH_SUCCESS,
  TRAILER_VEHICLES_FETCH_FAIL,
  VEHICLE_TRAILERS_FETCH_SUCCESS,
  VEHICLE_TRAILERS_FETCH_FAIL,
  TRAILER_WORK_TYPES_FETCH,
  TRAILER_WORK_TYPES_FETCH_SUCCESS,
  TRAILER_WORK_TYPES_FETCH_FAIL,
  VEHICLE_DRIVERS_FETCH_FAIL,
  VEHICLE_DRIVERS_FETCH_SUCCESS,
} from 'constants/actionTypes.js';

const initialState = Immutable.fromJS({
  isLoading: false,
  trailersData: null,
  vehicleData: null,
});

export default (state = initialState, {type, payload, error}) => {
  switch (type) {
    case TRAILER_VEHICLES_FETCH:
    case TRAILER_WORK_TYPES_FETCH:
    case VEHICLE_TRAILERS_FETCH:
      return state.set('isLoading', true);
    case TRAILER_VEHICLES_FETCH_SUCCESS:
      return state.set('isLoading', false).set('trailersData', payload.data);
    case TRAILER_VEHICLES_FETCH_FAIL:
      return state.set('isLoading', false).set('trailersData', null);
    case TRAILER_WORK_TYPES_FETCH_SUCCESS:
      return state.set('isLoading', false).set('workTypesData', payload.data);
    case TRAILER_WORK_TYPES_FETCH_FAIL:
      return state.set('isLoading', false).set('workTypesData', null);
    case VEHICLE_TRAILERS_FETCH_SUCCESS:
      return state.set('isLoading', false).set('vehicleData', payload.data);
    case VEHICLE_TRAILERS_FETCH_FAIL:
      return state.set('isLoading', false).set('vehicleData', null);
    case VEHICLE_DRIVERS_FETCH_SUCCESS:
      return state.set('isLoading', false).set('driversData', payload.data);
    case VEHICLE_DRIVERS_FETCH_FAIL:
      return state.set('isLoading', false).set('driversData', null);
    default:
      return state;
  }
};
