import _ from "lodash";

export const addEmptyValues = (initial, values) => {
  let empty = {};

  for (let attrname in initial) {
    if (initial.hasOwnProperty(attrname)) {
      empty[attrname] = null;
    }
  }

  return Object.assign(empty, values);
};

export const defaultSubmitForm = async (values, props) => {
  let data = addEmptyValues(props.data, values);
  let result = props.onSubmit && await props.onSubmit(false, data);

  let errors = result.error && result.error.response.data && result.error.response.data.errors;
  if (!errors) {
    props.onClose();
  }
  return errors;
};

export const parseDecimal = value => {
  return !_.isFinite(parseFloat(value)) || !_.isFinite(Number(value)) || (value.endsWith && value.endsWith('.')) ? value : parseFloat(value);
};
