import React from "react";
import ReactTable from "react-table";
import 'react-table/react-table.css'
import CN from "classnames";
import Pagination from "containers/features/reports/ReactTable/Pagination";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import Input from "components/ui/Input";
import FormFooter from "components/ui/FormFooter";
import styles from './SeasonListPage.module.scss';
import {DatetimeAdapter} from "../../ui/adapter";
import Label from 'components/ui/Label';
import moment from "moment";

class SeasonListPage extends React.PureComponent {
  state = {
    isOpen: false,
    showConfirmDeleteDialog: false,
    id: null,
    season: "",
    startDate: null,
    endDate: null,
    error: null,
  };

  handleDeleteSeason = (row) => (e) => {
    e.stopPropagation();
    this.setState({...this.state, showConfirmDeleteDialog: true, deletedItem: row});
    return false;
  };

  handleAddSeasonBlock = () => {
    const {isOpen} = this.state;
    this.setState({isOpen: !isOpen, id: null, error: null});
  };

  handleEditSeasonBlock = ({start_date, end_date, name, id}) => {
    const {isOpen} = this.state;
    this.setState({isOpen: !isOpen, season: name, startDate: start_date, endDate: end_date, id, error: null});
  };

  handleOpened = () => {
    if (this.innerInput) {
      this.innerInput.focus();
    }
  };

  handleChangeSeason = ({target: {value}}) => {
    this.setState({season: value, error: null});
  };

  handleChangeStartDate = (value) => this.setState({startDate: value, error: null});

  handleChangeEndDate = (value) => this.setState({endDate: value, error: null});

  handleAddSeason = async () => {
    const {onAddSeason, onUpdateSeason} = this.props;
    const {id, season, startDate, endDate} = this.state;
    if (!season) {
      this.setState({error: "Заполните название сезона"});
      return;
    }
    if (startDate && (!(startDate > 0) || !moment(startDate).isValid() || moment(startDate).year() > 9999)) {
      this.setState({error: "Некорректная дата старта сезона"});
      return;
    }
    if (endDate && (!(endDate > 0) || !moment(endDate).isValid() || moment(endDate).year() > 9999)) {
      this.setState({error: "Некорректная дата завершения сезона"});
      return;
    }
    if (startDate && endDate && moment(startDate).isAfter(moment(endDate))) {
      this.setState({error: "Дата завершения сезона раньше даты начала"});
      return;
    }
    const data = {name: season, alias: season, start_date: startDate ? moment(startDate).toDate().getTime() : null, end_date: endDate ? moment(endDate).toDate().getTime() : null}
    if (id) {
      await onUpdateSeason({id, ...data});
    } else {
      await onAddSeason(data);
    }
    this.handleCloseSeason();
  };


  handleCloseSeason = () => {
    this.setState({isOpen: false, season: '', startDate: null, endDate: null, id: null, error: null});
  };

  onCloseDeleteItemDialog = () => {
    this.setState({...this.state, showConfirmDeleteDialog: false, deletedItem: null});
  };

  onConfirmDeleteItem = async () => {
    const {onDeleteSeason} = this.props;
    const {deletedItem} = this.state;
    if (deletedItem) {
      await onDeleteSeason(deletedItem.id);
    }
    this.onCloseDeleteItemDialog();
  };

  render() {
    const {isLoading, seasons: data} = this.props;
    const {deletedItem, showConfirmDeleteDialog, isOpen, id, season, startDate, endDate, error} = this.state;

    const columns = [
      {
        Header: 'Дата начала',
        accessor: 'start_date',
        width: 180,
        Cell: ({value}) => value && moment(value).isValid() ? (<span className={styles.name}>{moment(value).format("DD.MM.YYYY")}</span>) : "",
      },
      {
        Header: 'Дата завершения',
        accessor: 'end_date',
        width: 180,
        Cell: ({value}) => value && moment(value).isValid() ? (<span className={styles.name}>{moment(value).format("DD.MM.YYYY")}</span>) : "",
      },
      {
        Header: 'Сезон',
        accessor: 'name',
        Cell: ({value, original}) => (
          <>
            <span className={styles.name}>{value}</span>
            <Button className={styles.deleteBtn} type="button"
                    onClick={this.handleDeleteSeason(original)}>Удалить</Button>
          </>
        )
      },
    ];
    return (
      <>
        <div className={styles.wrapper}>
          <ReactTable
            className={CN([styles.table, "-striped", "-highlight"])}
            data={data}
            columns={columns}
            defaultSorted={[
              {
                id: "id",
                desc: false,
              }
            ]}
            loading={isLoading}
            pageSizeOptions={[10, 20, 50, 100]}
            minRows={0}
            previousText="Предыдущая"
            nextText="Следующая"
            loadingText="Загрузка..."
            noDataText={isLoading ? "" : "Данных не найдено"}
            pageText="Страница"
            ofText="из"
            rowsText="строк"
            PaginationComponent={props => (
              <Pagination{...props} hideExport={true} extButtons={<Button className="ml-2" type="button"
                                                                          onClick={this.handleAddSeasonBlock}>Добавить</Button>} />
            )}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  const {row: {_original}} = rowInfo;
                  this.handleEditSeasonBlock(_original);
                }
              }
            }} />
        </div>
        {isOpen && (
          <Modal
            disableToggle
            isDisplayed={true}
            onClose={this.handleCloseSeason}
            onToggle={this.handleCloseSeason}
            onOpened={this.handleOpened}
            size={'lg'}
            header={!id ? "Добавить сезон" : "Изменить сезон"}
            content={
              <>
                <Label>Название</Label>
                <Input ref={el => this.input = el} innerRef={el => this.innerInput = el} type="text"
                       onChange={this.handleChangeSeason} value={season} />
                <Label>Дата начала</Label>
                <DatetimeAdapter className={styles.fixedDateTime} value={startDate} meta={{}} onChange={this.handleChangeStartDate}
                                 isValidDate={(current) => current && (!endDate || current.isBefore(moment(endDate)))}
                           timeFormat={false} />
                <Label>Дата завершения</Label>
                <DatetimeAdapter className={styles.fixedDateTime} value={endDate} meta={{}} onChange={this.handleChangeEndDate}
                                 isValidDate={(current) => current && (!startDate || current.isAfter(moment(startDate)))}
                           timeFormat={false} />
                {error && <div className={styles.error}>{error}</div>}
                <FormFooter right>
                  <Button type="submit" onClick={this.handleAddSeason}>{!id ? "Добавить" : "Изменить"}</Button>
                  <Button onClick={this.handleCloseSeason}>Отмена</Button>
                </FormFooter>
              </>
            }
            hasFooter={false}
          />
        )}
        {showConfirmDeleteDialog && (
          <Modal
            isDisplayed
            onToggle={this.onCloseDeleteItemDialog}
            header="Удаление сезона"
            content={`Вы уверены, что хотите удалить сезон "${deletedItem.name}"?`}
            buttons={
              <React.Fragment>
                <Button onClick={this.onConfirmDeleteItem}>Да</Button>
                <Button onClick={this.onCloseDeleteItemDialog}>Нет</Button>
              </React.Fragment>
            }
          />
        )}
      </>
    );
  }
}

export default SeasonListPage;
