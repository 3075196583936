import React from 'react';
import CN from 'classnames';
import { Label as ReactstrapLabel } from 'reactstrap';
import styles from './style.module.scss';

const Label = ({ className, children, ...props }) => (
    <ReactstrapLabel
      className={CN([
        styles.label,
        className,
      ])}
      {...props}
    >
        {children}
    </ReactstrapLabel>
);

export default Label;
