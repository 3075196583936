import React from 'react';
import PT from 'prop-types';
import Modal from 'containers/ui/Modal';

const Modals = props => (
  <React.Fragment>
    {props.items.map(item => (
      <Modal
        key={item.type}
        isDisplayed={true}
        type={item.type}
        header={item.header}
        content={item.content}
      />
    ))}
  </React.Fragment>
);

Modals.propTypes = {
  items: PT.arrayOf(
    PT.shape({ type: PT.string, header: PT.node, content: PT.node })
  ),
};

Modals.defaultProps = {
  items: [],
};

export default Modals;
