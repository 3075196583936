import React from 'react';
import Input from './Input';
import Datetime from './Datetime';
import FileBase64 from './FileBase64';

export const getErrorText = meta =>
  meta.invalid && !meta.dirtySinceLastSubmit
    ? meta.error || meta.submitError
    : null;

export const InputAdapter = ({ input, meta, ...rest }) => (
  <Input
    {...input}
    {...rest}
    // defaultValue={ meta.initial }
    errorText={getErrorText(meta)}
  />
);

export const SelectAdapter = ({ input, meta, options, withEmptyItem, ...rest }) => (
  <Input
    {...input}
    {...rest}
    // defaultValue={ meta.initial }
    errorText={getErrorText(meta)}
  >
      { withEmptyItem ? <option/> : '' }
      {options && options.map(object =>
        <option
            key={object.id}
            value={object.id}
        >
          {object.name}
        </option>
      )}
  </Input>
);

export const DatetimeAdapter = ({ input, meta, ...rest }) => (
  <Datetime
    {...input}
    {...rest}
    // defaultValue={ meta.initial }
    errorText={getErrorText(meta)}
  />
);

export const FileBase64Adapter = ({ input, meta, ...rest }) => (
  <FileBase64
    {...input}
    {...rest}
    // defaultValue={ meta.initial }
    errorText={getErrorText(meta)}
  />
);
