import * as actionsTypes from 'constants/actionTypes.js';
import { OMNICOMM_LOGOUT_URL } from 'constants/index';

export const initApp = (options) => ({
  type: actionsTypes.APP_INIT,
  payload: options || {},
});

export const userLogin = () => ({
  type: actionsTypes.USER_LOGIN,
  payload: {},
});

export const userLogout = () => ({
  type: actionsTypes.USER_LOGOUT,
  payload: {},
});

export const updateAuthData = (jwt, jwtData, refresh) => ({
  type: actionsTypes.UPDATE_AUTH_DATA,
  payload: { jwt, jwtData, refresh },
});

export const clearAuthData = () => ({
  type: actionsTypes.CLEAR_AUTH_DATA,
  payload: {},
});

export const showModal = (type, content, header, isConfirm) => ({
  type: actionsTypes.MODAL_SHOW,
  payload: { type, content, header, isConfirm },
});

export const hideModal = (type, isConfirmed) => ({
  type: actionsTypes.MODAL_HIDE,
  payload: { type, isConfirmed },
});

export const logoutThunk = () => dispatch => {
  dispatch(clearAuthData());
  localStorage.clear();

  window.location.href = OMNICOMM_LOGOUT_URL;
};

export const loadList = (section, entity, params) => ({
  type: `${section.toUpperCase()}_${entity.toUpperCase()}_GET_LIST`,
  payload: {
    request: {
      method: 'GET',
      url: `/${section}/${entity}`,
      params,
    },
  },
});

export const loadEntity = (section, entity, id) => ({
  type: `${section.toUpperCase()}_${entity.toUpperCase()}_GET_ITEM`,
  payload: {
    request: {
      url: `/${section}/${entity}/${id}`,
    },
  },
});

export const createEntity = (section, entity, data) => ({
  type: actionsTypes.ENTITY_CREATE,
  payload: {
    request: {
      method: 'POST',
      url: `/${section}/${entity}`,
      data: data,
    },
    section,
    entity
  },
});

export const updateEntity = (section, entity, data) => ({
  type: actionsTypes.ENTITY_UPDATE,
  payload: {
    request: {
      method: 'PUT',
      url: `/${section}/${entity}/${data.id}`,
      data: data,
    },
    section,
    entity
  },
});

export const storeEntity = (section, entity, data) => {
  return data.id ? updateEntity(section, entity, data) : createEntity(section, entity, data);
};

export const deleteTreeFolder = (treeName, id) => ({
  type: actionsTypes.TREE_FOLDER_DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: `/${treeName}/folder/${id}`,
    },
    section: treeName,
  },
});

export const importDataOO = () => ({
  type: actionsTypes.GEOZONES_IMPORT,
  payload: {
    request: {
      url: 'reports/import_data_oo',
    },
  },
});

export const importDataOOSuccess = () => ({
  type: actionsTypes.GEOZONES_IMPORT_SUCCESS,
  payload: {},
});

export const attachTrailer = (trailerId, vehicleId, fromTime, toTime, fetchParams) => ({
  type: actionsTypes.TRAILER_ATTACH,
  payload: {
    fetchParams,
    request: {
      method: 'POST',
      url: '/trailer/vehicle_trailer',
      data: {
        vehicle_uid: vehicleId,
        trailer_id: trailerId,
        attached_at: fromTime,
        unattached_at: toTime,
      },
    },
  },
});

export const detachTrailer = (id, fetchParams) => ({
  type: actionsTypes.TRAILER_DETACH,
  payload: {
    fetchParams,
    request: {
      method: 'POST',
      url: `trailer/vehicle_trailer/detach/${id}`,
    },
  },
});

export const deleteVehicleTrailer = (id, fetchParams) => ({
  type: actionsTypes.VEHICLE_TRAILER_DELETE,
  payload: {
    fetchParams,
    request: {
      method: 'DELETE',
      url: `trailer/vehicle_trailer/${id}`,
    },
  },
});

export const deleteWorkTypeTrailer = (id, fetchParams) => ({
  type: actionsTypes.WORK_TYPE_TRAILER_DELETE,
  payload: {
    fetchParams,
    request: {
      method: 'DELETE',
      url: `trailer/trailer_work_type/${id}`,
    },
  },
});

export const deleteVehicleDriver = (id, fetchParams) => ({
  type: actionsTypes.VEHICLE_DRIVER_DELETE,
  payload: {
    fetchParams,
    request: {
      method: 'DELETE',
      url: `driver/vehicle_driver/${id}`,
    },
  },
});

export const updateRfidData = () => ({
  type: actionsTypes.RFID_UPDATE_DATA,
  payload: {
    request: {
      method: 'POST',
      url: 'trailer/vehicle_trailer/rfid',
    },
  },
});
