import { connect } from 'react-redux';
import { updatePeriodFilter } from 'actions/filters';
import Periods from 'components/ui/Periods';
import { getPeriodValue } from 'selectors/filters';

const mapStateToProps = (state, props) => ({
  from: getPeriodValue(state, 'from'),
  to: getPeriodValue(state, 'to'),
});

const mapDispatchToProps = dispatch => ({
  onSelect: (from, to) => dispatch(updatePeriodFilter(from, to)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Periods);
